import React, {
  useState,
  useRef,
  useLayoutEffect,
  useContext,
  useEffect
} from "react"
import {useMutation} from "@apollo/client"
import {useNavigate, useParams} from "react-router-dom"
import {Progress} from "antd"
import {Styled} from "./health-scale.styled"
import {DataContext, UserContext} from "../../../context/context"
import {INSERT_ANSWER, UPDATE_ANSWER} from "../../../const/const"
import AchtungQuestion from "../../achtung-question/achtung.component"
import {isAuthDataWithoutParameter} from "../../../middleware/auth"

const timelineProgressCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const gradientColorScale = {
  "0%": "#63ca3c",
  "10%": "#d6d32f",
  "20%": "#e6c536",
  "30%": "#edae2a",
  "40%": "#ef9837",
  "50%": "#e38242",
  "60%": "#e5603c",
  "70%": "#d5523a",
  "80%": "#c3413c",
  "90%": "#a73333",
  "100%": "#8e2826"
}

const describeArr = [
  "0 – kein Problem",
  "1 – etwas unwohl",
  "2 – Unwohlsein aber keine Übelkeit, sehr gering",
  "3 – Unwohlsein aber keine Übelkeit, gering",
  "4 – Unwohlsein aber keine Übelkeit, mittel",
  "5 – Unwohlsein aber keine Übelkeit, stark",
  "6 – Übelkeit,  gering",
  "7 – Übelkeit, mittel",
  "8 – Übelkeit, stark",
  "9 – Würgen",
  "10 – Übergeben"
]

const HealthScale = ({title, questionId, data, answer: answerParametr}) => {
  const [answer, setAnswer] = useState(answerParametr)

  const [timelineProgress, setTimelineProgress] = useState(
    answer.length !== 0 ? Number(answer[0].answer) : -1
  )
  const [offsetLeft, setOffsetLeft] = useState(0)

  const {state, handlerSet} = useContext(DataContext)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)

  const ref = useRef(null)

  const {tabId, stageId} = useParams()

  const [isShow, setIsShow] = useState(false)

  const handleSetIsshow = (value) => {
    setIsShow(value)
  }

  // set appear achtung message
  useEffect(() => {
    // if (true) - show message
    // if (not setted and this page is in array)
    handleSetIsshow(
      timelineProgress === -1 &&
        stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
    )
  }, [timelineProgress])

  const [updateMutation, {loading}] = useMutation(UPDATE_ANSWER)

  const [insertMutation, {loading: insertMutationLoading}] =
    useMutation(INSERT_ANSWER)

  const navigate = useNavigate()

  async function updateData(index) {
    if (answer.length !== 0) {
      await updateMutation({
        variables: {
          answerId: answer[0].id,
          answer: `${index}`
        }
      })
    } else {
      const insertFieldId = await insertMutation({
        variables: {
          answer: `${index}`,
          question_id: questionId,
          test_scenario_history_id: state.historyId
        }
      })

      setAnswer([
        {
          id: insertFieldId.data.insert_questions_answers_one.id,
          answer: index
        }
      ])
    }
  }

  const handleClick = (index) => {
    if (loading || insertMutationLoading) return

    setTimelineProgress(index)
    updateData(index).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(index)
          else navigate("/login")
        })
      }
    })
  }

  useLayoutEffect(() => {
    if (timelineProgress === 0) {
      setOffsetLeft(0)
    }

    // 15px - BoxProgress padding/2
    if (timelineProgress === 10) setOffsetLeft(ref.current.offsetWidth - 15)

    if (timelineProgress >= 1 && timelineProgress < 10)
      setOffsetLeft(
        (timelineProgress * ref.current.offsetWidth) / 11 +
          ref.current.offsetWidth / 11 / 2 -
          8
      )
  }, [timelineProgress])

  return (
    <>
      <AchtungQuestion
        isShow={isShow}
        title="Bitte ausfüllen! Falls nicht möglich, dem Versuchsleiter zeigen."
      />
      <Styled.TimelineBoxLight>
        <Styled.TimelineBoxLightTitle>{title}</Styled.TimelineBoxLightTitle>
        <Styled.TimelineBoxInside>
          <Styled.BoxLightHeader>
            <Styled.LightHeaderStart>Garnicht</Styled.LightHeaderStart>
            <Styled.LightHeaderFinish>10</Styled.LightHeaderFinish>
          </Styled.BoxLightHeader>
          <Styled.BoxProgressRelative>
            {/* absolute */}
            {timelineProgress !== -1 && (
              <Styled.BoxShowCount value={`${offsetLeft}px`}>
                <Styled.NumberCursor />
                {timelineProgress !== 0 ? timelineProgress : "Garnicht"}
              </Styled.BoxShowCount>
            )}
            <Styled.BoxProgress>
              <Progress
                percent={100}
                strokeColor={gradientColorScale}
                size="small"
                showInfo={false}
              />
              <Styled.ButtonListBox ref={ref}>
                {timelineProgressCount.map((item, index) => (
                  <Styled.Buttons
                    key={item}
                    onClick={() => handleClick(item)}
                    active={index === timelineProgress}
                  >
                    <Styled.ButtonsTitle>{item}</Styled.ButtonsTitle>
                  </Styled.Buttons>
                ))}
              </Styled.ButtonListBox>
              <Progress
                percent={100}
                strokeColor={gradientColorScale}
                size="small"
                showInfo={false}
              />
            </Styled.BoxProgress>
          </Styled.BoxProgressRelative>
        </Styled.TimelineBoxInside>
      </Styled.TimelineBoxLight>
      <Styled.DescribeBox>
        {describeArr.map((item, index) => (
          <Styled.Ul key={item}>
            <li>{item}</li>
          </Styled.Ul>
        ))}
      </Styled.DescribeBox>
    </>
  )
}

export default HealthScale
