import React, {useState, useRef, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {Button, Input, Space} from "antd"
import {SearchOutlined} from "@ant-design/icons"
import Highlighter from "react-highlight-words"

import {
  LeftSideFormContainer,
  UserRow,
  CreateUserButton,
  StyledTitle,
  TableStyledUserList
} from "./styledComponents"
import ModalUnsavedUser from "../../../../components/modal-unsaved-user"
import "./styled.css"

const UserList = ({
  handleCreateUserButtonClick,
  handleRowClick,
  users,
  isUserNoSavedState,
  setIsUserNoSaved,
  handleSaveDataIfNoSaved
}) => {
  const {t} = useTranslation()
  const [rowData, setRowData] = useState()
  const [isVisibleFinish, setIsVisibleFinish] = useState(false)

  const [searchText, setSearchText] = useState("")
  const [searchedColumn, setSearchedColumn] = useState("")
  const searchInput = useRef(null)
  const [rowId, setRowId] = useState()

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText("")
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters)

              // close dropdown after reset
              confirm()
            }}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 10)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      )
  })

  const columns = [
    {
      title: "Benutzername",
      dataIndex: "user_name",
      width: 50,
      ...getColumnSearchProps("user_name")
    },
    {
      title: "Benutzertyp",
      dataIndex: "type",
      align: "center",
      width: 50,
      ...getColumnSearchProps("type")
    }
  ]

  const handleRowClickGetData = (data, index) => {
    setRowData(data)
    setRowId(index)
  }

  const handleOnConfirmYes = () => {
    handleRowClick(rowData)

    // clear all logic for unsaved user
    setIsUserNoSaved(false)
  }

  useEffect(() => {
    if (!isUserNoSavedState) handleRowClick(rowData)
    else {
      setIsVisibleFinish(true)
    }
  }, [rowData])

  return (
    <>
      {isUserNoSavedState && (
        <ModalUnsavedUser
          closable
          isVisibleFinish={isVisibleFinish}
          setIsVisibleFinish={setIsVisibleFinish}
          handleOnConfirmYes={handleOnConfirmYes}
          handleSaveDataIfNoSaved={handleSaveDataIfNoSaved}
          setRowId={setRowId}
        />
      )}
      <LeftSideFormContainer>
        <UserRow>
          <StyledTitle>{t("users.currentUser")}</StyledTitle>
          <CreateUserButton
            onClick={() => {
              handleCreateUserButtonClick()
              setRowId(null)
            }}
          >
            {t("users.addUser")}
          </CreateUserButton>
        </UserRow>
        <TableStyledUserList
          id="userlist"
          dataSource={users}
          columns={columns}
          pagination={false}
          scroll={{y: 600}}
          onRow={(record, index) => ({
            onClick: () => handleRowClickGetData(record, index)
          })}
          rowClassName={(record, index) =>
            record?.user_name === rowData?.user_name &&
            record?.type === rowData?.type &&
            rowId !== null
              ? "table-row-dark"
              : "table-row-light"
          }
        />
      </LeftSideFormContainer>
    </>
  )
}

export default UserList
