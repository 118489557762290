import {useMutation} from "@apollo/client"
import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {INSERT_ANSWER, UPDATE_ANSWER} from "../../const/const"
import {DataContext, UserContext} from "../../context/context"
import {isAuthDataWithoutParameter} from "../../middleware/auth"
import {Styled} from "./radio-button.styled"

function RadioButton({
  options,
  data,
  answer: answerParametr,
  questionId,
  handleSetIsshow
}) {
  const [answer, setAnswer] = useState(answerParametr)

  const [selected, setSelected] = useState(
    answer.length !== 0
      ? data.filter((itemSearch) => itemSearch.title === answer[0].answer)[0].id
      : -1
  )

  const {state, handlerSet} = useContext(DataContext)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)
  const navigate = useNavigate()

  const [updateMutation, {loading}] = useMutation(UPDATE_ANSWER)

  const [insertMutation] = useMutation(INSERT_ANSWER)

  const newOption = options.split(";").map((item, index) => {
    const container = {}

    container.id = index
    container.title = item

    return container
  })

  const {tabId, stageId} = useParams()

  // set appear achtung message
  useEffect(() => {
    // if (true) - show message
    // if (not setted and this page is in array)
    handleSetIsshow(
      selected === -1 &&
        stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
    )
  }, [selected])

  async function updateData(index) {
    if (answer.length !== 0) {
      await updateMutation({
        variables: {
          answerId: answer[0].id,
          answer: data[index].title
        }
      })
    } else {
      const insertFieldId = await insertMutation({
        variables: {
          answer: data[index].title,
          question_id: questionId,
          test_scenario_history_id: state.historyId
        }
      })
      setAnswer([
        {
          id: insertFieldId.data.insert_questions_answers_one.id,
          answer: data[index].title
        }
      ])
    }
  }

  const handleClick = (index) => {
    if (loading) return
    setSelected(index)
    updateData(index).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(index)
          else navigate("/login")
        })
      }
    })
  }

  return (
    <Styled.MainBox>
      {newOption &&
        newOption.map((el, index) => (
          <Styled.Container key={el.id} onClick={() => handleClick(el.id)}>
            <Styled.Input
              type="radio"
              checked={selected === el.id}
              onChange={() => setSelected(el.id)}
            />
            <Styled.DarkInsideText>{el.title}</Styled.DarkInsideText>
          </Styled.Container>
        ))}
    </Styled.MainBox>
  )
}

export default RadioButton
