import React from "react"
import {useTranslation} from "react-i18next"

import {Styled} from "./achtung.styled"

const AchtungQuestion = ({isShow, title, description, marginBottom}) => {
  const {t} = useTranslation()

  return (
    <Styled.AchtungBox $isShow={isShow} $marginBottom={marginBottom}>
      <Styled.VertAlign>
        <Styled.Bold>{isShow && title}</Styled.Bold>
        <Styled.TempDiv>{isShow && t(description)}</Styled.TempDiv>
      </Styled.VertAlign>
    </Styled.AchtungBox>
  )
}
export default AchtungQuestion
