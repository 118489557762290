import {
  LoadingOutlined,
  SettingOutlined,
  SearchOutlined
} from "@ant-design/icons"
import {useMutation, useQuery} from "@apollo/client"
import {Collapse, message, Spin, Button, Input, Space} from "antd"
import Highlighter from "react-highlight-words"

import React, {useEffect, useRef, useState} from "react"
import ButtonNameStyle from "../../common/button-name-style"
import {
  DELETE_TEST_GROUP_MEMBERS,
  GET_TEST_GROUPS,
  GET_TEST_GROUPS_USERS
} from "../../const/const"

import {getAge} from "../../middleware/math"
import ModalTestGroup from "../modal-test-group"

import {Styled} from "./test-groups.styled"
import "./styled.css"

const {Panel} = Collapse

const TestGroup = ({updateGroups}) => {
  const [tableArr, setTableArr] = useState([])
  const [state_test_group_id, setTest_group_id] = useState()
  const [activeKeyIndex, setActiveKeyIndex] = useState(-1)

  const [isVisibleFinish, setIsVisibleFinish] = useState(false)
  const [isLoadingChangeArray, setIsLoadingChangeArray] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [searchedColumn, setSearchedColumn] = useState("")
  const searchInput = useRef(null)
  const [rowId, setRowId] = useState()

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText("")
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters)

              // close dropdown after reset
              confirm()
            }}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 10)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      )
  })

  const columns = [
    {
      title: "Username",
      dataIndex: "name",
      width: 30,
      ...getColumnSearchProps("name")
    },
    {
      title: "Alter",
      dataIndex: "alter",
      align: "center",
      width: 20,
      ...getColumnSearchProps("alter")
    },
    {
      title: "Adresse",
      dataIndex: "adresse",
      align: "center",
      width: 20,
      ...getColumnSearchProps("adresse")
    },
    {
      title: "Typ",
      dataIndex: "typ",
      align: "center",
      width: 20,
      ...getColumnSearchProps("typ")
    },
    {
      title: "Aktion",
      dataIndex: "aktion",
      align: "center",
      width: 20
    }
  ]

  const {
    data: dataTestGroups,
    loading: loadingDataTestGroups,
    refetch: refetchGetTestGroups
  } = useQuery(GET_TEST_GROUPS, {fetchPolicy: "no-cache"})

  const {
    refetch: refetchGet_Test_Group_Users,
    loading: loadingTest_Group_Users
  } = useQuery(GET_TEST_GROUPS_USERS, {
    fetchPolicy: "no-cache"
  })

  const [mutationDelete_Test_Groups_Members, {loading: loadingDeleteOneuser}] =
    useMutation(DELETE_TEST_GROUP_MEMBERS)

  let onChange

  const handleDeleteOneUser = async (userId, panelId) => {
    try {
      await mutationDelete_Test_Groups_Members({
        variables: {test_groupId: Number(userId)}
      })
      await onChange(panelId)
    } catch (err) {
      message.error(err)
    }
  }

  // refetch data after close modal
  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingChangeArray(true)

        await refetchGetTestGroups()
        await onChange(state_test_group_id)
      } catch (err) {
        message.error(err)

        setIsLoadingChangeArray(false)
      }

      setIsLoadingChangeArray(false)
    })()
  }, [isVisibleFinish, updateGroups])

  const [expandIconPosition, setExpandIconPosition] = useState("start")

  onChange = async (panelId) => {
    setRowId(null)
    setIsLoadingChangeArray(true)

    // panel id
    setTest_group_id(panelId)

    // panel open by id
    if (panelId !== 0) setActiveKeyIndex(panelId)

    if (panelId !== undefined) {
      try {
        const res = await refetchGet_Test_Group_Users({
          test_group_id: Number(panelId)
        })

        const get_all_users_in_one_array = [
          ...res.data?.drivers,
          ...res.data?.test_subjects,
          ...res.data?.users
        ]

        const userType = {
          drivers: "Fahrer",
          test_subjects: "Proband",
          users: "Testleiter"
        }

        const dataSource = get_all_users_in_one_array.map((item, index) => ({
          key: index,
          name: `${item.user_name}`,
          alter: getAge(item.date_of_birth),
          adresse: item.address,
          /* eslint-disable  */
          typ: userType[item.__typename],
          /* eslint-disable  */
          table: item.__typename,
          test_group_id: item.test_groups_members?.[0].id,
          aktion: (
            <ButtonNameStyle
              id={index}
              name="Löschen"
              color="red"
              background="transparent"
              handleDataSet={() =>
                handleDeleteOneUser(item.test_groups_members?.[0].id, panelId)
              }
              status="Delete"
            />
          )
        }))

        setTableArr(dataSource)
      } catch (err) {
        message.error(err)
      }
      setIsLoadingChangeArray(false)
    }
  }

  const genExtra = (panelId, groupName) => (
    <SettingOutlined
      id={"setting_" + groupName.split(" ").join("")}
      onClick={async (event) => {
        setIsVisibleFinish(true)
        setTest_group_id(panelId)

        event.stopPropagation()
      }}
    />
  )

  const handleSetActiveKeyIndex = (id) => {
    setActiveKeyIndex(id)
    setTest_group_id(id)
  }
  return (
    <>
      {isVisibleFinish && (
        <ModalTestGroup
          closable={false}
          isVisibleFinish={isVisibleFinish}
          setIsVisibleFinish={setIsVisibleFinish}
          test_group_id={state_test_group_id}
          handleSetActiveKeyIndex={handleSetActiveKeyIndex}
        />
      )}

      <Styled.Box>
        <Styled.InsideBox>
          <Styled.TestGroupEdit>
            <Styled.DescriptionBox>
              <Styled.DescriptionTitle>Testgruppen</Styled.DescriptionTitle>
              <Styled.DescriptionButtonBox
                id="addNewTestGroup"
                onClick={(event) => {
                  setIsVisibleFinish(true)
                  setTest_group_id(0)
                  setActiveKeyIndex(0)
                }}
              >
                <Styled.DescriptionButtonIcon>+</Styled.DescriptionButtonIcon>
                <Styled.DescriptionButton>Testgruppen</Styled.DescriptionButton>
              </Styled.DescriptionButtonBox>
            </Styled.DescriptionBox>
            {dataTestGroups !== undefined ? (
              <Styled.CollapseStyle
                onChange={onChange}
                expandIconPosition={expandIconPosition}
                accordion
                activeKey={activeKeyIndex} // get by Panel key
              >
                {dataTestGroups?.test_groups.map((itemGroup) => (
                  <Panel
                    id={itemGroup.name.split(" ").join("")}
                    header={itemGroup.name}
                    key={itemGroup.id}
                    extra={genExtra(itemGroup.id, itemGroup.name)}
                  >
                    <Styled.Relative>
                      {(loadingDeleteOneuser ||
                        loadingDataTestGroups ||
                        loadingTest_Group_Users ||
                        isLoadingChangeArray) && (
                        <Styled.WrapperAbsolute>
                          <Styled.AlignSpin>
                            <Spin indicator={<LoadingOutlined spin />} />
                          </Styled.AlignSpin>
                        </Styled.WrapperAbsolute>
                      )}
                      <Styled.Dark
                        $isLoading={
                          loadingDeleteOneuser ||
                          loadingDataTestGroups ||
                          loadingTest_Group_Users ||
                          isLoadingChangeArray
                        }
                      >
                        <Styled.ListBoxStyle>
                          <Styled.ListBoxTitle>
                            <Styled.TableStyled
                              id="carTable"
                              dataSource={tableArr}
                              columns={columns}
                              pagination={false}
                              scroll={{y: 200}}
                              onRow={(record, index) => ({
                                onClick: () => setRowId(index)
                              })}
                              rowClassName={(record, index) =>
                                index === rowId
                                  ? "table-row-dark"
                                  : "table-row-light"
                              }
                            />
                          </Styled.ListBoxTitle>
                        </Styled.ListBoxStyle>
                      </Styled.Dark>
                    </Styled.Relative>
                  </Panel>
                ))}
              </Styled.CollapseStyle>
            ) : (
              <Styled.Relative>
                <Styled.WrapperCollapse>
                  <Styled.AlignSpin>
                    <Spin indicator={<LoadingOutlined spin />} />
                  </Styled.AlignSpin>
                </Styled.WrapperCollapse>
              </Styled.Relative>
            )}
          </Styled.TestGroupEdit>
        </Styled.InsideBox>
      </Styled.Box>
    </>
  )
}

export default TestGroup
