import {useMutation} from "@apollo/client"
import React, {useContext, useEffect, useRef, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {INSERT_ANSWER, regex, UPDATE_ANSWER} from "../../../const/const"
import {DataContext, UserContext} from "../../../context/context"
import {isAuthDataWithoutParameter} from "../../../middleware/auth"
import Line from "../line"
import {
  ContainerMainTitle,
  ContainerMainTitleRelative,
  ContainerNumberTitle
} from "../rhombus/rhombus.styled"
import {Styled} from "./set-date.styled"

function SetDate({
  title,
  question,
  isFirst,
  isLast,
  answer: answerParametr,
  questionId
}) {
  const [updateMutation, {loading}] = useMutation(UPDATE_ANSWER)

  const [insertMutation] = useMutation(INSERT_ANSWER)

  const [answer, setAnswer] = useState(answerParametr)

  const [activeInput, setActiveInput] = useState(0)

  const [inputValue, setInputValue] = useState(
    answer.length !== 0 ? answer[0].answer : ""
  )

  const {state, handlerSet} = useContext(DataContext)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)

  const {tabId, stageId} = useParams()

  const [isShow, setIsShow] = useState(false)
  const navigate = useNavigate()

  const handleSetIsshow = (value) => {
    setIsShow(value)
  }

  // set appear achtung message
  useEffect(() => {
    // if (true) - red border
    // if (not setted and this page is in array)
    handleSetIsshow(
      inputValue === "" &&
        stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
    )
  }, [inputValue])

  const findDecimal = question !== null ? question.match(regex) : null

  const findDecimalTitle = title !== null ? title.match(regex) : null

  async function updateData(value) {
    if (answer.length !== 0) {
      await updateMutation({
        variables: {
          answerId: answer[0].id,
          answer: value
        }
      })
    } else {
      const insertFieldId = await insertMutation({
        variables: {
          answer: value,
          question_id: questionId,
          test_scenario_history_id: state.historyId
        }
      })

      setAnswer([
        {
          id: insertFieldId.data.insert_questions_answers_one.id,
          answer: value
        }
      ])
    }
  }

  const handleClick = (value) => {
    if (loading) return
    setInputValue(value)
    updateData(value).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(value)
          else navigate("/login")
        })
      }
    })
  }
  const ref = useRef(null)

  const hanldeOpenPicker = (event) => {
    try {
      ref.current.showPicker()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {!isFirst && title !== null && <Line />}
      {title !== null && (
        <ContainerMainTitle>
          <ContainerMainTitleRelative>
            {title.match(regex) !== null && (
              <ContainerNumberTitle
                $isBigSpace={
                  findDecimalTitle !== null
                    ? Number(findDecimalTitle[0].split(".")[0]) >= 10
                    : 0
                }
              >
                {title.match(regex)}
              </ContainerNumberTitle>
            )}
          </ContainerMainTitleRelative>
          {findDecimalTitle !== null
            ? title.split(findDecimalTitle[0])[1]
            : title}
        </ContainerMainTitle>
      )}
      <Styled.MainBox>
        <Styled.TimeBoxInside>
          <Styled.Container>
            <Styled.InsideText>
              {question.match(regex) !== null && (
                <Styled.ContainerNumber
                  $isBigSpace={
                    findDecimal !== null
                      ? Number(findDecimal[0].split(".")[0]) >= 10
                      : 0
                  }
                >
                  {question.match(regex)}
                </Styled.ContainerNumber>
              )}
              {findDecimal !== null
                ? question.split(findDecimal[0])[1]
                : question}
            </Styled.InsideText>
            <Styled.DateInput
              type="time"
              ref={ref}
              onFocus={(e) => {
                hanldeOpenPicker(e)
                setActiveInput(true)
              }}
              onBlur={(e) => setActiveInput(false)}
              onChange={(e) => handleClick(e.target.value)}
              activeInput
              value={inputValue}
              placeholder="Zeit wählen"
              $isShow={isShow}
            />
          </Styled.Container>
        </Styled.TimeBoxInside>
      </Styled.MainBox>
      {!isFirst && isLast && title !== null && <Line />}
    </>
  )
}

export default SetDate
