import styled from "styled-components"
import {MainBoxStyle} from "../../common/main-box/main-box"
import {ReactComponent as EditSvg} from "../../images/edit.svg"
import {ReactComponent as arrowInsideCirleSvg} from "../../images/arrowinsidecirle.svg"

export const MainBox = styled(MainBoxStyle)`
  display: flex;
  flex-direction: column;
`

export const BoxAlign = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 35px;
`

export const InsideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  border-radius: 0px;
`

export const AchtungMargin = styled.div`
  display: flex;
  margin: 0 30px;
`

export const ColumnAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 29%;
  padding-top: 10px;

  @media screen and (max-width: 700px) {
    width: 32%;
  }
`

export const BoxHeaderRelative = styled.div`
  position: relative;
`

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 3px dashed #f6f6f6;
`
export const HeaderDate = styled.div`
  display: flex;
  justify-content: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */
  height: 20px;
  color: #faba15;
`

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  height: 24px;
  margin-bottom: 35px;
  color: #495057;

  @media screen and (max-width: 700px) {
    margin-bottom: 25px;
  }
`

export const HeaderArrowBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: -17px;
`
export const HeaderArrowImg = styled(arrowInsideCirleSvg)`
  background-color: white;
`

export const ItemsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 52px;

  @media screen and (max-width: 700px) {
    margin-top: 30px;
  }
`

export const ItemsList = styled.div`
  display: flex;
  width: 100%;

  height: 78px;
  margin-bottom: 14px;
  border-radius: 8px 8px 8px 0px;

  ${({$isTrigger, $isEmpty, $isDialog, $isNoAnswer}) => {
    if ($isEmpty && $isDialog) {
      return "background: rgba(71, 69, 68, 0.2);"
    }

    if ($isTrigger && $isDialog) {
      return "background-color: rgba(227,220,184,0.50);"
    }

    if (!$isDialog && $isNoAnswer) {
      return "background-color: rgba(255,0,0,0.05);"
    }

    return "background: rgba(241, 241, 241, 0.49);"
  }}
`

export const ItemGroupBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 9px 20px 15px 15px;
`

export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  margin-top: 2px;
  color: #808080;
`

export const ItemGroupDateEdit = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;

  ${({$isFragen}) =>
    $isFragen &&
    `
    justify-content: flex-end;
  
  `}
`

export const ItemDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #495057;
`

export const ItemEdit = styled.div`
  display: flex;
`

export const ItemEditSvg = styled(EditSvg)`
  cursor: pointer;
`

export const Styled = {
  MainBox,
  BoxAlign,
  InsideBox,
  AchtungMargin,
  ColumnAlign,
  ColumnBox,
  BoxHeaderRelative,
  BoxHeader,
  HeaderDate,
  HeaderText,
  HeaderArrowBox,
  HeaderArrowImg,
  ItemsBox,
  ItemsList,
  ItemGroupBox,
  ItemText,
  ItemGroupDateEdit,
  ItemDate,
  ItemEdit,
  ItemEditSvg
}
