
export const pauseAudio = (audioRef, isAudioPlayingRef) => {
	if(audioRef?.current) {
  	const pausePromise = audioRef.current.pause();
		if(pausePromise) {
			pausePromise.then((audioRes) => {
  			console.log('Pausing audio with promise', audioRef.current);
  			/* eslint-disable no-param-reassign */
				isAudioPlayingRef.current = false;
			})
			.catch(err => {
				console.log(err)
  			/* eslint-disable no-param-reassign */
				isAudioPlayingRef.current = true;
			})
  	} else {
  		console.log('Pausing audio synchronously', audioRef.current);
			isAudioPlayingRef.current = false;
  	}
	}
}

export const playAudio = (audioRef, isAudioPlayingRef, stopSpeechToText) => {
	if(audioRef?.current) {
		try {
			stopSpeechToText()
		} catch(err) {
			console.debug("Could not stop STT before playing audio!")
		}

		const playPromise = audioRef.current.play();
		if(playPromise) {
			playPromise.then((audioRes) => {
  			console.log('Playing audio with promise', audioRef.current);
  			/* eslint-disable no-param-reassign */
				isAudioPlayingRef.current = true;
			})
			.catch(err => {
				console.log(err)
  			/* eslint-disable no-param-reassign */
				isAudioPlayingRef.current = false;
			})
  	} else {
  		console.log('Playing audio synchronously', audioRef.current);
			isAudioPlayingRef.current = true;
  	}
  }
}
