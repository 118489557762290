import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 111px;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  width: 100%;
  @media screen and (max-width: 494px) {
    height: 222px;
  }
`

export const InsideBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 92%;
`

export const BoxLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const BoxMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-right: 44px;
  @media screen and (max-width: 625px) {
    margin-right: 12px;
  }
`

export const BoxMenuLogo = styled.svg``

export const BoxCompany = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 44px;
  @media screen and (max-width: 625px) {
    margin-left: 12px;
  }
`

const BoxCompanyLogo = styled.img`
  height: 70px;
  margin-bottom: 10px;

  ${({$isResize}) =>
    $isResize &&
    `
    @media screen and (max-width: 900px) {
    width: 100px;
    height: 40px;
  }

    `}
`

export const BoxCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 111px;
`

export const BoxRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 111px;
`

export const BoxLanguage = styled.div`
  margin-right: 44px;
  @media screen and (max-width: 1200px) {
    margin-right: 12px;
  }
  @media screen and (max-width: 900px) {
    margin-right: 0px;
  }
`

export const BoxAccount = styled.div`
  background-color: transparent;
`

export const MenuSelect = styled.a`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #555b6d;
`

export const Styled = {
  MainBox,
  InsideBox,
  BoxLeft,
  BoxRight,
  BoxCenter,
  BoxMenu,
  BoxMenuLogo,
  BoxCompany,
  BoxCompanyLogo,
  BoxLanguage,
  BoxAccount,
  MenuSelect
}
