import {Tag, Select} from "antd"
import React from "react"
import {useQuery, useMutation} from "@apollo/client"
import {useTranslation} from "react-i18next"
import {
  CREATE_DRIVER_LICENSE_CONNECTION,
  GET_DRIVING_LICENSES,
  GET_DRIVING_LICENSES_BY_DRIVER,
  DELETE_DRIVERS_LICENSE_DRIVER_CONNECTION
} from "../api"
import {getDriverLicenseIdAccordingToClass} from "../helper"
import {StyledDropDown} from "./styledComponents"
/* eslint-disable */
const DriverLicenseTags = ({currentID}) => {
  const [deleteDriversLicenseDriverConnection] = useMutation(
    DELETE_DRIVERS_LICENSE_DRIVER_CONNECTION
  )
  const {data: drivingLicenseData} = useQuery(GET_DRIVING_LICENSES)
  const {data: drivingLicenseDataFromDriver} = useQuery(
    GET_DRIVING_LICENSES_BY_DRIVER,
    {
      variables: {
        driverId: currentID
      }
    }
  )
  const [createDriverLicenseConnection] = useMutation(
    CREATE_DRIVER_LICENSE_CONNECTION
  )
  const {t} = useTranslation()

  const handleClose = (removedTag) => {
    deleteDriversLicenseDriverConnection({
      variables: {
        driverId: currentID,
        licenseId: getDriverLicenseIdAccordingToClass(
          removedTag.driving_licence.class,
          drivingLicenseData
        )
      },
      refetchQueries: [
        {
          query: GET_DRIVING_LICENSES_BY_DRIVER,
          variables: {
            driverId: currentID
          }
        }
      ]
    })
  }

  const handleInputConfirm = (selectedDriverLicense) => {
    try {
      createDriverLicenseConnection({
        variables: {
          licenseId: getDriverLicenseIdAccordingToClass(
            selectedDriverLicense,
            drivingLicenseData
          ),
          driverId: currentID
        },
        refetchQueries: [
          {
            query: GET_DRIVING_LICENSES_BY_DRIVER,
            variables: {
              driverId: currentID
            }
          }
        ]
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {drivingLicenseDataFromDriver?.drivers_driving_licenses &&
        Array.isArray(drivingLicenseDataFromDriver?.drivers_driving_licenses) &&
        drivingLicenseDataFromDriver?.drivers_driving_licenses.map((tag) => {
          return (
            <Tag
              className="edit-tag"
              key={tag.license_id}
              onClose={() => handleClose(tag)}
              closable
            >
              <span>{tag?.driving_licence.class}</span>
            </Tag>
          )
        })}

      <StyledDropDown
        placeholder={t("users.pleaseSelect")}
        onChange={handleInputConfirm}
      >
        {drivingLicenseData?.driving_licenses &&
          Array.isArray(drivingLicenseData.driving_licenses) &&
          drivingLicenseData.driving_licenses
            .filter(
              (item) =>
                !drivingLicenseDataFromDriver?.drivers_driving_licenses.some(
                  (item2) => Number(item.id) === Number(item2.license_id)
                )
            )
            .map((license) => (
              <Select.Option key={license.id} value={license.class}>
                {license.class}
              </Select.Option>
            ))}
      </StyledDropDown>
    </>
  )
}

export default DriverLicenseTags
