import React from "react"
import {Outlet, useLocation, Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {Breadcrumb, Spin} from "antd"
import {LoadingOutlined} from "@ant-design/icons"
import {Styled} from "./AdminFlow.styled"
import {MainContentSpinnerWrapper} from "../main/main.styled"

const AdminFlow = ({menuItems, pending}) => {
  const {t} = useTranslation()

  const breadcrumbNameMap = {
    users: t("menu.users"),
    vehicle: t("menu.vehicle"),
    scenarios: t("menu.scenarios"),
    export: t("menu.exportData")
  }

  const location = useLocation()

  const extraBreadcrumbItems = (
    <Breadcrumb.Item>
      <Link to={location.pathname}>
        {breadcrumbNameMap[location.pathname.split("/")[2]]}
      </Link>
    </Breadcrumb.Item>
  )

  const currentMenuItem = menuItems.find(
    (item) => location.pathname === `/admin/${item.path}`
  )

  const pageTitleTranslationHandle =
    currentMenuItem && currentMenuItem.textTranslationHandle

  const pageTitleTranslation = pageTitleTranslationHandle
    ? t(pageTitleTranslationHandle)
    : ""

  return (
    <Styled.MainBox>
      <Styled.PageHeadlineBox>
        <Styled.PageHeadlineBoxLeft>
          <h4>
            <b>{pageTitleTranslation.toUpperCase()}</b>
          </h4>
        </Styled.PageHeadlineBoxLeft>
        <Styled.PageHeadlineBoxRight>
          <Breadcrumb>{extraBreadcrumbItems}</Breadcrumb>
        </Styled.PageHeadlineBoxRight>
      </Styled.PageHeadlineBox>
      {pending ? (
        <MainContentSpinnerWrapper>
          <Spin
            style={{
              scale: "4"
            }}
            indicator={<LoadingOutlined spin />}
          />
        </MainContentSpinnerWrapper>
      ) : (
        <Outlet />
      )}
    </Styled.MainBox>
  )
}

export default AdminFlow
