import {Row, Modal, Button} from "antd"
import styled from "styled-components"

export const LeftSideFormContainer = styled.div`
  width: 100%;
  border-radius: 25px;
`

export const FormContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 40px auto 0px;
  border-radius: 25px;
  margin-bottom: 30px;
`

export const SusiPortrait = styled.img`
  width: 197.6px;
  height: 128px;
  right: 40px;
`

export const SusiPortraitWrapper = styled(Row)`
  background-color: #feeec4;
  border-radius: 10px 10px 0px 0px;
`
export const TitleBox = styled.div``

export const OrangeText = styled.p`
  color: #f1b44c;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  color: #495057;
  margin-bottom: 0px;
`

export const GreyText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #495057;
`

export const ModalStyle = styled(Modal)`
  border-radius: 25px !important;
  -moz-border-radius: 25px;
  position: relative;
  .ant-modal-footer {
    border-top: 0px;
  }
  padding: 0px;
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 0px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  box-shadow: -1px 0px 12px 0px rgba(0, 0, 0, 0.75);
`

export const OutterRowWrapper = styled(Row)`
  border-radius: 25px;
`

export const TextBox = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #495057;
  margin-bottom: 30px;
`

export const DescriptionBox = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #495057;
`

export const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  background: #faba15;
  border: 1px solid #faba15;
  border-radius: 4px;

  margin-left: 25px;
  color: #ffffff;
`

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const Description = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
`

export const Styled = {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  GreyText,

  OutterRowWrapper,
  TitleBox,
  DescriptionBox,
  TextBox,
  ButtonsGroup,
  ButtonStyled,
  Description
}
