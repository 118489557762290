import styled from "styled-components"
import {Steps} from "antd"

const {Step} = Steps

export const TimelineBoxLight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0;

  @media screen and (max-width: 700px) {
    margin-bottom: 0px;
    margin-top: 20px;
  }
`

export const TimelineBoxLightTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #495057;
`
export const BoxLightHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: space-between;
`
export const LightHeaderStart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 75px;
  background: #dedede;
`

export const LightHeaderFinish = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 75px;
  background: #dedede;
`

export const TimelineBoxInside = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 280px;
  background: #f8f8f8;
  margin-top: 20px;
  padding: 30px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`

export const BoxProgressRelative = styled.div`
  position: relative;
`

export const BoxProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f8f8f8;
  margin-top: 0px;
  padding: 30px;
  padding-top: 10px;
  .ant-progress-bg,
  .ant-progress-inner {
    border-radius: 0px !important;
  }
`

export const BoxShowCount = styled.div`
  position: absolute;
  top: -30px;
  left: 200px;
  width: 75px;
  text-align: center;
  padding-top: 3px;
  height: 30px;

  transition: 0.4s;
  background: #faba15;
  border: 1px solid #faba15;
  color: white;
  ${({value}) =>
    `
    left:  ${value};
    `}
`

export const DescribeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #495057;
  margin: 50px 0;

  @media screen and (max-width: 700px) {
    margin-bottom: 5px;
    margin-top: 10px;
  }
`
export const Ul = styled.ul`
  padding-left: 20px;
  margin: 0px;
`

export const StepBox = styled(Step)`
  cursor: pointer;
`

export const ButtonListBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 4px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #495057;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  background: #ffffff;
  transition: 0.4s;
  cursor: pointer;

  border: 2px solid #f2f2f2;
  ${({active}) =>
    active &&
    `
    border: 2px solid #FABA15;
    `}

  @media screen and (max-width: 700px) {
    width: 20px;
    height: 20px;
  }
`
export const ButtonsTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #808080;
  transform: rotate(-45deg);
`

export const NumberCursor = styled.div`
  position: absolute;
  height: 12px;
  width: 2px;
  background-color: #808080;
  left: 50%;
  transform: translateX(-50%);
  top: 31px;
`

export const Styled = {
  TimelineBoxLight,
  TimelineBoxLightTitle,
  TimelineBoxInside,

  DescribeBox,
  Ul,
  StepBox,

  BoxLightHeader,
  LightHeaderStart,
  LightHeaderFinish,
  BoxProgress,
  BoxProgressRelative,
  BoxShowCount,

  ButtonListBox,
  Buttons,
  ButtonsTitle,
  NumberCursor
}
