import React, {useContext} from "react"
import {Steps} from "antd"
import {useNavigate, useParams} from "react-router-dom"
import {Styled} from "./fragen.styled"
import {UserContext} from "../../../context/context"
import Pagination from "../../../common/pagination"

const Fragen = ({startCount, FragenListTitle, FragenList, before, after}) => {
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)

  const {tabId, stageId} = useParams()
  const navigate = useNavigate()

  const setFragenStep = (index) => {
    // set Fragen viewed

    if (!stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`))
      handlerSetUser({
        isFragenViewed: [...stateUserFlow.isFragenViewed, `${tabId}${stageId}`]
      })

    if (tabId === "1") navigate(`/drive/${tabId}/${index + 1}`)
    if (tabId === "3") navigate(`/drive/${tabId}/${index}`)
  }

  return (
    <>
      <Styled.MainBox>
        {FragenListTitle !== null && (
          <Styled.InsideBox>
            <Styled.TitleBox>
              <Steps
                size="small"
                // before active and active color text = black, after active color text=gray
                current={
                  tabId === "1"
                    ? Number(stageId) + startCount - 1
                    : Number(stageId) + startCount
                }
                color="red"
                type="navigation"
                // number start counter
                initial={startCount}
              >
                {FragenListTitle.map((item, index, arr) => (
                  <Styled.StepBox
                    title={item.name}
                    type="navigation"
                    className="site-navigation-steps"
                    key={item.name}
                    onClick={() => setFragenStep(index)}
                    // current active cirle background=orange
                    isactive={
                      (tabId === "1" && index === Number(stageId) - 1) ||
                      (tabId === "3" && index === Number(stageId))
                        ? "true"
                        : ""
                    }
                    isafter={
                      // color circle before active border=orange
                      // color circle before active border=gray
                      (tabId === "1" && index > Number(stageId) - 1) ||
                      (tabId === "3" && index > Number(stageId))
                        ? "true"
                        : ""
                    }
                    $isLast={index === arr.length - 1}
                  />
                ))}
              </Steps>
            </Styled.TitleBox>
            {FragenList}
            <Pagination before={before} after={after} />
          </Styled.InsideBox>
        )}
      </Styled.MainBox>
    </>
  )
}

export default Fragen
