import React, {useContext, useMemo, useState, Suspense, useEffect} from "react"
import {Spin} from "antd"
import {useNavigate} from "react-router-dom"
import {useMutation, useQuery} from "@apollo/client"
import {Styled} from "./questionnaire-answers-overview.styled"
import Pagination from "../../common/pagination"
import Achtung from "../../common/achtung"
import {DataContext} from "../../context/context"
import {getDayYear, formMissedQuestions} from "../../middleware/math"
import ModalSusi from "../modal-susi"
import {deleteCookie} from "../../middleware/auth"
import {stateObj} from "../../const/contexts"
import {
  UPDATE_HISTORY_TIME_END,
  GET_TEST_SCENARIOS_HISTORY_DIALOGS
} from "../../const/const"
import ModalFinish from "../modal-finish"
import {formatISODate, zeroPad} from "../../utils/utils"

const QuestionnaireAnswersOverview = ({before, after, allQuestions}) => {
  const {state, handlerSet} = useContext(DataContext)
  const [isVisible, setIsVisible] = useState(false)
  const [isVisibleFinish, setIsVisibleFinish] = useState(false)
  const [currentDialogIdx, setCurrentDialogIdx] = useState(false)
  const [allDialogData, setAllDialogData] = useState()
  const [currentDialog, setCurrentDialog] = useState()

  const dataSourseTable = useMemo(
    () => formMissedQuestions(allQuestions),
    [allQuestions]
  )

  const {
    loading,
    error,
    data: dialogData,
    refetch
  } = useQuery(GET_TEST_SCENARIOS_HISTORY_DIALOGS, {
    variables: {
      testScenarioHistoryId: state.historyId
    }
  })

  useEffect(() => {
    if (!loading && dialogData && refetch) {
      refetch({testScenearioHistoryId: state.historyId})
        .then((res) => console.log("Refech of dialog data successfull", res))
        .catch((err) => console.error("Error refetching dialog data", err))
    }
  }, [])

  useEffect(() => {
    setAllDialogData(dialogData)
  }, [dialogData])

  const navigate = useNavigate()

  const handlerSetFragen = (isbefore, fragenIndex) => {
    if (isbefore) {
      navigate(`/drive/1/${fragenIndex + 1}`)
    }
    if (!isbefore) {
      navigate(`/drive/3/${fragenIndex}`)
    }
  }

  const [MutateHistory] = useMutation(UPDATE_HISTORY_TIME_END)

  const handleDialogClick = (dialogObj) => {
    setCurrentDialog(dialogObj)
    setIsVisible(true)
  }

  /* eslint-disable consistent-return */
  const TestDriveDialogs = () => {
    const data = allDialogData?.test_scenarios_history_dialogs ?? []
    return data.map((item, i) => {
      const dialogJson = JSON.parse(item?.dialog_json ?? "[]")
      let isEmpty = false
      let isTriggerDialog = false
      if (dialogJson.length) {
        if (/misc|trigger/gi.test(dialogJson[0].message)) {
          isTriggerDialog = true
        }
      } else {
        isEmpty = true
      }

      let name = `Dialog ${i + 1}`
      if (isTriggerDialog) {
        name += ` (Trigger)`
      }

      if (isEmpty) {
        name += ` (Leer)`
      }

      return (
        <Styled.ItemsList
          key={name}
          $isTrigger={isTriggerDialog}
          $isEmpty={isEmpty}
          $isDialog
        >
          <Styled.ItemGroupBox>
            <Styled.ItemText>{name}</Styled.ItemText>
            <Styled.ItemGroupDateEdit>
              <Styled.ItemDate>
                {formatISODate(item.created_at)}
              </Styled.ItemDate>
              <Styled.ItemEdit>
                <Styled.ItemEditSvg onClick={() => handleDialogClick(item)} />
              </Styled.ItemEdit>
            </Styled.ItemGroupDateEdit>
          </Styled.ItemGroupBox>
        </Styled.ItemsList>
      )
    })
  }

  // logout
  const setDefaultState = () => {
    handlerSet(stateObj)
  }

  const logout = () => {
    deleteCookie()
      .then(() => {
        setDefaultState()
        navigate("/login")
      })
      .catch(() => null)
  }

  const handleDataSet = async () => {
    if (!state.user_id) return

    await MutateHistory({
      variables: {
        historyId: state.historyId
      }
    }).then(() => {
      logout()
    })
  }

  const handleClick = () => {
    setIsVisibleFinish(true)
  }

  return (
    <>
      <ModalSusi
        text="So funktioniert die App:"
        closable={false}
        description={null}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        refetchDialogs={refetch}
        allDialogData={allDialogData}
        setAllDialogData={setAllDialogData}
        currentDialog={currentDialog}
      />
      <ModalFinish
        closable={false}
        isVisibleFinish={isVisibleFinish}
        setIsVisibleFinish={setIsVisibleFinish}
        handleDataSet={handleDataSet}
        dataSourseTable={dataSourseTable}
      />
      <Styled.MainBox>
        <Styled.BoxAlign>
          <Styled.InsideBox>
            <Styled.AchtungMargin>
              <Achtung
                isShow
                title="dancer.titleOverview"
                description="dancer.descTempOverview"
              />
            </Styled.AchtungMargin>
            <Styled.ColumnAlign>
              <Styled.ColumnBox $isfirst>
                <Styled.BoxHeaderRelative>
                  <Styled.BoxHeader>
                    <Styled.HeaderDate>{getDayYear()}</Styled.HeaderDate>
                    <Styled.HeaderText>Vor der Fahrt</Styled.HeaderText>
                    <Styled.HeaderArrowBox>
                      <Styled.HeaderArrowImg />
                    </Styled.HeaderArrowBox>
                  </Styled.BoxHeader>
                </Styled.BoxHeaderRelative>
                <Styled.ItemsBox>
                  {before.map((item, index) => {
                    const isNoAnswer = dataSourseTable.some(
                      (itemFragen) => itemFragen.question === item.name
                    )
                    return (
                      <Styled.ItemsList
                        key={item.id}
                        $isDialog={false}
                        $isNoAnswer={isNoAnswer}
                      >
                        <Styled.ItemGroupBox>
                          <Styled.ItemText>
                            {item.name}
                            {isNoAnswer && " (Unbeantwortete Fragen)"}
                          </Styled.ItemText>
                          <Styled.ItemGroupDateEdit $isFragen>
                            <Styled.ItemEdit>
                              <Styled.ItemEditSvg
                                onClick={() => handlerSetFragen(true, index)}
                              />
                            </Styled.ItemEdit>
                          </Styled.ItemGroupDateEdit>
                        </Styled.ItemGroupBox>
                      </Styled.ItemsList>
                    )
                  })}
                </Styled.ItemsBox>
              </Styled.ColumnBox>
              <Styled.ColumnBox>
                <Styled.BoxHeaderRelative>
                  <Styled.BoxHeader>
                    <Styled.HeaderDate>{getDayYear()}</Styled.HeaderDate>
                    <Styled.HeaderText>Protokoll SUSI</Styled.HeaderText>
                    <Styled.HeaderArrowBox>
                      <Styled.HeaderArrowImg />
                    </Styled.HeaderArrowBox>
                  </Styled.BoxHeader>
                </Styled.BoxHeaderRelative>
                <Styled.ItemsBox>
                  {loading ? <Spin /> : <TestDriveDialogs />}
                </Styled.ItemsBox>
              </Styled.ColumnBox>
              <Styled.ColumnBox $islast>
                <Styled.BoxHeaderRelative>
                  <Styled.BoxHeader>
                    <Styled.HeaderDate>{getDayYear()}</Styled.HeaderDate>
                    <Styled.HeaderText>Nach der Fahrt</Styled.HeaderText>
                    <Styled.HeaderArrowBox>
                      <Styled.HeaderArrowImg />
                    </Styled.HeaderArrowBox>
                  </Styled.BoxHeader>
                </Styled.BoxHeaderRelative>
                <Styled.ItemsBox>
                  {after.map((item, index) => {
                    const isNoAnswer = dataSourseTable.some(
                      (itemFragen) => itemFragen.question === item.name
                    )
                    return (
                      <Styled.ItemsList
                        key={item.id}
                        $isDialog={false}
                        $isNoAnswer={isNoAnswer}
                      >
                        <Styled.ItemGroupBox>
                          <Styled.ItemText>
                            {item.name}
                            {isNoAnswer && " (Unbeantwortete Fragen)"}
                          </Styled.ItemText>
                          <Styled.ItemGroupDateEdit $isFragen>
                            <Styled.ItemEdit>
                              <Styled.ItemEditSvg
                                onClick={() => handlerSetFragen(false, index)}
                              />
                            </Styled.ItemEdit>
                          </Styled.ItemGroupDateEdit>
                        </Styled.ItemGroupBox>
                      </Styled.ItemsList>
                    )
                  })}
                </Styled.ItemsBox>
              </Styled.ColumnBox>
            </Styled.ColumnAlign>
          </Styled.InsideBox>
          <Pagination
            before={before.length}
            after={after.length}
            handleClick={handleClick}
          />
        </Styled.BoxAlign>
      </Styled.MainBox>
    </>
  )
}

export default QuestionnaireAnswersOverview
