export const zeroPad = (numStr) => {
	const num = parseInt(numStr, 10);
	return num >= 10 ? num.toString() : `0${num.toString()}`;
}

export const formatISODate = (isoDateStr) => {
	if(!isoDateStr) { 
		return "";
	}

  const date = new Date(isoDateStr);
  const month = zeroPad(date.getMonth()+1);
  const day = zeroPad(date.getDate());
  const hours = zeroPad(date.getHours());
  const minutes = zeroPad(date.getMinutes());
  const seconds = zeroPad(date.getSeconds());
  return `${date.getFullYear()}/${month}/${day} ${hours}:${minutes}:${seconds}`
}

