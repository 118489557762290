import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0;
  margin-top: 0px;

  @media screen and (max-width: 700px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`

export const TimeBoxTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #495057;
`

export const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TimeBoxInside = styled.div`
  justify-content: space-between;
  margin-top: 10px;
`
export const InsideText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #495057;

  padding-right: 30px;
  min-width: 250px;
  width: 250px;
  margin: 5px;
`

export const Container = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: flex-start;
`

export const DateInput = styled.input`
  border: 1px solid #d9d9d9;
  width: 140px;
  padding: 8px 10px;
  border-radius: 2px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: #495057;
  max-height: 52px;

  ${({value, placeholder}) =>
    value === "" &&
    `
    :before {
      content: "${placeholder}";
      color: #9d9d9d;
      position: absolute;
      background: #fff;
      width: 100px;
    }
  
    :focus:before {
      width: 0;
      content: "";
    }

    `}

  ${({active}) =>
    active &&
    `
    border : 1px solid #d9d9d9;

    `}

    ${({$isShow}) =>
    $isShow &&
    `
    border : 1px solid red;

    `}
`

export const DayButton = styled.button`
  padding: 8px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: 0.4s;
  padding: 0 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: #495057;
  max-height: 52px;
  ${({active}) =>
    active &&
    `
    color: #FFFFFF;
    background: #FABA15;
    border: 1px solid #FABA15;
  `}

  ${({first}) =>
    first &&
    `
    border-radius: 2px 0px 0px 2px;

  `}

  ${({last}) =>
    last &&
    `
    border-radius: 0px 2px 2px 0px;

  `}
`

export const Styled = {
  MainBox,
  TimeBoxTitle,
  TimeBox,
  TimeBoxInside,
  InsideText,
  Container,
  DateInput,
  DayButton
}
