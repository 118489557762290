import {useContext, useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {DataContext} from "../context/context"
import {
  checkUserAllowedPath,
  isAllowedPath,
  isAuthDataWithoutParameter,
  isLogIn
} from "../middleware/auth"

const useAuth = () => {
  const {state, handlerSet} = useContext(DataContext)
  const [pending, setPending] = useState(false)

  const location = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const isLoginPath = location.pathname === "/login"
      const isHomePath = location.pathname === "/"

      setPending(true)

      const dataFromRequest = await isAuthDataWithoutParameter(
        handlerSet
      ).catch(() => false)

      const isLogin = dataFromRequest?.user_type

      if (!isLogin && !isLoginPath) {
        navigate(
          `/login?redirectTo=${location.pathname}&scenarioId=${state.scenarioActive}&historyId=${state.historyId}`
        )
      } else if (
        (isLogin &&
          !isAllowedPath(location.pathname, dataFromRequest.user_type)) ||
        (isLogin && (isLoginPath || isHomePath)) ||
        (location.pathname.includes("drive") &&
          location.pathname !== "/drive" &&
          state.historyId === null)
      ) {
        navigate(
          `/${checkUserAllowedPath(
            dataFromRequest.user_type,
            location.pathname
          )}`
        )
      }

      setPending(false)
    })()
  }, [location.pathname])

  return [pending, isLogIn]
}

export default useAuth
