import React, {useContext} from "react"
import {DataContext} from "../../context/context"
import LanguageSelector from "../languageSelector"
import TopMenu from "../top-menu"
import Account from "../account"
import {Styled} from "./header.styled"
import CompanyLogo from "../../images/companylogo.png"

const Header = () => {
  const {state} = useContext(DataContext)

  return (
    <Styled.MainBox>
      <Styled.InsideBox>
        <Styled.BoxLeft>
          {/* {state.user_type !== "research_director" && (
            <Styled.BoxMenu>
              <Styled.BoxMenuLogo
                width="40"
                height="40"
                viewBox="0 0 35 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.24902 6.3125H33.749C34.374 6.3125 34.999 5.76562 34.999 5.0625V1.9375C34.999 1.3125 34.374 0.6875 33.749 0.6875H1.24902C0.545898 0.6875 -0.000976562 1.3125 -0.000976562 1.9375V5.0625C-0.000976562 5.76562 0.545898 6.3125 1.24902 6.3125ZM1.24902 18.8125H33.749C34.374 18.8125 34.999 18.2656 34.999 17.5625V14.4375C34.999 13.8125 34.374 13.1875 33.749 13.1875H1.24902C0.545898 13.1875 -0.000976562 13.8125 -0.000976562 14.4375V17.5625C-0.000976562 18.2656 0.545898 18.8125 1.24902 18.8125ZM1.24902 31.3125H33.749C34.374 31.3125 34.999 30.7656 34.999 30.0625V26.9375C34.999 26.3125 34.374 25.6875 33.749 25.6875H1.24902C0.545898 25.6875 -0.000976562 26.3125 -0.000976562 26.9375V30.0625C-0.000976562 30.7656 0.545898 31.3125 1.24902 31.3125Z"
                  fill="#555B6D"
                />
              </Styled.BoxMenuLogo>
            </Styled.BoxMenu>
          )} */}
          <Styled.BoxCompany>
            <Styled.BoxCompanyLogo
              $isResize={state.user_type === "research_director"}
              draggable="false"
              src={CompanyLogo}
            />
          </Styled.BoxCompany>
        </Styled.BoxLeft>
        {state.user_type === "research_director" && (
          <Styled.BoxCenter>
            <TopMenu />
          </Styled.BoxCenter>
        )}
        <Styled.BoxRight>
          <Styled.BoxLanguage>
            <LanguageSelector />
          </Styled.BoxLanguage>
          <Styled.BoxAccount>
            <Account />
          </Styled.BoxAccount>
        </Styled.BoxRight>
      </Styled.InsideBox>
    </Styled.MainBox>
  )
}

export default Header
