import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react"
import {useMutation} from "@apollo/client"
import {useNavigate, useParams} from "react-router-dom"
import {cutText} from "../../middleware/math"
import {Styled} from "./question-step.styled"
import {INSERT_ANSWER, UPDATE_ANSWER} from "../../const/const"
import {DataContext, UserContext} from "../../context/context"
import {isAuthDataWithoutParameter} from "../../middleware/auth"

const QuestionStep = ({
  data,
  lastConnected,
  size,
  questionId,
  answer: answerParametr,
  handleSetIsshow
}) => {
  const [answer, setAnswer] = useState(answerParametr)

  const {state, handlerSet} = useContext(DataContext)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)

  const smallButton =
    answer.length !== 0 && size === "small"
      ? data.filter(
          (itemSearch) => itemSearch.bottomLabel === answer[0].answer
        )[0].id
      : -1

  const bigButton =
    answer.length !== 0 && size === "big"
      ? data.filter(
          (itemSearch) => itemSearch.buttonName === Number(answer[0].answer)
        )[0].id
      : -1

  const [timelineProgress, setTimelineProgress] = useState(
    size === "small" ? smallButton : bigButton
  )

  const [lengthWith, setLengthWith] = useState(0)

  const [updateMutation, {loading}] = useMutation(UPDATE_ANSWER)

  const {tabId, stageId} = useParams()

  // set appear achtung message
  useEffect(() => {
    // if (true) - show message
    // if (not setted and this page is in array)
    handleSetIsshow(
      timelineProgress === -1 &&
        stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
    )
  }, [timelineProgress])

  const [insertMutation, {loading: insertMutationLoading}] =
    useMutation(INSERT_ANSWER)

  const ref = useRef(null)

  const boxWidth = size === "small" ? 16 : 30
  const padding = 100
  const navigate = useNavigate()

  async function updateData(index) {
    if (answer.length !== 0) {
      await updateMutation({
        variables: {
          answerId: answer[0].id,
          answer: size === "small" ? data[index].bottomLabel : `${index}`
        }
      })
    } else {
      const insertFieldId = await insertMutation({
        variables: {
          answer: size === "small" ? data[index].bottomLabel : `${index}`,
          question_id: questionId,
          test_scenario_history_id: state.historyId
        }
      })
      setAnswer([
        {
          id: insertFieldId.data.insert_questions_answers_one.id,
          answer: data[index].bottomLabel
        }
      ])
    }
  }

  const handleClick = (index) => {
    if (loading || insertMutationLoading) return
    setTimelineProgress(index)
    updateData(index).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(index)
          else navigate("/login")
        })
      }
    })
  }

  useLayoutEffect(() => {
    if (data.length > 1)
      setLengthWith(
        ref.current.offsetWidth / (data.length - 1) -
          boxWidth / 2 -
          padding / (data.length - 1)
      )
  }, [])

  return (
    <Styled.ButtonListBox>
      <Styled.GroupBox ref={ref}>
        {data.map((item, index, arr) => {
          const maxLabel = arr
            .map((itemLabel) => itemLabel?.bottomLabel)
            .sort((a, b) => b.length - a.length)[0]

          return (
            <Styled.StepBoxRelative
              key={item.id}
              $labelLength={
                maxLabel.split(" ").length < 2 || maxLabel.length < 18
                  ? 0
                  : maxLabel.split(" ").length
              }
            >
              <Styled.TopLabelAbsolute
                active={index === timelineProgress}
                isGray={index === 0 || index === data.length - 1}
                size={size === "small" ? "true" : ""}
              >
                <Styled.TopLabelAbsoluteText
                  active={index === timelineProgress}
                  isGray={index === 0 || index === data.length - 1}
                  size={size === "small" ? "true" : ""}
                >
                  {cutText(item.topLabel, 9)}
                </Styled.TopLabelAbsoluteText>
              </Styled.TopLabelAbsolute>
              {index < data.length - 1 &&
                !(!lastConnected && index === data.length - 2) && (
                  <Styled.LineBox
                    size={size === "small" ? "true" : "false"}
                    width={lengthWith}
                  />
                )}
              <Styled.Buttons
                active={index === timelineProgress}
                size={size === "small" ? "16px" : "30px"}
                onClick={() => handleClick(index)}
              >
                <Styled.ButtonsTitle>{item.buttonName}</Styled.ButtonsTitle>
              </Styled.Buttons>
              {size === "small" && (
                <Styled.BottomLabelAbsolute>
                  {item.topLabel}
                </Styled.BottomLabelAbsolute>
              )}
            </Styled.StepBoxRelative>
          )
        })}
      </Styled.GroupBox>
    </Styled.ButtonListBox>
  )
}

export default QuestionStep
