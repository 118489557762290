/* eslint-disable semi */
import {Row, Button, Modal} from "antd"
import styled from "styled-components"

export const LeftSideFormContainer = styled.div`
  width: 100%;
  border-radius: 25px;
`

export const FormContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 40px auto 0px;
  border-radius: 25px;
`

export const SusiPortrait = styled.img`
  width: 197.6px;
  height: 128px;
  right: 40px;
`
export const ContactWrapper = styled.div`
  margin-top: -35px;
  margin-bottom: -35px;
  margin-left: 11%;
`
export const ContactEllipse = styled.img``

export const ContactLogo = styled.img`
  position: relative;
  top: 0;
  right: 51px;
  z-value: 2;
`

export const SusiPortraitWrapper = styled(Row)`
  background-color: #feeec4;
  justify: space-evenly;
  border-radius: 10px 10px 0px 0px;
`

export const OrangeText = styled.p`
  color: #f1b44c;
  margin-bottom: 3;
  font-style: normal;
  font-weight: 500;
  font-size: 16.16px;
  line-height: 24px;
`

export const GreyText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #74788d;
`

export const LoginWrapper = styled(Row)`
  background-color: inherit;
  flex: 1;
  padding: 0px;
  margin-top: -15px;
`

export const ForgotPassword = styled(Row)`
  justify-content: center;
  align-items: center;
`

export const PrimaryButton = styled(Button)`
  background-color: #f1b44c;
  flex: 1;
  align: "middle";
  margin: auto;
  display: block;
  margin-top: 8px;
`

export const RegisterAnchor = styled.a`
  link: blue;
  active: blue;
`

export const NewAccountRow = styled(Row)`
  display: flex;
  allign-items: center;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
`

export const ModalStyle = styled(Modal)`
  border-radius: 25px !important;
  -moz-border-radius: 25px;
  position: relative;
  .ant-modal-footer {
    border-top: 0px;
  }
  padding: 0px;
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 0px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  box-shadow: -1px 0px 12px 0px rgba(0, 0, 0, 0.75);
`

export const OutterRowWrapper = styled(Row)`
  border-radius: 25px;
`
