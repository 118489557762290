import styled from "styled-components"
import {Table, Collapse} from "antd"
import {ReactComponent as PlusSvg} from "../../images/Plus.svg"

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);

  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;

  margin: 40px;
`

export const InsideBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  width: 100%;
`

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  width: 100%;
`

export const DescriptionTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #495057;

  margin-bottom: 20px;
  margin-left: -8px;
`
export const DescriptionButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  background: #f1b44c;
  border: 1px solid #f1b44c;
  border-radius: 4px;

  height: 36px;

  cursor: pointer;
`
export const DescriptionButtonIcon = styled(PlusSvg)`
  margin-left: 11px;
  width: 13px;
  height: 13px;
`

export const DescriptionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #ffffff;
  padding-left: 16px;
  padding-right: 30px;
  white-space: nowrap;
`

export const ListBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-top: 5px;
`

export const ListBoxTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 100%;
`

export const TableStyled = styled(Table)`
  && tbody > tr:hover > td {
    background: #ddd;
  }
  thead > tr > th {
    height: 54px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: rgba(0, 0, 0, 0.85);

    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 0px;
  }

  tbody {
    tr {
      td {
        height: 54px;

        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #495057;

        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 0px;

        text-overflow: ellipsis;
        cursor: pointer;
      }
    }
  }

  // pagination border color
  .ant-pagination-item-active {
    background-color: #faba15;

    border: 1px solid #faba15;
    border-radius: 2px;

    color: rgba(0, 0, 0, 0.85);
    transition: 0.3s;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
`

// right panel
export const TestGroupEdit = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  min-width: 300px;
  min-height: 150px;

  width: 100%;

  height: 100%;

  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 3.25px;

  padding: 21px 40px 25px 40px;
`

export const Error = styled.div`
  color: red;
`
export const CollapseStyle = styled(Collapse)`
  margin-top: 26px;
  margin-bottom: 15px;
`

export const Relative = styled.div`
  position: relative;
  z-index: 0;
`

export const Dark = styled.div`
  ${({$isLoading}) =>
    $isLoading &&
    `
    filter: brightness(80%) blur(1px);
  
    `}
`
export const WrapperAbsolute = styled.div`
  position: absolute;
  z-index: 1;
  min-width: 100%;
  height: 100%;
`

export const WrapperCollapse = styled.div`
  position: absolute;
  z-index: 1;

  min-width: 100%;
  min-height: 100px;
`

export const AlignSpin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 100%;
`

export const Styled = {
  Box,
  InsideBox,
  DescriptionBox,
  DescriptionTitle,
  DescriptionButtonBox,
  DescriptionButtonIcon,
  DescriptionButton,
  ListBoxStyle,
  ListBoxTitle,
  TableStyled,
  Error,
  TestGroupEdit,
  CollapseStyle,
  WrapperAbsolute,
  WrapperCollapse,
  Relative,
  AlignSpin,
  Dark
}
