import moment from "moment"

export const getGenderMapping = (gender) => {
  if (gender !== null) {
    if (Number(gender) === 0) {
      return "male"
    }
    if (Number(gender) === 1) {
      return "female"
    }
    if (Number(gender) === 2) {
      return "diverse"
    }

    return "notStated"
    /* eslint-disable-next-line */
  } else return undefined
}

export const getGenderIntegerMapping = (gender) => {
  if (gender === "male") {
    return "0"
  }
  if (gender === "female") {
    return "1"
  }
  if (gender === "diverse") {
    return "2"
  }
  return "3"
}

export const getUserType = (userType) => {
  if (userType === "Testleiter") return "Testleiter"

  if (userType === "Fahrer") return "Fahrer"

  return "Proband"
}

export const getDateOfBirth = (dateOfBirth) => {
  if (dateOfBirth) {
    return moment(dateOfBirth, "YYYY MM DD")
  }
  return null
}

export const getDriversLicenses = (driversLicenes, currentID) =>
  driversLicenes
    .filter((lice) => lice.driver_id === currentID.variables.id)
    .map((license) => license.driving_licence.class)

export const getDriversLicenseClass = (id, driversLicenses) =>
  driversLicenses
    .filter((license) => license.id === id)
    .map((lice) => lice.class)

export const getDriverLicenseIdAccordingToClass = (
  licenseClass,
  driverslicenses
) =>
  driverslicenses.driving_licenses
    .filter((lice) => lice.class === licenseClass)
    .flatMap((license) => license.id)[0]
