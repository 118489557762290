/* eslint-disable semi */
/* eslint-disable import/order */
import React, {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Select, Space, Form, Input, Checkbox, Button, message} from "antd"
import {LockFilled, UserOutlined} from "@ant-design/icons"
import {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  GreyText,
  LoginWrapper,
  ForgotPassword,
  PrimaryButton,
  ContactLogo,
  ContactEllipse,
  ContactWrapper,
  OutterRowWrapper
} from "./StyledComponents"

import SusiPortraitImg from "../../images/susi_portrait.png"
import Ellipse from "../../images/Ellipse.png"
import Contact from "../../images/Contact.png"
import {GRAPHQL_SERVER_URL, SWUI_BE_API_URL} from "../../const/const"
import axios from "axios"
import {DataContext} from "../../context/context"

const executeLogin = async (values, t) => {
  const data = await axios
    .post(`${SWUI_BE_API_URL}/api/login`, values, {withCredentials: true})
    .catch(() => message.error(t("login.loginFailed")))
  return data
}

const Login = ({
  setIsVisible,
  handleAuthorized,
  handleClickedForgotPassword
}) => {
  const {Option} = Select
  const {t} = useTranslation()
  const [formInstance] = Form.useForm()
  const [isResearchDirector, setIsResearchDirector] = useState(true)
  const {state, handlerSet} = useContext(DataContext)

  const handleUserTypeChange = (userType) => {
    if (userType !== "research_director") {
      setIsResearchDirector(false)
    } else {
      setIsResearchDirector(true)
    }
  }

  const onFinish = async (values) => {
    const response = await executeLogin(values, t)
    if (!response || !response.data) return
    setIsVisible(false)
    handleAuthorized(response.data)
  }

  const onFinishFailed = (error) => {
    message.error(t("login.loginFailed"))
  }

  const onClickForgotPassword = () => {
    console.log("now open password dialogue")
    setIsVisible(false)
    handleClickedForgotPassword()
  }

  return (
    <OutterRowWrapper>
      <LeftSideFormContainer>
        <SusiPortraitWrapper justify="space-evenly" align="middle" wrap={false}>
          <div>
            <OrangeText>{t("login.headlineWelcomeBack")}</OrangeText>
            <GreyText>{t("login.subHeadlineLoginAndStart")}</GreyText>
          </div>
          <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
        </SusiPortraitWrapper>
        <ContactWrapper>
          <ContactEllipse src={Ellipse} alt="contact background" />
          <ContactLogo src={Contact} alt="contact" />
        </ContactWrapper>
        <FormContainer>
          <Form
            onSubmit={executeLogin}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{user_type: "research_director"}}
            form={formInstance}
            layout="vertical"
          >
            <Form.Item
              form={formInstance}
              name="user_name"
              label={t("generic.forms.userName.label")}
              rules={[
                {
                  required: true,
                  message: t("generic.forms.userName.errorNoValue")
                }
              ]}
            >
              <Input placeholder={t("generic.forms.userName.placeholder")} />
            </Form.Item>

            <Form.Item
              form={formInstance}
              name="user_type"
              label={t("generic.forms.userType.label")}
              rules={[
                {
                  required: true,
                  message: t("generic.forms.userType.error")
                }
              ]}
            >
              <Select onChange={handleUserTypeChange}>
                <Option value="research_director">
                  <Space>
                    <UserOutlined />
                    {t("generic.forms.userType.types.researchDirector")}
                  </Space>
                </Option>
                <Option value="test_subject">
                  <Space>
                    <UserOutlined />
                    {t("generic.forms.userType.types.testSubject")}
                  </Space>
                </Option>
                <Option value="driver">
                  <Space>
                    <UserOutlined />
                    {t("generic.forms.userType.types.driver")}
                  </Space>
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              form={formInstance}
              name="password"
              label={t("generic.forms.password.label")}
              type="password"
              rules={[
                {
                  required: true,
                  message: t("generic.forms.password.errorNoValue")
                }
              ]}
            >
              <Input.Password
                placeholder={t("generic.forms.password.placeholder")}
              />
            </Form.Item>
            <Form.Item type="checkbox">
              <Checkbox>{t("login.rememberMe")}</Checkbox>
            </Form.Item>
            <LoginWrapper>
              <PrimaryButton
                type="submit"
                htmlType="submit"
                align="stretch"
                block
                id="buttonLogin"
              >
                {t("login.loginButton")}
              </PrimaryButton>
            </LoginWrapper>
            {/* {isResearchDirector && (
              <ForgotPassword>
                <LockFilled />
                <Button type="text" onClick={onClickForgotPassword}>
                  {t("login.forgotPasswordButton")}
                </Button>
              </ForgotPassword>
            )} */}
          </Form>
        </FormContainer>
      </LeftSideFormContainer>
    </OutterRowWrapper>
  )
}

export default Login
