import React, {useContext, useState} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate, useParams} from "react-router-dom"
import {Styled} from "./tabs.styled"
import {UserContext} from "../../context/context"
import {getFragenStep, handlerModalBeforeDrive} from "../../middleware/mathObj"
import {formMissedQuestions} from "../../middleware/math"

const Tabs = ({tabs, handleRefetch}) => {
  const {t} = useTranslation()
  const [isVisibleFinish, setIsVisibleFinish] = useState(false)
  const [lastTabClick, setLastTabClick] = useState(null)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)
  const navigate = useNavigate()

  const {tabId, stageId} = useParams()

  const handleSettab = async (count) => {
    const {data} = await handleRefetch()
    setLastTabClick(count)
    const dataQuestionBeforeDrive = formMissedQuestions(getFragenStep(data, 1))
    // check if all answer is filled before drive
    if (
      (count === 2 || count === 3) &&
      tabId !== "2" &&
      tabId !== "3" &&
      dataQuestionBeforeDrive.length !== 0
    ) {
      setIsVisibleFinish(true)

      return
    }

    // set Fragen viewed
    if (!stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`))
      handlerSetUser({
        isFragenViewed: [...stateUserFlow.isFragenViewed, `${tabId}${stageId}`]
      })

    navigate(`/drive/${count}/0`)
  }

  const modalBeforeDrive = handlerModalBeforeDrive(
    setIsVisibleFinish,
    isVisibleFinish,
    lastTabClick
  )

  return (
    <>
      {modalBeforeDrive}
      <Styled.MainBox>
        {tabs !== undefined && (
          <Styled.BoxTab>
            {tabs.stages.map((el) => (
              <Styled.TabDark
                key={el.id}
                onClick={() => handleSettab(el.id)}
                active={el.id === Number(tabId)}
              >
                <Styled.SvgContainer>
                  <Styled.Svg
                    viewBox="0 0 355 31"
                    fill={Number(tabId) === el.id ? "#FABA15" : "transparent"}
                    xmlns="http://www.w3.org/2000/svg"
                    style={{width: "100%", height: "100px"}}
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M14.3252 15.6352L2 2.80189V2.413L4.14351 1.63523L6.28702 1.24634H332.101H333.173L335.852 2.02412L337.46 2.80189L338.531 3.57967L353 15.6352L340.139 26.1352L337.46 27.6908L334.78 28.8574L332.637 29.2463H7.35878H6.28702L4.14351 28.8574L3.07176 28.4686L2 27.6908L14.3252 15.6352Z"
                      stroke="#FABA15"
                      strokeWidth="2"
                    />
                  </Styled.Svg>
                  <Styled.TitleSpan active={el.id === Number(tabId)}>
                    {/* {t(el.translationKey)} */}
                    {el.name}
                  </Styled.TitleSpan>
                </Styled.SvgContainer>
              </Styled.TabDark>
            ))}
          </Styled.BoxTab>
        )}
      </Styled.MainBox>
    </>
  )
}

export default Tabs
