import styled from "styled-components"

export const ContainerRhombus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 0;

  @media (max-width: 938px) {
    flex-wrap: wrap;
  }
`
export const ContainerRhombusItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: flex-start;
  padding: 10px 0;
  min-width: 426px;
  @media (max-width: 938px) {
    width: 100%;
  }
`

export const ContainerBox = styled.div`
  position: relative;
  margin-bottom: 10px;
`

export const ContainerNumber = styled.div`
  position: absolute;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #495057;

  ${({$isBigSpace}) =>
    $isBigSpace &&
    `
    left: -20px;
  `}

  ${({$isBigSpace}) =>
    !$isBigSpace &&
    `
    left: -13px;
  `}
`

export const ContainerRhombusText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #495057;

  padding-right: 30px;
  min-width: 250px;
  width: 250px;

  @media (max-width: 835px) {
    width: 100%;
  }
  white-space: pre-line;
  margin-left: 5px;
`

export const ContainerMainTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #495057;

  padding-top: 41px;
  padding-bottom: 41px;

  @media screen and (max-width: 700px) {
    padding-top: 10px;
    padding-bottom: 0px;
  }
`

export const ContainerMainTitleRelative = styled.div`
  position: relative;
`

export const ContainerNumberTitle = styled.div`
  position: absolute;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #495057;

  ${({$isBigSpace}) =>
    $isBigSpace &&
    `
    left: -20px;
  `}

  ${({$isBigSpace}) =>
    !$isBigSpace &&
    `
    left: -13px;
  `}
`
export const Styled = {
  ContainerRhombus,
  ContainerRhombusItem,
  ContainerBox,
  ContainerNumber,
  ContainerRhombusText,
  ContainerMainTitle,
  ContainerMainTitleRelative,
  ContainerNumberTitle
}
