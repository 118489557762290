import React, {useState} from "react"
import {Styled} from "./radiobutton-group.styled"
import RadioButton from "../../radio-buttons"
import {
  ContainerBox,
  ContainerMainTitle,
  ContainerMainTitleRelative,
  ContainerNumber,
  ContainerNumberTitle
} from "../rhombus/rhombus.styled"
import Line from "../line"
import {regex} from "../../../const/const"
import AchtungQuestion from "../../achtung-question/achtung.component"

function RadiobuttonGroup({
  title,
  options,
  question,
  isFirst,
  isLast,
  answer,
  data,
  questionId
}) {
  const [isShow, setIsShow] = useState(false)

  const handleSetIsshow = (value) => {
    setIsShow(value)
  }

  const findDecimal = question !== null ? question.match(regex) : null

  const findDecimalTitle = title !== null ? title.match(regex) : null

  const newData = data.split(";").map((item, index) => {
    const container = {}

    container.id = index
    container.title = item

    return container
  })

  return (
    <>
      {!isFirst && title !== null && <Line />}
      <AchtungQuestion
        isShow={isShow}
        title="Bitte ausfüllen! Falls nicht möglich, dem Versuchsleiter zeigen."
      />
      {title !== null && (
        <ContainerMainTitle>
          <ContainerMainTitleRelative>
            {title.match(regex) !== null && (
              <ContainerNumberTitle
                $isBigSpace={
                  findDecimalTitle !== null
                    ? Number(findDecimalTitle[0].split(".")[0]) >= 10
                    : 0
                }
              >
                {title.match(regex)}
              </ContainerNumberTitle>
            )}
          </ContainerMainTitleRelative>
          {findDecimalTitle !== null
            ? title.split(findDecimalTitle[0])[1]
            : title}
        </ContainerMainTitle>
      )}
      <Styled.Container>
        <ContainerBox>
          {question.match(regex) !== null && (
            <ContainerNumber
              $isBigSpace={
                findDecimal !== null
                  ? Number(findDecimal[0].split(".")[0]) >= 10
                  : 0
              }
            >
              {question.match(regex)}
            </ContainerNumber>
          )}
          <Styled.Text>
            {findDecimal !== null
              ? question.split(findDecimal[0])[1]
              : question}
          </Styled.Text>
        </ContainerBox>
        <RadioButton
          options={options}
          answer={answer}
          data={newData}
          questionId={questionId}
          handleSetIsshow={handleSetIsshow}
        />
      </Styled.Container>
      {!isFirst && isLast && title !== null && <Line />}
    </>
  )
}

export default RadiobuttonGroup
