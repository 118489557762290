import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
  padding: 10px 0;
`
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #495057;

  padding-right: 30px;
  min-width: 250px;
  width: 250px;
  margin-left: 5px;
  margin-right: 50px;

  @media screen and (max-width: 575px) {
    margin-right: 10px;
  }
`

export const Styled = {
  Text,
  Container
}
