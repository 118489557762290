import React, {useEffect, useState} from "react"
import {Styled} from "./button-toogleName-style.styled"

const ButtonToogleNameStyle = ({
  id,
  name,
  color,
  background,
  handleDataSet,
  status,
  selectedUser,
  userId,
  userType
}) => {
  const [nameState, setnameState] = useState(name)
  const [colorState, setcolorState] = useState(color)

  useEffect(() => {
    if (
      selectedUser?.some(
        (item) => item.userId === userId && item.userType === userType
      )
    ) {
      setnameState("Löschen")
      setcolorState("red")
    } else {
      setnameState("Hinzufügen")
      setcolorState("#F1B44C")
    }
  }, [selectedUser])

  return (
    <Styled.ButtonBox>
      <Styled.ButtonStyle
        id={`modal${id}`}
        $backgroundcolor={background}
        $color={colorState}
        onClick={() => {
          handleDataSet()
        }}
      >
        {nameState}
      </Styled.ButtonStyle>
    </Styled.ButtonBox>
  )
}

export default ButtonToogleNameStyle
