export const stateObj = {
  user_type: null,
  user_first_name: null,
  user_last_name: null,
  user_name: null,
  user_id: null,

  scenarioActive: 0,
  historyId: null,

  previousUserId: null,
  previousUserType: null
}

export const stateObjDefault = {
  user_type: null,
  user_first_name: null,
  user_last_name: null,
  user_name: null,
  user_id: null,

  scenarioActive: 0,
  historyId: null,
}

export const userObj = {
  isFragenViewed: [],
  newHistory: false,
  scenarioActiveMP3Id: 0
}
