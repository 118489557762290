import React, {useContext, useEffect, useState} from "react"
import {useQuery, gql, useMutation} from "@apollo/client"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {message} from "antd"
import ButtonStyle from "../../common/button-style"
import {DataContext, UserContext} from "../../context/context"
import {Styled} from "./scenario-list.styled"
import {getScenarioListData} from "../../middleware/mathObj"
import {getTimeScenarioList} from "../../middleware/math"
import {stateObj, userObj} from "../../const/contexts"
import {deleteCookie, isAuthDataWithoutParameter} from "../../middleware/auth"

const GET_SCENARIOS_LIST = gql`
  query GetScenariosList($test_subject_id: Int) {
    in_progress_scenarios: test_scenarios_history(
      where: {
        test_subject_id: {_eq: $test_subject_id}
        end_time: {_is_null: true}
        start_time: {_is_null: false}
      }
    ) {
      id
      created_at
      test_scenario {
        key: id
        scenarioName: name
        description
        mp3File: file {
          id
          name
          filePath: file_path
          fileContents: file_contents
        }
      }
      test_subjects_questions_answers_aggregate {
        aggregate {
          max {
            updated_at
            created_at
          }
        }
      }
    }
    all_scenarios_for_subject: test_scenarios(
      where: {
        test_group: {
          test_groups_members: {test_subject_id: {_eq: $test_subject_id}}
        }
      }
    ) {
      key: id
      scenarioName: name
      description
      mp3File: file {
        id
        name
        filePath: file_path
        fileContents: file_contents
      }
    }
  }
`

const INSERT_HISTORY = gql`
  mutation MyMutationHistory($test_scenario_id: Int, $test_subject_id: Int) {
    insert_test_scenarios_history_one(
      object: {
        created_at: "now()"
        start_time: "now()"
        test_scenario_id: $test_scenario_id
        test_subject_id: $test_subject_id
      }
    ) {
      id
    }
  }
`

const ButtonComponent = ({id, name, handleDataSet, paddingLeft}) => (
  <ButtonStyle
    color="white"
    borderColor="#faba15"
    borderSize={0}
    backgroundColor="#faba15"
    height={27}
    name={name}
    borderRadius={30}
    handleDataSet={() => handleDataSet(id)}
    paddingLeft={paddingLeft}
  />
)

const ScenarioList = () => {
  const [dataSource, setDataSource] = useState([])
  const {state, handlerSet} = useContext(DataContext)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)
  const navigate = useNavigate()
  const {t} = useTranslation()

  const {data, refetch: refetchGetScenarioListUser} = useQuery(
    GET_SCENARIOS_LIST,
    {
      variables: {test_subject_id: state.user_id},
      fetchPolicy: "no-cache",
      onError: ({graphQLErrors}) => {
        if (graphQLErrors.length > 0) {
          isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
            if (dataRes) refetchGetScenarioListUser()
            else navigate("/login")
          })
        }
      }
    }
  )

  const [MutateHistory, {refetch: refetchMutation}] =
    useMutation(INSERT_HISTORY)

  const handleMutation = async (parametr_scenarioId, parametr_user_id) => {
    const result = await MutateHistory({
      variables: {
        test_scenario_id: parametr_scenarioId,
        test_subject_id: parametr_user_id
      }
    })

    return result?.data?.insert_test_scenarios_history_one?.id
  }

  const handleDataSet = async (scenarioId, history, scenarioName) => {
    if (!state.user_id) return

    let newHistory = history

    if (newHistory === undefined) {
      try {
        newHistory = await handleMutation(scenarioId, state.user_id)
        // for modal show once on drive/1/0 if new history
        handlerSetUser({newHistory: true})
      } catch (err) {
        message.error("Fehler beim Erstellen eines neuen Szenarios")
        if (err.response.status === 403) {
          try {
            const dataRes = isAuthDataWithoutParameter(handlerSet)
            if (dataRes) {
              newHistory = await handleMutation(scenarioId, state.user_id)
              handlerSetUser({newHistory: true})
            }
          } catch (_) {
            navigate("/login")
          }
        }
      }
    }

    if (!newHistory) return

    handlerSet({
      scenarioActive: scenarioId,
      historyId: newHistory,
      scenarioName
    })

    navigate("/drive/1/0")
  }

  // set default userObj
  useEffect(() => {
    handlerSetUser(userObj)
  }, [])

  // set default userObj
  useEffect(() => {
    handlerSetUser(userObj)
  }, [])

  // logout
  const setDefaultState = () => {
    handlerSet(stateObj)
  }

  const logout = () => {
    deleteCookie()
      .then(() => {
        setDefaultState()
        navigate("/login")
      })
      .catch(() => null)
  }

  const handleClick = ({key}) => {
    logout()
  }

  useEffect(() => {
    if (!data) return

    const filtrData = getScenarioListData(data)

    setDataSource(
      filtrData?.map((item) => ({
        ...item,
        scenarioName: item.history
          ? `${item.scenarioName} (${item.history})`
          : item.scenarioName,
        scenarioButton: (
          <ButtonComponent
            id={item.key}
            name="Weiter"
            handleDataSet={() =>
              handleDataSet(item.scenarioId, item.history, item.scenarioName)
            }
            paddingLeft={12}
          />
        ),
        timeName: !item.time ? "" : getTimeScenarioList(item.time)
      }))
    )
  }, [data])

  const columns = [
    {
      title: "Szenario",
      dataIndex: "scenarioName",
      key: "scenarioName",
      width: 40
    },
    {
      title: "Time",
      dataIndex: "timeName", // the same field must be in data
      key: "time",
      align: "center",
      width: 20,
      onCell: (child, record) => ({
        children: child,
        props: {
          "data-time": record.time || ""
        }
      })
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "scenarioButton",
      align: "center",
      width: 20
    },
    {
      title: "",
      dataIndex: "scenarioButton",
      key: "scenarioButton",
      align: "center",
      width: 25
    }
  ]

  return (
    <Styled.Box>
      <Styled.InsideBox>
        <Styled.DescriptionBox>
          <Styled.DescriptionTitle>
            Sie können hier Testszenarien wählen. Mit welchem möchten Sie
            fortfahren?
          </Styled.DescriptionTitle>
          <Styled.DescriptionText>
            Wenn Sie im Voraus Fragen ausgefüllt haben, wählen Sie Ihr
            angefangenes Szenario, indem Sie entsprechend auf “Weiter” klicken.
            Sie können auch ein neues Szenario aus der Auswahl starten.
          </Styled.DescriptionText>
        </Styled.DescriptionBox>
        {dataSource.length > 0 && (
          <Styled.ListBoxStyle>
            <Styled.ListBoxTitle>
              <Styled.TableStyled
                id="scenarioTable"
                dataSource={dataSource}
                columns={columns}
                scroll={{y: 60 * 3}}
                pagination={false}
              />
            </Styled.ListBoxTitle>
          </Styled.ListBoxStyle>
        )}
        <Styled.ButtonBox>
          <Styled.ButtonStyled onClick={handleClick}>
            {t("account.menuItems.logout")}
          </Styled.ButtonStyled>
        </Styled.ButtonBox>
      </Styled.InsideBox>
    </Styled.Box>
  )
}

export default ScenarioList
