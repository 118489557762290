import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0;

  @media screen and (max-width: 700px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`

export const TimeBoxTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #495057;
`

export const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TimeBoxInside = styled.div`
  justify-content: space-between;
  margin-top: 10px;
`
export const InsideText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #495057;

  padding-right: 30px;
  min-width: 250px;
  width: 250px;
  margin: 5px;
  margin-bottom: 15px;
`

export const Container = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const ButtonGroup = styled.div`
  width: 300px;
  min-height: 52px;
  height: 52px;
  max-height: 52px;
`
export const DayButton = styled.button`
  padding: 8px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: 0.4s;
  padding: 0 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: #495057;

  min-height: 52px;
  height: 52px;
  max-height: 52px;
  ${({active}) =>
    active &&
    `
    color: #FFFFFF;
    background: #FABA15;
    border: 1px solid #FABA15;
  `}

  ${({first}) =>
    first &&
    `
    border-radius: 2px 0px 0px 2px;

  `}

  ${({last}) =>
    last &&
    `
    border-radius: 0px 2px 2px 0px;

  `}
`

export const Styled = {
  MainBox,
  TimeBoxTitle,
  TimeBox,
  TimeBoxInside,
  InsideText,
  Container,

  ButtonGroup,
  DayButton
}
