import styled from "styled-components"

const MainBox = styled.div`
  width: 91%;
  margin: auto;
  margin-top: 70px;
`

const PageHeadlineBox = styled.div`
  display: flex;
  height: 59px;
  flex-direction: row;
  justify-content: space-between;
`

const PageHeadlineBoxLeft = styled.div``

const PageHeadlineBoxRight = styled.div``

const MainContentSpinnerWrapper = styled.div`
  display: flex;
  height: 100vh;
  max-height: calc(100vh - 325px);
  justify-content: center;
  align-items: center;
`

export const Styled = {
  MainBox,
  PageHeadlineBox,
  PageHeadlineBoxLeft,
  PageHeadlineBoxRight,
  MainContentSpinnerWrapper
}
