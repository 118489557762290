export const questionnairesData = [
  {
    description: null,
    name: "Fragen A",
    questionnaire_type_id: 1,
    subtitle: null
  },
  {
    description:
      "Dieser Fragebogen soll uns dabei helfen herauszufinden, wie anfällig Sie für die Bewegungskrankheit (Motion Sickness) sind. Es geht des Weiteren darum, welche Arten von Bewegung am ehesten diese Beschwerden hervorrufen. (Bewegungs-)Krankheit in diesem Falle bedeutet sich unwohl fühlen, Übelkeit verspüren oder sich übergeben.",
    name: "Fragen B",
    questionnaire_type_id: 1,
    subtitle: "Vorabfragebogen"
  },
  {
    description: null,
    name: "Fragen C",
    questionnaire_type_id: 1,
    subtitle: "Vorabfragebogen"
  },
  {
    description: null,
    name: "Fragen D",
    questionnaire_type_id: 1,
    subtitle: "Datenerhebung"
  },
  {
    description: null,
    name: "Fragen E",
    questionnaire_type_id: 1,
    subtitle: "Übelkeitsskala"
  },
  {
    description: null,
    name: "Fragen F",
    questionnaire_type_id: 1,
    subtitle: "Datenerhebung"
  },
  {
    description: null,
    name: "Fragen G",
    questionnaire_type_id: 1,
    subtitle: null
  },
  {
    description: null,
    name: "(Bevor der Fahrt) Wetterverhältnisse festlegen",
    questionnaire_type_id: 2,
    subtitle: null
  },
  {
    description: null,
    name: "(Nach der Fahrt) Wetterverhältnisse festlegen",
    questionnaire_type_id: 2,
    subtitle: null
  }
]

export const questionsData = [
  {
    belongs_to: "[Fragen A]",
    description: "Gut;Eher gut;Mittelmässig;Eher schlecht;Schlecht",
    question: "Wie fühlen Sie sich heute allgemein?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen A]",
    description: null,
    question: "Um wieviel Uhr haben Sie das letzte Mal gegessen?",
    question_type_id: 2,
    question_image_file_id: null,
    title: "Letzte Mahlzeit"
  },
  {
    belongs_to: "[Fragen A]",
    description: "Heute;Gestern;Vorgestern",
    question: "An welchem Tag haben Sie das letzte Mal gegessen?",
    question_type_id: 3,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen A]",
    description: "Heiß;Warm;Leicht warm;Neutral;Leicht kühl;Kühl;Kalt",
    question:
      "Bitte geben Sie auf der folgenden Skala an wie Sie sich jetzt fühlen",
    question_type_id: 4,
    question_image_file_id: null,
    title:
      "Thermischer Komfort; Mit thermischem Komfort ist Ihr Wohlbefinden in Abhängigkeit der Temperatur gemeint"
  },
  {
    belongs_to: "[Fragen A]",
    description:
      "Viel wärmer;Wärmer;Leicht wärmer;Keine Veränderung;Leicht kühler;Kühler;Viel kühler",
    question: "Bitte geben Sie an wie Sie sich jetzt gerne fühlen würden",
    question_type_id: 4,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Weniger als ein paarmal im Jahr/Nie;Ein paarmal im Jahr;Ein paarmal im Monat; Einige Male pro Woche;(Fast) jeden Tag",
    question: "1. Wie oft fahren Sie selbst Auto?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Weniger als ein paarmal im Jahr/Nie;Ein paarmal im Jahr;Ein paarmal im Monat; Einige Male pro Woche;(Fast) jeden Tag",
    question:
      "2. Wie oft fahren Sie in einem Auto als Passagier auf dem Vordersitz?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Weniger als ein paarmal im Jahr/Nie;Ein paarmal im Jahr;Ein paarmal im Monat; Einige Male pro Woche;(Fast) jeden Tag",
    question:
      "3. Wie oft fahren Sie in einem Auto als Passagier auf dem Rücksitz mit?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description: "Nie;Selten;Manchmal;Oft;Immer",
    question:
      "4. Als Passagier in einem Auto, wie oft lesen Sie ein Buch/ eine Zeitung oder ein Magazin?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description: "Nie;Selten;Manchmal;Oft;Immer",
    question:
      "5. Als Passagier in einem Auto, wie oft nutzen Sie ein Smart Device (Tablet, Smartphone)?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description: "Nie;Selten;Manchmal;Oft;Immer",
    question:
      "6. Als Passagier in einem Auto, wie oft arbeiten Sie an einem Laptop?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description: "Nie;Selten;Manchmal;Oft;Immer",
    question:
      "7. Als Passagier in einem Auto, wie oft arbeiten Sie an einem Tablet oder Smartphone?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Weniger als ein paarmal im Jahr/Nie;Ein paarmal im Jahr;Ein paarmal im Monat; Einige Male pro Woche;(Fast) jeden Tag/ immer",
    question:
      "8. Wie häufig nutzen Sie ein Virtual Reality Headset (zB. Oculus Rift, HTC Vive, etc.)?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Nie;Selten;Manchmal;Oft;Immer;Nicht zutreffend - nicht verwendet",
    question:
      "9. Haben Sie in den letzten fünf Jahren unangenehme Symptome bei der Verwendung eines VR-Headsets erlebt?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Milde (Leichte) Symptome;Mittelmäßige Symptome;Schwere Symptome; Sehr schwere Symptome (einschließlich Erbrechen);Nicht zutreffend - nicht verwendet",
    question:
      "10. Wie stark waren die Symptome die Sie in den letzten fünf Jahren bei der Verwendung eines VR-Headsets erlebt haben?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Nie;Selten;Manchmal;Oft;Immer;Nicht zutreffend - nicht verwendet",
    question:
      "11. Hatten Sie in den letzten fünf Jahren Symptome von Motion Sickness unter folgenden Bedingungen? Als Fahrer in einen Auto",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Milde (Leichte) Symptome;Mittelmäßige Symptome;Schwere Symptome; Sehr schwere Symptome (einschließlich Erbrechen);Nicht zutreffend - nicht verwendet",
    question:
      "12. Wie stark waren die schwerwiegendsten Symptome von Motion Sickness als Fahrer in einem Auto, die Sie in den letzten fünf Jahren erlebt haben?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Nie;Selten;Manchmal;Oft;Immer;Nicht zutreffend - nicht verwendet",
    question:
      "13. Hatten Sie in den letzten fünf Jahren Symptome von Motion Sickness unter folgenden Bedingungen? Als Passagier in einem Auto auf dem Vordersitz",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Milde (Leichte) Symptome;Mittelmäßige Symptome;Schwere Symptome; Sehr schwere Symptome (einschließlich Erbrechen);Nicht zutreffend - nicht verwendet",
    question:
      "14. Wie stark waren die schwerwiegendsten Symptome von Motion Sickness als Passagier in einem Auto auf dem Vordersitz, die Sie in den letzten fünf Jahren erlebt haben?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Nie;Selten;Manchmal;Oft;Immer;Nicht zutreffend - nicht verwendet",
    question:
      "15. Hatten Sie in den letzten fünf Jahren Symptome von Motion Sickness unter folgenden Bedingungen? Als Passagier in einem Auto auf dem Rücksitz",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Milde (Leichte) Symptome;Mittelmäßige Symptome;Schwere Symptome; Sehr schwere Symptome (einschließlich Erbrechen);Nicht zutreffend - nicht verwendet",
    question:
      "16. Wie stark waren die schwerwiegendsten Symptome von Motion Sickness als Passagier in einem Auto auf dem Rücksitz, die Sie in den letzten fünf Jahren erlebt haben?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Ich erlebe nur dann Motion Sickness, wenn ich für einige Zeit nicht nach draußen schaue;Selbst wenn ich die ganze Zeit aus dem Fenster schaue, kann es vorkommen, dass ich Motion Sickness bekomme",
    question:
      "17. Sie haben angegeben, dass Sie als Passagier in einem Auto bereits Motion Sickness erlebt haben, welche der folgenden zwei Aussagen beschreibt Sie am besten?",
    question_type_id: 4,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen B]",
    description:
      "Überhaupt nicht möglich;Weniger als 10 Minuten;10-30 Minuten;30-60 Minuten;Mehr als ein Stunde oder unbegrenzt",
    question:
      "18. Wie lange können Sie als Passagier im Auto ein Buch/Zeitung/Magazin lesen oder ein Smartphone/Tablet nutzen, bis Sie sich unwohl fühlen?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Geruch eines Neuwagens",
    question_type_id: 1,
    question_image_file_id: null,
    title:
      "19. Haben Sie während der Fahrt in einem Auto unter folgenden Umständen/ Situationen Symptome von Motion Sickness?"
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Zigarretengeruch",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Abgasgeruch",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Kalter Luftzug",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Warmer Luftzug",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Stockender Verkehr",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Konstant hohe Geschwindigkeit",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Kurvenreiche Straßen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Viele Abbiegungen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Häufiges Bremsen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Bergauf und bergab",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Unebene Straße",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Bei keiner bestimmten Aktivität",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Nach einem Nickerchen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Lesen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Handschiriftlich schreiben",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Smartphone-Nutzung",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Verwendung eines Laptops",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Abspielen eines Videos in Höhe der Rückenlehne",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 19.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Blick auf die vorausliegende Straße",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 20.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Wach mit geschlossenen Augen",
    question_type_id: 1,
    question_image_file_id: null,
    title:
      "20. Haben Sie während der Fahrt in einem Auto unter folgenden Umständen/ Situationen Symptome von Motion Sickeness?"
  },
  {
    belongs_to: "[Fragen C 20.]",
    description:
      "Nein, nie;Ja, selten;Ja, manchmal;Ja, oft; Ja, fast immer;Ich weiß es nicht",
    question: "Schlafen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C]",
    description: null,
    question:
      "21. Wie lange leiden Sie normalerweise im Durchschnitt nach dem Verlassen des Fahrzeuges an Motion Sickness?",
    question_type_id: 5,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C]",
    description:
      "Nein, nie; Ja, selten; Ja, manchmal; Ja, oft; Ja, (fast) immer; Ich weiß es nicht",
    question:
      "22. Haben Sie jemals Motion Sickness nach dem Verlassen des Fahrzeugs erlebt, obwohl es Ihnen während der Fahrt gut ging?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Autos",
    question_type_id: 1,
    question_image_file_id: null,
    title:
      "23. Ihre Erfahrungen aus der Kindheit (nur vor einem Alter von 12 Jahren): Bitte geben Sie für jede Mobilitätsart oder Freizeitaktivität an: Als Kind (vor einem Alter von 12 Jahren): wie oft wurde Ihnen übel oder haben Sie erbrochen (bitte auswählen): "
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Busse",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Züge",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Flugzeuge",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Kleine Boote",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Schiffe (z.B. Fähren)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Schaukeln",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Karussels auf dem Spielplatz",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 23.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Achterbahnen,Fahrgeschäfte auf Kirmes, in Freizeitparks",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Autos",
    question_type_id: 1,
    question_image_file_id: null,
    title:
      "24. Ihre Erfahrungen in den letzten 10 Jahren (ungefähr): Bitte geben Sie für jede Mobilitätsart oder Freizeitaktivität an: In den letzten 10 Jahren: wie oft wurde Ihnen übel oder haben Sie erbrochen (bitte auswählen): "
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Busse",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Züge",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Flugzeuge",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Kleine Boote",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Schiffe (z.B. Fähren)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Schaukeln",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Karussels auf dem Spielplatz",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen C 24.]",
    description:
      "Trifft nicht zu / Niemals, gereist/genutzt;Niemals;Selten;Manchmal;Häufig",
    question: "Achterbahnen,Fahrgeschäfte auf Kirmes, in Freizeitparks",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Ein Generelles Unwohlsein",
    question_type_id: 1,
    question_image_file_id: null,
    title:
      "Ich würde gerne wissen, ob Sie gerade _____ empfinden? Bitte beantworten Sie die Frage wie folgt: Keine Angabe, gar nicht, ein wenig, moderat oder sehr."
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Müdigkeit",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Kopfschmerzen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Eine Augenreizung bzw. ermüdete Augen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question:
      "Schwer sich zu fokussieren (d.h. mit den Augen z.B. eine Stelle zu fokussieren)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Vermehrter Speichelfluss",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Schwitzen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Übelkeit",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question:
      "Schwer sich zu konzentrieren (z.B. sich auf die gestellten Fragen zu konzentrieren)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Das Gefühl, der Kopf wäre voll",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Benommenheit bei offenen Augen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Verschwommene Sicht",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Benommenheit bei geschlossenen Augen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Schwindel (Orientierungsverlust)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question:
      "Sensibles Bauchgefühl (Unwohlsein oder leichte Übelkeit, jedoch nicht ausgelöst von Hungergefühl)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen D]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Aufstoßen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen E]",
    description: "0;1;2;3;4;5;6;7;8;9;10",
    question: "Wie übel ist ihnen gerade?",
    question_type_id: 8,
    question_image_file_id: 1,
    title:
      "Bitte sagen Sie mir, wie wohl oder unwohl Sie sich gerade vor der Fahrt fühlen auf einer Skala von 0 bis 10. Hierzu verwenden Sie bitte die gerade besprochene Skala"
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Ein Generelles Unwohlsein",
    question_type_id: 1,
    question_image_file_id: null,
    title:
      "Ich würde gerne wissen, ob Sie gerade _____ empfinden? Bitte beantworten Sie die Frage wie folgt: Keine Angabe, gar nicht, ein wenig, moderat oder sehr."
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Müdigkeit",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Kopfschmerzen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Eine Augenreizung bzw. ermüdete Augen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question:
      "Schwer sich zu fokussieren (d.h. mit den Augen z.B. eine Stelle zu fokussieren)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Vermehrter Speichelfluss",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Schwitzen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Übelkeit",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question:
      "Schwer sich zu konzentrieren (z.B. sich auf die gestellten Fragen zu konzentrieren)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Das Gefühl, der Kopf wäre voll",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Benommenheit bei offenen Augen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Verschwommene Sicht",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Benommenheit bei geschlossenen Augen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Schwindel (Orientierungsverlust)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question:
      "Sensibles Bauchgefühl (Unwohlsein oder leichte Übelkeit, jedoch nicht ausgelöst von Hungergefühl)",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen F]",
    description: "Gar nicht;Ein wenig;Moderat;Sehr;Keine Angabe",
    question: "Aufstoßen",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen G]",
    description: "Gut;Eher Gut;Mittelmässig;Eher Schlecht;Schlecht",
    question: "Wie fühlen Sie sich heute allgemein?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen G]",
    description: "Heiß;Warm;Leicht warm;Neutral;Leicht kühl;Kühl;Kalt",
    question:
      "Bitte geben Sie auf der folgenden Skala an wie Sie sich jetzt fühlen",
    question_type_id: 4,
    question_image_file_id: null,
    title:
      "Thermischer Komfort; Mit thermischem Komfort ist Ihr Wohlbefinden in Abhängigkeit der Temperatur gemeint"
  },
  {
    belongs_to: "[Fragen G]",
    description:
      "Viel wärmer;Wärmer;Leicht wärmer;Keine Veränderung;Leicht kühler;Kühler;Viel kühler",
    question: "Bitte geben Sie an wie sich jetzt gerne fühlen würden",
    question_type_id: 4,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen A]",
    description: null,
    question:
      "(Vor der Fahrt) Bitte geben Sie die jetzige Temperatur in Grad Celsius: ",
    question_type_id: 6,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen A]",
    description: null,
    question:
      "(Vor der Fahrt) Bitte geben Sie die jetzige Wetterverhältnisse an: ",
    question_type_id: 7,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen G]",
    description: null,
    question:
      "(Nach der Fahrt) Bitte geben Sie die jetzige Temperatur in Grad Celsius: ",
    question_type_id: 6,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen G]",
    description: null,
    question:
      "(Nach der Fahrt) Bitte geben Sie die jetzige Wetterverhältnisse an: ",
    question_type_id: 7,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen G]",
    description: null,
    question:
      "Bitte geben Sie besondere Ereignisse, die während der Fahrt passiert sind (z.B. Leichte Übelkeit, Fahrt abgebrochen, usw.): ",
    question_type_id: 9,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen A]",
    description: "1 (Schlecht);2;3;4;5;6;7;8;9;10 (Gut)",
    question:
      "(Vor der Fahrt) Wie bewerten Sie im allgemeinen den aktuellen thermischen Komfort?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  },
  {
    belongs_to: "[Fragen G]",
    description: "1 (Schlecht);2;3;4;5;6;7;8;9;10 (Gut)",
    question:
      "(Nach der Fahrt) Wie bewerten Sie im allgemeinen den aktuellen thermischen Komfort?",
    question_type_id: 1,
    question_image_file_id: null,
    title: null
  }
]

export const test_scenarios_stagesData = (test_scenario_id) => [
  {
    stage_id: 1,
    stage_sequence_index: 0,
    test_scenario_id
  },
  {
    stage_id: 2,
    stage_sequence_index: 1,
    test_scenario_id
  },
  {
    stage_id: 3,
    stage_sequence_index: 2,
    test_scenario_id
  }
]

export const test_scenarios_questionnairesData = (test_scenario_id) => [
  {
    questionnaire_id: 8,
    questionnaire_sequence_index: 1,
    test_scenario_id,
    test_scenario_stage_id: 1
  },
  {
    questionnaire_id: 1,
    questionnaire_sequence_index: 2,
    test_scenario_id,
    test_scenario_stage_id: 1
  },
  {
    questionnaire_id: 2,
    questionnaire_sequence_index: 3,
    test_scenario_id,
    test_scenario_stage_id: 1
  },
  {
    questionnaire_id: 3,
    questionnaire_sequence_index: 4,
    test_scenario_id,
    test_scenario_stage_id: 1
  },
  {
    questionnaire_id: 4,
    questionnaire_sequence_index: 5,
    test_scenario_id,
    test_scenario_stage_id: 1
  },
  {
    questionnaire_id: 5,
    questionnaire_sequence_index: 6,
    test_scenario_id,
    test_scenario_stage_id: 1
  },
  {
    questionnaire_id: 6,
    questionnaire_sequence_index: 1,
    test_scenario_id,
    test_scenario_stage_id: 3
  },
  {
    questionnaire_id: 7,
    questionnaire_sequence_index: 2,
    test_scenario_id,
    test_scenario_stage_id: 3
  },
  {
    questionnaire_id: 9,
    questionnaire_sequence_index: 3,
    test_scenario_id,
    test_scenario_stage_id: 3
  }
]

export const test_scenarios_questionnaires = (
  test_scenario_id,
  diff_questionnaire,
  resStagesId
) => [
  {
    questionnaire_id: 8 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId,
    questionnaire_sequence_index: 1
  },
  {
    questionnaire_id: 1 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId,
    questionnaire_sequence_index: 2
  },
  {
    questionnaire_id: 2 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId,
    questionnaire_sequence_index: 3
  },
  {
    questionnaire_id: 3 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId,
    questionnaire_sequence_index: 4
  },
  {
    questionnaire_id: 4 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId,
    questionnaire_sequence_index: 5
  },
  {
    questionnaire_id: 5 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId,
    questionnaire_sequence_index: 6
  },
  {
    questionnaire_id: 6 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId + 2,
    questionnaire_sequence_index: 1
  },
  {
    questionnaire_id: 7 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId + 2,
    questionnaire_sequence_index: 2
  },
  {
    questionnaire_id: 9 + diff_questionnaire,
    test_scenario_id,
    test_scenario_stage_id: resStagesId + 2,
    questionnaire_sequence_index: 3
  }
]

export const questionnaires_questions = (diff_question, diff_questionnaire) => [
  {
    question_id: 1 + diff_question,
    questionnaire_id: 1 + diff_questionnaire
  },
  {
    question_id: 2 + diff_question,
    questionnaire_id: 1 + diff_questionnaire
  },
  {
    question_id: 3 + diff_question,
    questionnaire_id: 1 + diff_questionnaire
  },
  {
    question_id: 4 + diff_question,
    questionnaire_id: 1 + diff_questionnaire
  },
  {
    question_id: 5 + diff_question,
    questionnaire_id: 1 + diff_questionnaire
  },
  {
    question_id: 107 + diff_question,
    questionnaire_id: 1 + diff_questionnaire
  },
  {
    question_id: 6 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 7 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 8 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 9 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 10 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 11 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 12 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 13 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 14 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 15 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 16 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 17 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 18 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 19 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 20 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 21 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 22 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 23 + diff_question,
    questionnaire_id: 2 + diff_questionnaire
  },
  {
    question_id: 24 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 25 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 26 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 27 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 28 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 29 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 30 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 31 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 32 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 33 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 34 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 35 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 36 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 37 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 38 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 39 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 40 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 41 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 42 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 43 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 44 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 45 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 46 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 47 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 48 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 49 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 50 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 51 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 52 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 53 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 54 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 55 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 56 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 57 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 58 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 59 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 60 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 61 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 62 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 63 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 64 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 65 + diff_question,
    questionnaire_id: 3 + diff_questionnaire
  },
  {
    question_id: 66 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 67 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 68 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 69 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 70 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 71 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 72 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 73 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 74 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 75 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 76 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 77 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 78 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 79 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 80 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 81 + diff_question,
    questionnaire_id: 4 + diff_questionnaire
  },
  {
    question_id: 82 + diff_question,
    questionnaire_id: 5 + diff_questionnaire
  },
  {
    question_id: 83 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 84 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 85 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 86 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 87 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 88 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 89 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 90 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 91 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 92 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 93 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 94 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 95 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 96 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 97 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 98 + diff_question,
    questionnaire_id: 6 + diff_questionnaire
  },
  {
    question_id: 99 + diff_question,
    questionnaire_id: 7 + diff_questionnaire
  },
  {
    question_id: 100 + diff_question,
    questionnaire_id: 7 + diff_questionnaire
  },
  {
    question_id: 101 + diff_question,
    questionnaire_id: 7 + diff_questionnaire
  },
  {
    question_id: 108 + diff_question,
    questionnaire_id: 7 + diff_questionnaire
  },
  {
    question_id: 102 + diff_question,
    questionnaire_id: 8 + diff_questionnaire
  },
  {
    question_id: 103 + diff_question,
    questionnaire_id: 8 + diff_questionnaire
  },
  {
    question_id: 104 + diff_question,
    questionnaire_id: 9 + diff_questionnaire
  },
  {
    question_id: 105 + diff_question,
    questionnaire_id: 9 + diff_questionnaire
  },
  {
    question_id: 106 + diff_question,
    questionnaire_id: 9 + diff_questionnaire
  }
]
