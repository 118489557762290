import styled from "styled-components"

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonStyle = styled.span`
  ${({$backgroundcolor, $color}) =>
    `
    background: ${$backgroundcolor};
    color: ${$color};
    border: none;
    cursor:pointer;
  `}
`

export const Styled = {
  ButtonBox,
  ButtonStyle
}
