import styled from "styled-components"
import {Button, Input} from "antd"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"

const {TextArea} = Input

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  width: 100%;
  width: 92%;
  margin-bottom: 100px;
`

export const InsideBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0px;
  margin: 60px;

  @media screen and (max-width: 650px) {
    margin: 20px;
  }
`

export const TitleBox = styled.div`
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #495057;
  margin-bottom: 30px;
  margin-left: 5px;

  @media screen and (max-width: 650px) {
    margin-bottom: 10px;
  }
`

export const AchtungBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  height: 73px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fde1e1;
  border: 1px solid #fcd2d2;
  border-radius: 4px;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  color: #924040;
`

export const VertAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 5px;
`

export const Bold = styled.div`
  display: flex;
  font-weight: 700;
  flex-direction: column;
  justify-content: center;
`

export const TempDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
`

export const TemperatureBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`

export const TemperatureBoxAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

export const TemperatureInsideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 403px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  background: #f2f2f2;
  border-radius: 8px;
  padding: 80px;

  @media (min-width: 650px) {
    width: 585px;
  }
`

export const TempInsideBox = styled.div`
  display: flex;
  height: 50px;
`

export const TempTurleyBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const TurleyBoxImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 50px;
`

export const RoadImg = styled.img`
  display: flex;
  height: 16px;
`

export const TurleyBoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 50px;
  margin-left: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 0px;
  text-align: center;
  letter-spacing: 0.22px;
  color: #495057;
`

export const TempCountBox = styled.div`
  position: relative;
  margin-top: 30px;
`

export const TempMeteorologicalBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 50px;
  margin-top: 52px;
`

export const ArrowUp = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 52px;
  width: 82px;
  background: #faba15;
  border-radius: 2px;
  border: 1px solid #ced4da;
  color: #faba15;
  z-index: 2;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: opacity 0.4s;
  &:hover {
    opacity: 0.8;
  }
`

export const ArrowDown = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 52px;
  width: 82px;
  background: #faba15;
  border-radius: 2px;
  border: 1px solid #ced4da;
  color: #faba15;
  z-index: 2;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: opacity 0.4s;
  &:hover {
    opacity: 0.8;
  }
`

export const TempInputBox = styled.div`
  display: flex;
  height: 50px;
  margin-top: 19px;
`

export const TempInputCount = styled.input`
  box-sizing: border-box;
  height: 104px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  text-align: center;
  font-size: 90px;
  z-index: 1;

  font-style: normal;
  font-weight: 700;
  font-size: 99px;
  line-height: 0px;
  color: #495057;

  @media screen and (max-width: 650px) {
    padding-right: 80px;
    font-size: 80px;
  }

  ${({$isShow}) =>
    $isShow &&
    `
    border : 1px solid red;

    `}
`

export const MeteoInput = styled.input`
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;

  ${({$isShow}) =>
    $isShow &&
    `
    border : 1px solid red;

    `}
`

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const ButtonFill = styled(Button)`
  display: flex;
  height: 35px;
  width: 62px;
  background: #faba15;
  border: 1px solid #faba15;
  border-radius: 4px;
  margin-left: 15px;
  color: #ffffff;
`

export const ButtonNoFill = styled(Button)`
  display: flex;
  height: 35px;
  width: 82px;
  border-radius: 4px;
  background: #ffffff;
  border: 2px solid #faba15;
  color: #faba15;
`

export const KeyboardArrowDownIconLogo = styled(KeyboardArrowDownIcon)`
  position: absolute;
  right: 35%;
  bottom: 30%;
  color: #ffffff;
  cursor: pointer;
`

export const KeyboardArrowUpIconLogo = styled(KeyboardArrowUpIcon)`
  position: absolute;
  right: 35%;
  bottom: 30%;
  color: #ffffff;
  cursor: pointer;
`

export const TextAreaStyle = styled(TextArea)`
  white-space: pre-line;
`

export const QuestionAfterDrive = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #495057;

  margin-top: 50px;
  margin-bottom: 10px;
`

export const Styled = {
  MainBox,
  InsideBox,
  TitleBox,
  AchtungBox,
  VertAlign,
  Bold,
  TempDiv,
  TemperatureBox,
  TemperatureBoxAlign,
  TemperatureInsideBox,

  TempInsideBox,
  TempTurleyBox,
  TurleyBoxImg,
  RoadImg,
  TurleyBoxText,
  TempCountBox,
  TempMeteorologicalBox,
  TempInputBox,
  TempInputCount,
  ArrowUp,
  ArrowDown,
  KeyboardArrowDownIconLogo,
  KeyboardArrowUpIconLogo,

  MeteoInput,

  ButtonBox,
  ButtonFill,
  ButtonNoFill,

  TextAreaStyle,

  QuestionAfterDrive
}
