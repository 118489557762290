import React, {useContext, useEffect, useState} from "react"
import {LoadingOutlined} from "@ant-design/icons"
import {Spin} from "antd"
import {useQuery, gql} from "@apollo/client"
import {Outlet, useNavigate, useParams} from "react-router-dom"
import Tabs from "../../components/tabs"
import {DataContext, UserContext} from "../../context/context"
import ProgressPanel from "../../components/progress-panel"
import ScenarioList from "../../components/scenario-list"

import {getObj} from "../../middleware/mathObj"
import ModalScenarioList from "../../components/modal-scenario-list"
import {userObj} from "../../const/contexts"
import {MainContentSpinnerWrapper} from "../main/main.styled"
import {isAuthDataWithoutParameter} from "../../middleware/auth"
import {GET_SCENARIOS_DATA} from "../../const/const"

function UserFlow({pending}) {
  const {state, handlerSet} = useContext(DataContext)

  const {data, refetch} = useQuery(GET_SCENARIOS_DATA, {
    variables: {scenarioId: state.scenarioActive},
    skip: !state.scenarioActive
  })

  const [scenarioData, setScenarioData] = useState()
  const [newData, setNewData] = useState()
  const {tabId, stageId} = useParams()
  const navigate = useNavigate()

  const handleRefetch = async () => {
    const fetchedData = await refetch({
      scenarioId: state.scenarioActive,
      historyScenarioId: state.historyId,
      testSubjectId: state.user_id
    })

    setNewData(fetchedData.data)
    window.scrollTo({behavior: "smooth", top: 0})

    return fetchedData
  }
  // get data after choose scenario, use user_id, scenario_id, history_id
  useEffect(() => {
    if (!state.scenarioActive) return // add history

    handleRefetch().catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) handleRefetch()
          else navigate("/login")
        })
      }
    })
  }, [state.scenarioActive, tabId, stageId])

  useEffect(() => {
    setScenarioData(getObj(newData))
  }, [newData])

  const [stateUserFlow, setDataUserFlow] = useState(userObj)

  const handlerSetUser = (newState) => {
    setDataUserFlow((statePrev) => ({...statePrev, ...newState}))
  }

  return (
    <>
      <UserContext.Provider value={{stateUserFlow, handlerSetUser}}>
        <ModalScenarioList
          text=""
          user={state.user_first_name}
          description={<ScenarioList />}
          modalName="ScenarioList"
        />
        <Tabs tabs={data} handleRefetch={handleRefetch} />
        <ProgressPanel />
        {pending ? (
          <MainContentSpinnerWrapper>
            <Spin
              style={{
                scale: "4"
              }}
              indicator={<LoadingOutlined spin />}
            />
          </MainContentSpinnerWrapper>
        ) : (
          <Outlet context={scenarioData} />
        )}
      </UserContext.Provider>
    </>
  )
}

export default UserFlow
