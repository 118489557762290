import styled from "styled-components"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"

export const Box = styled.div`
  display: flex;
  width: 100%;
  margin: 50px 0;
`
export const ContainerBox = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 50%;
  margin-right: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #495057;
  padding-left: 5px;
`

export const Title = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #495057;
  margin-left: 5px;
`

export const InputBox = styled.div`
  display: flex;
  max-height: 32px;
`

export const Text = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #495057;
  margin-left: 8px;
`

export const ArrowUp = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 16px;
  width: 20px;
  background: #faba15;
  border-radius: 2px;
  border: 1px solid #ced4da;
  color: #faba15;
  z-index: 2;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: opacity 0.4s;
  &:hover {
    opacity: 0.8;
  }
`

export const ArrowDown = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 16px;
  width: 20px;
  background: #faba15;
  border-radius: 2px;
  border: 1px solid #ced4da;
  color: #faba15;
  z-index: 2;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  transition: opacity 0.4s;
  &:hover {
    opacity: 0.8;
  }
`

export const TempInputCount = styled.input`
  box-sizing: border-box;
  height: 32px;
  width: 90px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  text-align: left;
  padding: 10px;
  z-index: 1;

  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #000;

  ${({$isShow}) =>
    $isShow &&
    `
    border : 1px solid red;

    `}
  ${({$isShow}) =>
    !$isShow &&
    `
    border: 1px solid #ced4da;

    `}

  &:focus {
    background: #ffffff;
    border: 1px solid #faba15 !important;
    box-shadow: 0px 0px 0px 2px rgba(250, 186, 21, 0.2);
    border-radius: 2px;
    outline: none;
  }
`
export const TempCountBox = styled.div`
  position: relative;
`
export const KeyboardArrowDownIconLogo = styled(KeyboardArrowDownIcon)`
  position: absolute;
  right: -15%;
  bottom: -40%;
  color: #ffffff;
  cursor: pointer;
`

export const KeyboardArrowUpIconLogo = styled(KeyboardArrowUpIcon)`
  position: absolute;
  right: -15%;
  bottom: -40%;
  color: #ffffff;
  cursor: pointer;
`

export const Styled = {
  Box,
  Title,
  InputBox,
  Text,

  ArrowUp,
  ArrowDown,
  TempInputCount,
  TempCountBox,
  KeyboardArrowDownIconLogo,
  KeyboardArrowUpIconLogo,
  ContainerBox
}
