import {useMutation} from "@apollo/client"
import {message} from "antd"
import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useDebounce} from "react-use"
import {INSERT_ANSWER, regex, UPDATE_ANSWER} from "../../../const/const"
import {DataContext, UserContext} from "../../../context/context"
import {isAuthDataWithoutParameter} from "../../../middleware/auth"
import Line from "../line"
import {
  ContainerMainTitle,
  ContainerMainTitleRelative,
  ContainerNumber,
  ContainerNumberTitle
} from "../rhombus/rhombus.styled"
import {Styled} from "./minutes-input.styled"

const MinutesInput = ({
  title,
  question,
  isFirst,
  isLast,
  answer: answerParametr,
  questionId
}) => {
  const [answer, setAnswer] = useState(answerParametr)
  const [inputValue, setInputValue] = useState(
    answer.length !== 0 ? answer[0].answer : 0
  )

  const [updateMutation, {loading}] = useMutation(UPDATE_ANSWER)

  const [insertMutation] = useMutation(INSERT_ANSWER)

  const {state, handlerSet} = useContext(DataContext)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)

  const navigate = useNavigate()

  const {tabId, stageId} = useParams()

  const [isShow, setIsShow] = useState(false)

  const handleSetIsshow = (value) => {
    setIsShow(value)
  }

  async function updateData(value) {
    if (answer.length !== 0) {
      await updateMutation({
        variables: {
          answerId: answer[0].id,
          answer: String(value)
        }
      })
    } else {
      const insertFieldId = await insertMutation({
        variables: {
          answer: String(value),
          question_id: questionId,
          test_scenario_history_id: state.historyId
        }
      })

      setAnswer([
        {
          id: insertFieldId.data.insert_questions_answers_one.id,
          answer: value
        }
      ])

      // if not work useEffect - clear red border
      handleSetIsshow(
        !insertFieldId.data.insert_questions_answers_one.id &&
          stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
      )
    }
  }

  const handleSetCount = (value) => {
    const number = Number(value)
    let updateNumber = 0
    if (!Number.isNaN(number)) {
      if (number >= 0 && number <= 59) {
        updateNumber = number
      }
      if (number < 0) {
        updateNumber = 0
      }
      if (number > 59) {
        updateNumber = 59
      }
    } else {
      updateNumber = 0
    }

    setInputValue(updateNumber)

    updateData(updateNumber).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(updateNumber).catch((_) => console.error(_))
          else navigate("/login")
        })
      }
    })
  }

  const handleClick = (value) => {
    if (loading) return
    handleSetCount(value)
  }

  // set value 0 to default
  useDebounce(() => {
    ;(async () => {
      if (answer.length === 0) {
        const insertFieldId = await insertMutation({
          variables: {
            answer: String(0),
            question_id: questionId,
            test_scenario_history_id: state.historyId
          }
        }).catch((err) => message.error(err))

        setAnswer([
          {
            id: insertFieldId.data.insert_questions_answers_one.id,
            answer: "0"
          }
        ])

        // if not work useEffect - clear red border
        handleSetIsshow(
          !insertFieldId.data.insert_questions_answers_one.id &&
            stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
        )
      }
    })()
  }, 1000)

  // set appear achtung message
  useEffect(() => {
    // if (true) - red border
    // if (not setted and this page is in array)
    handleSetIsshow(
      answer.length === 0 &&
        inputValue === 0 &&
        stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
    )
  }, [inputValue])

  const findDecimal = question !== null ? question.match(regex) : null

  const findDecimalTitle = title !== null ? title.match(regex) : null

  return (
    <>
      {!isFirst && title !== null && <Line />}
      {title !== null && (
        <ContainerMainTitle>
          <ContainerMainTitleRelative>
            {title.match(regex) !== null && (
              <ContainerNumberTitle
                $isBigSpace={
                  findDecimalTitle !== null
                    ? Number(findDecimalTitle[0].split(".")[0]) >= 10
                    : 0
                }
              >
                {title.match(regex)}
              </ContainerNumberTitle>
            )}
          </ContainerMainTitleRelative>
          {findDecimalTitle !== null
            ? title.split(findDecimalTitle[0])[1]
            : title}
        </ContainerMainTitle>
      )}
      <Styled.Box>
        <Styled.ContainerBox>
          {question.match(regex) !== null && (
            <ContainerNumber
              $isBigSpace={
                findDecimal !== null
                  ? Number(findDecimal[0].split(".")[0]) >= 10
                  : 0
              }
            >
              {question.match(regex)}
            </ContainerNumber>
          )}
          {findDecimal !== null ? question.split(findDecimal[0])[1] : question}
        </Styled.ContainerBox>
        <Styled.InputBox>
          <Styled.TempCountBox>
            <Styled.TempInputCount
              value={inputValue}
              onChange={(e) => handleClick(e.target.value)}
              $isShow={isShow}
            />
            <Styled.ArrowUp onClick={() => handleClick(inputValue + 1)}>
              <Styled.KeyboardArrowUpIconLogo />
            </Styled.ArrowUp>
            <Styled.ArrowDown onClick={() => handleClick(inputValue - 1)}>
              <Styled.KeyboardArrowDownIconLogo />
            </Styled.ArrowDown>
          </Styled.TempCountBox>
          <Styled.Text>Minuten</Styled.Text>
        </Styled.InputBox>
      </Styled.Box>
      {!isFirst && isLast && title !== null && <Line />}
    </>
  )
}
export default MinutesInput
