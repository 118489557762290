/* eslint-disable semi */
import {Row, Button, Tag, Input, Form, Radio, Select, Table} from "antd"
import styled from "styled-components"

export const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #f8f8fb;
`

export const LeftSideFormContainer = styled.div`
  min-width: 350px;
  width: 350px;
  padding: 20px;
  margin: 40px;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
`

export const RightSideUserFormContainer = styled.div`
  width: 600px;
  height: 800px;
  background: #fff;
  padding: 1%;
`

export const LeftSideUserFormContainer = styled.div`
  width: 600px;
  height: 800px;
  background: #fff;
  padding: 1%;
`

export const UserRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`

export const CreateUserButton = styled(Button)`
  color: white;
  background: #f1b44c;
  border-radius: 4px;
  border-color: #f1b44c;
`

export const ScenarioTag = styled(Tag)`
  background-color: #fff;
  border-style: dashed;
`

export const StyledInput = styled(Input)`
  width: 100%;
`

export const StyledDropDown = styled(Select)`
  display: flex;
  justify-content: flex-end;
  width: 80%;
  background-color: crimson;
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selector {
    background-color: crimson;
    width: 80% !important;
  }
`

export const StyledTitle = styled.p`
  font-weight: bold;
`

export const StyledForm = styled(Form)`
  border-radius: 4px;
  padding: 20px;
  padding-right: 50px;
  margin: 40px;
  background-color: #fff;
  box-shadow: 1px 1px 7px -1px rgba(0, 0, 0, 0.77);
  display: flex;
  justify-content: space-between;
`

export const StyledRadioButton = styled(Radio.Button)`
  color: black;
  border-radius: 4px;
  background-color: white;
`

export const StyledButtonDontClick = styled(Button)`
  background-color: white;
  color: #f1b44c;
  border-radius: 4px;
  border: 2px solid;
  margin-right: 20px;
  border-color: #f1b44c;
  :active :not ::selection {
    color: black;
  }
`

export const LastRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  padding: 100px;
`

export const GenderRadio = styled(Radio)`
  margin-top: 46px;
`

export const PaginationRow = styled(Row)`
  margin-top: -90px;
  display: flex;
  justify-content: flex-end;
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background-color: #d9d9d9 !important;
  }
`
export const PageDisplay = styled.div`
  padding: 0px 10px;
  gap: 10px;
  font-size: large;
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`

export const PaginationButton = styled(Button)`
  margin-left: 3px;
  margin-right: 3px;
  padding: 1px 7px;
  gap: 10px;
  background-color: #ffffff;
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
`

export const TableStyledUserList = styled(Table)`
  && tbody > tr:hover > td {
    background: #ddd;
  }
  thead > tr > th {
    height: 54px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: rgba(0, 0, 0, 0.85);

    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 0px;
  }

  tbody {
    tr {
      td {
        height: 54px;

        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #495057;

        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 0px;
        cursor: pointer;
        text-overflow: ellipsis;
      }
    }
  }
`
