import {gql} from "@apollo/client"

export const temperatureSubTab = 3

export const PICOVOICE_ACCESS_KEY = process.env.REACT_APP_PICOVOICE_ACCESS_KEY

/* eslint-disable prefer-destructuring */
export const GRAPHQL_SERVER_URL = process.env.REACT_APP_SWUI_GRAPHQL_URL

/* eslint-disable prefer-destructuring */
export const SWUI_BE_ADMIN_PW = process.env.REACT_APP_SWUI_BE_ADMIN_PW

/* eslint-disable prefer-destructuring */
export const SWUI_BE_API_URL = process.env.REACT_APP_SWUI_BE_API_URL

let websocketAddress = "ws://localhost:5013/api/ws_trigger"
if (/https/.test(SWUI_BE_API_URL)) {
  websocketAddress = `${SWUI_BE_API_URL.replace("https", "wss")}/api/ws_trigger`
} else {
  websocketAddress = `${SWUI_BE_API_URL.replace("http", "ws")}/api/ws_trigger`
}

export const SWUI_BE_API_WS_TRIGGER_URL = websocketAddress

export const EVA_API_URL = process.env.REACT_APP_EVA_API_URL

export const EVA_ADMIN_USERNAME = process.env.REACT_APP_EVA_ADMIN_USERNAME

export const EVA_ADMIN_PASSWORD = process.env.REACT_APP_EVA_ADMIN_PASSWORD

export const EVA_WORKFLOW_ID = process.env.REACT_APP_EVA_WORKFLOW_ID

export const WAKEWORD_URL = process.env.REACT_APP_WAKEWORD_URL

export const WAKEWORD_TOKEN = process.env.REACT_APP_WAKEWORD_TOKEN

export const GOOGLE_SPEECH_RECOGNITION_API_KEY =
  process.env.REACT_APP_GOOGLE_SPEECH_RECOGNITION_API_KEY

export const regex = /^\d*\./

export const UPDATE_ANSWER = gql`
  mutation UpdateAnswer($answerId: Int, $answer: String) {
    update_questions_answers(
      where: {id: {_eq: $answerId}}
      _set: {answer: $answer, updated_at: "now()"}
    ) {
      returning {
        id
        answer
      }
    }
  }
`

export const INSERT_ANSWER = gql`
  mutation InsertQuestion(
    $answer: String
    $question_id: Int
    $test_scenario_history_id: Int
  ) {
    insert_questions_answers_one(
      object: {
        answer: $answer
        created_at: "now()"
        question_id: $question_id
        test_scenario_history_id: $test_scenario_history_id
      }
    ) {
      id
    }
  }
`

export const GET_TEST_SCENARIOS_HISTORY_TIME_DRIVE_START_END = gql`
  query GetTestScenariosHistoryTimeDriveStartEnd($id: Int) {
    test_scenarios_history(where: {id: {_eq: $id}}) {
      id
      test_drive_start_time
      test_drive_end_time
    }
  }
`

export const GET_TEST_SCENARIOS_HISTORY_DIALOGS = gql`
  query GetTestScenariosHistoryDialogs($testScenarioHistoryId: Int) {
    test_scenarios_history_dialogs(
      where: {history_id: {_eq: $testScenarioHistoryId}}
      order_by: {created_at: asc}
    ) {
      id
      test_scenarios_history {
        id
        start_time
        end_time
        test_drive_start_time
        test_drive_end_time
        test_subject {
          user_name
          first_name
          last_name
        }
      }
      created_at
      misc_number
      misc_timestamp
      comment
      dialog_json
      workflow_id
      workflow_execution_id
    }
  }
`

export const GET_SCENARIOS_LIST_ADMIN = gql`
  query GetScenariosListAdmin {
    test_scenarios {
      id
      name
      description
      test_group_id
    }
    test_groups {
      id
      description
      name
    }
  }
`

export const UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS = gql`
  mutation InsertTestScenarioHistoryDialog(
    $testScenarioHistoryId: Int
    $workflowId: String
    $workflowExecutionId: String
  ) {
    insert_test_scenarios_history_dialogs(
      objects: {
        history_id: $testScenarioHistoryId
        workflow_id: $workflowId
        workflow_execution_id: $workflowExecutionId
      }
    ) {
      returning {
        id
      }
    }
  }
`

export const INSERT_SCENARIOS = gql`
  mutation InsertScenario(
    $name: String
    $test_group_id: Int
    $description: String
  ) {
    insert_test_scenarios(
      objects: {
        name: $name
        description: $description
        test_group_id: $test_group_id
        test_track_id: 1
        vehicle_id: 1
      }
    ) {
      returning {
        id
      }
    }
  }
`
export const INSERT_TEST_SCENARIOS_STAGES = gql`
  mutation Insert_test_scenarios_stages(
    $objects: [test_scenarios_stages_insert_input!]!
  ) {
    insert_test_scenarios_stages(objects: $objects) {
      affected_rows
      returning {
        id
        stage_id
        test_scenario_id
        stage_sequence_index
      }
    }
  }
`
export const INSERT_QUESTIONS = gql`
  mutation Insert_questions($objects: [questions_insert_input!]!) {
    insert_questions(objects: $objects) {
      returning {
        question
        id
        question_image_file_id
        question_type_id
        title
        description
      }
      affected_rows
    }
  }
`
export const INSERT_QUESTIONNAIRES = gql`
  mutation Insert_questionnaires($objects: [questionnaires_insert_input!]!) {
    insert_questionnaires(objects: $objects) {
      affected_rows
      returning {
        description
        id
        name
        questionnaire_type_id
        subtitle
      }
    }
  }
`
export const INSERT_TEST_SCENARIOS_QUESTIONNAIRES = gql`
  mutation Insert_test_scenarios_questionnaires(
    $objects: [test_scenarios_questionnaires_insert_input!]!
  ) {
    insert_test_scenarios_questionnaires(objects: $objects) {
      affected_rows
      returning {
        id
        questionnaire_id
        questionnaire_sequence_index
        test_scenario_id
        test_scenario_stage_id
      }
    }
  }
`
export const INSERT_QUESTIONNAIRES_QUESTIONS = gql`
  mutation Insert_questionnaires_questions(
    $objects: [questionnaires_questions_insert_input!]!
  ) {
    insert_questionnaires_questions(objects: $objects) {
      returning {
        question_id
        questionnaire_id
        id
      }
      affected_rows
    }
  }
`

export const UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_MESSAGES = gql`
  mutation Update_Test_Scenarios_History_Dialogs_Messages(
    $workflowExecutionId: String
    $workflowId: String
    $dialogJson: String
  ) {
    update_test_scenarios_history_dialogs(
      where: {
        workflow_id: {_eq: $workflowId}
        workflow_execution_id: {_eq: $workflowExecutionId}
      }
      _set: {dialog_json: $dialogJson}
    ) {
      returning {
        id
        dialog_json
      }
    }
  }
`
export const UPDATE_TEST_SCENARIOS_HISTORY_TEST_DRIVE_START = gql`
  mutation UpdateTestScenariosHistoryTestDriveStart($id: Int) {
    update_test_scenarios_history(
      where: {id: {_eq: $id}}
      _set: {test_drive_start_time: "now()"}
    ) {
      returning {
        id
        test_drive_start_time
      }
    }
  }
`

export const UPDATE_TEST_SCENARIOS_HISTORY_TEST_DRIVE_END = gql`
  mutation UpdateTestScenariosHistoryTestDriveEnd($id: Int) {
    update_test_scenarios_history(
      where: {id: {_eq: $id}}
      _set: {test_drive_end_time: "now()"}
    ) {
      returning {
        id
        test_drive_end_time
      }
    }
  }
`

export const UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_COMMENTS = gql`
  mutation Update_Test_Scenarios_History_Dialogs_Comment(
    $testScenarioHistoryDialogId: Int
    $miscNumber: Int
    $miscTimestamp: timestamptz
    $miscComment: String
  ) {
    update_test_scenarios_history_dialogs(
      where: {id: {_eq: $testScenarioHistoryDialogId}}
      _set: {
        comment: $miscComment
        misc_number: $miscNumber
        misc_timestamp: $miscTimestamp
      }
    ) {
      returning {
        id
        misc_number
        misc_timestamp
        comment
      }
    }
  }
`

export const UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_MISC = gql`
  mutation Update_Test_Scenarios_History_Dialogs_Misc(
    $workflowExecutionId: String
    $workflowId: String
    $miscNumber: Int
    $miscTimestamp: timestamptz
  ) {
    update_test_scenarios_history_dialogs(
      where: {
        workflow_id: {_eq: $workflowId}
        workflow_execution_id: {_eq: $workflowExecutionId}
      }
      _set: {misc_number: $miscNumber, misc_timestamp: $miscTimestamp}
    ) {
      returning {
        id
        misc_number
        misc_timestamp
      }
    }
  }
`

export const UPDATE_SCENARIOS = gql`
  mutation Update_Scenario(
    $scenarioId: Int
    $name: String
    $test_group_id: Int
    $test_track_id: Int
    $vehicle_id: Int
    $description: String
  ) {
    update_test_scenarios(
      where: {id: {_eq: $scenarioId}}
      _set: {
        name: $name
        test_group_id: $test_group_id
        test_track_id: $test_track_id
        vehicle_id: $vehicle_id
        description: $description
      }
    ) {
      returning {
        id
        name
        test_group_id
        description
        test_track_id
        vehicle_id
      }
    }
  }
`
export const SELECT_TEST_SCENARIOS_QUESTIONNAIRES = gql`
  query Test_scenarios_questionnaires($scenarioId: Int) {
    test_scenarios_questionnaires(
      where: {test_scenario_id: {_eq: $scenarioId}}
    ) {
      questionnaire_id
    }
  }
`
export const SELECT_QUESTIONNAIRES_QUESTIONS = gql`
  query Select_questionnaires_questions($questionnairesArrId: [Int!]) {
    questionnaires_questions(
      where: {questionnaire_id: {_in: $questionnairesArrId}}
    ) {
      question_id
    }
  }
`
export const DELETE_SCENARIOS = gql`
  mutation DeleteScenario(
    $scenarioId: Int
    $questionnairesArrId: [Int!]!
    $questionsIdArray: [Int!]!
  ) {
    delete_test_scenarios_questionnaires(
      where: {test_scenario_id: {_eq: $scenarioId}}
    ) {
      affected_rows
    }
    delete_test_scenarios_stages(
      where: {test_scenario_id: {_eq: $scenarioId}}
    ) {
      affected_rows
    }

    delete_questions_answers(where: {question_id: {_in: $questionsIdArray}}) {
      affected_rows
    }

    delete_test_scenarios_history(
      where: {test_scenario_id: {_eq: $scenarioId}}
    ) {
      affected_rows
    }

    delete_test_scenarios(where: {id: {_eq: $scenarioId}}) {
      returning {
        id
      }
    }

    delete_questionnaires_questions(
      where: {question_id: {_in: $questionsIdArray}}
    ) {
      affected_rows
    }

    delete_questions(where: {id: {_in: $questionsIdArray}}) {
      affected_rows
    }

    delete_questionnaires(where: {id: {_in: $questionnairesArrId}}) {
      affected_rows
    }
  }
`

export const UPDATE_HISTORY_TIME_END = gql`
  mutation UpdateHistoryTimeEnd($historyId: Int, $end_time: timestamptz) {
    update_test_scenarios_history(
      where: {id: {_eq: $historyId}}
      _set: {end_time: "now()"}
    ) {
      returning {
        id
        start_time
        end_time
      }
    }
  }
`

export const GET_SCENARIOS_DATA = gql`
  query GetScenariosData($scenarioId: Int, $historyScenarioId: Int) {
    stages(
      where: {test_scenarios_stages: {test_scenario: {id: {_eq: $scenarioId}}}}
    ) {
      id
      name
      test_scenarios_stages(where: {test_scenario: {id: {_eq: $scenarioId}}}) {
        test_scenarios_questionnaires(
          where: {test_scenario: {id: {_eq: $scenarioId}}}
        ) {
          questionaire {
            description
            subtitle
            name
            id
            questionnaires_type {
              id
              name
            }
            questionnaires_questions {
              question {
                id
                description
                question
                question_image_file_id
                title
                questions_type {
                  id
                  name
                  description
                }
                test_subjects_questions_answers(
                  limit: 1
                  where: {test_scenario_history_id: {_eq: $historyScenarioId}}
                ) {
                  id
                  answer
                  answer_file_id
                }
              }
            }
          }
        }
        test_scenario {
          test_track {
            id
            name
          }
          mp3File: file {
            id
            name
            filePath: file_path
            fileContents: file_contents
          }
        }
      }
    }
  }
`

export const GET_VEHICLES_LIST = gql`
  query GetVehiclesList {
    vehicles {
      id
      manufacturer_id
      max_speed
      model_name
      motor_type
      name
      vin
      plate
      notes
      vehicles_driving_licenses {
        driving_licence {
          name
          id
        }
        id
      }
      vehicle_manufacturer {
        name
        id
        location
      }
    }
    vehicle_manufacturers {
      name
      id
    }
    driving_licenses {
      class
      id
      name
    }
  }
`

export const INSERT_VEHICLES_MANUFACTURERS = gql`
  mutation InsertVehicleManufacturers($manufacturersName: String = "test") {
    insert_vehicle_manufacturers(objects: {name: $manufacturersName}) {
      returning {
        id
        name
      }
    }
  }
`

export const INSERT_VEHICLES = gql`
  mutation InsertVehicle(
    $max_speed: Int
    $model_name: String
    $motor_type: String
    $name: String
    $notes: String
    $plate: String
    $vin: String
    $manufacturer_id: Int = 10
  ) {
    insert_vehicles(
      objects: {
        max_speed: $max_speed
        model_name: $model_name
        motor_type: $motor_type
        name: $name
        notes: $notes
        plate: $plate
        vin: $vin
        manufacturer_id: $manufacturer_id
      }
    ) {
      affected_rows
      returning {
        max_speed
        model_name
        motor_type
        name
        notes
        plate
        vin
        id
        manufacturer_id
      }
    }
  }
`

export const INSERT_VEHICLES_DRIVING_LICENSES = gql`
  mutation InsertVehiclesDrivingLicenses(
    $objects: [vehicles_driving_licenses_insert_input!]! # = [ #   {license_id: 1, vehicle_id: 1} #   {license_id: 2, vehicle_id: 1} # ]
  ) {
    insert_vehicles_driving_licenses(objects: $objects) {
      affected_rows
      returning {
        id
        license_id
        vehicle_id
      }
    }
  }
`

export const DELETE_AND_INSERT_VEHICLES_DRIVING_LICENSES = gql`
  mutation InsertVehiclesDrivingLicenses(
    $id: Int
    $objects: [vehicles_driving_licenses_insert_input!]!
  ) {
    delete_vehicles_driving_licenses(where: {vehicle_id: {_eq: $id}}) {
      returning {
        id
        license_id
        vehicle_id
      }
    }

    insert_vehicles_driving_licenses(objects: $objects) {
      affected_rows
      returning {
        id
        license_id
        vehicle_id
      }
    }
  }
`

export const UPDATE_VEHICLES = gql`
  mutation Update_Vehicle(
    $manufacturer_id: Int
    $max_speed: Int
    $motor_type: String
    $model_name: String
    $name: String
    $notes: String
    $plate: String
    $vin: String
    $id: Int
  ) {
    update_vehicles(
      where: {id: {_eq: $id}}
      _set: {
        manufacturer_id: $manufacturer_id
        max_speed: $max_speed
        motor_type: $motor_type
        model_name: $model_name
        name: $name
        notes: $notes
        plate: $plate
        vin: $vin
      }
    ) {
      returning {
        id
        manufacturer_id
        max_speed
        model_name
        motor_type
        name
        notes
        plate
        vin
      }
    }
  }
`

export const DELETE_VEHICLES = gql`
  mutation DeleteVehicle($vehicleId: Int) {
    delete_vehicles(where: {id: {_eq: $vehicleId}}) {
      returning {
        id
        name
      }
    }
  }
`

export const DELETE_VEHICLES_AND_DRIVING_LICENSES = gql`
  mutation Delete_vehicles_driving_licenses($vehicleId: Int) {
    delete_vehicles_driving_licenses(where: {vehicle_id: {_eq: $vehicleId}}) {
      returning {
        id
        license_id
        vehicle_id
      }
    }
    delete_vehicles(where: {id: {_eq: $vehicleId}}) {
      returning {
        id
        name
      }
    }
  }
`

export const DELETE_VEHICLES_MANUFACTURERS = gql`
  mutation delete_vehicle_manufacturers($manufacturer_id: Int = 10) {
    delete_vehicle_manufacturers(where: {id: {_eq: $manufacturer_id}}) {
      returning {
        id
        name
      }
    }
  }
`

export const GET_TEST_GROUPS = gql`
  query get_test_groups {
    test_groups {
      id
      description
      name
    }
  }
`

export const GET_TEST_GROUPS_USERS = gql`
  query Get_Test_Group_Users($test_group_id: Int) {
    users(
      where: {test_groups_members: {test_group_id: {_eq: $test_group_id}}}
    ) {
      test_groups_members(where: {test_group_id: {_eq: $test_group_id}}) {
        id
      }
      id
      first_name
      last_name
      address
      date_of_birth
      is_researcher
      is_research_administrator
      user_name
    }
    test_subjects(
      where: {test_groups_members: {test_group_id: {_eq: $test_group_id}}}
    ) {
      test_groups_members(where: {test_group_id: {_eq: $test_group_id}}) {
        id
      }
      last_name
      id
      first_name
      date_of_birth
      address
      user_name
    }
    drivers(
      where: {test_groups_members: {test_group_id: {_eq: $test_group_id}}}
    ) {
      test_groups_members(where: {test_group_id: {_eq: $test_group_id}}) {
        id
      }
      user_name
      last_name
      id
      first_name
      date_of_birth
      address
      training_status
    }
  }
`

export const GET_TEST_GROUPS_USERS_AND_GROUPS = gql`
  query Get_Test_Group_Users_And_Groups($test_groupsId: Int) {
    test_groups(where: {id: {_eq: $test_groupsId}}) {
      id
      name
      description
    }
    driversAll: drivers {
      first_name
      last_name
      id
      user_name
    }
    usersAll: users {
      last_name
      id
      first_name
      user_name
    }
    test_subjectsAll: test_subjects {
      first_name
      last_name
      user_name
      id
    }

    users(
      where: {test_groups_members: {test_group_id: {_eq: $test_groupsId}}}
    ) {
      id
      first_name
      last_name
      user_name
    }
    test_subjects(
      where: {test_groups_members: {test_group_id: {_eq: $test_groupsId}}}
    ) {
      id
      first_name
      last_name
      user_name
    }
    drivers(
      where: {test_groups_members: {test_group_id: {_eq: $test_groupsId}}}
    ) {
      id
      first_name
      last_name
      user_name
    }
  }
`

export const INSERT_NEW_GROUP = gql`
  mutation InsertNewGroup($description: String, $name: String) {
    insert_test_groups(objects: {name: $name, description: $description}) {
      returning {
        id
        name
      }
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation UpdateGroup(
    $test_groupId: Int
    $name: String
    $description: String
    $objects: [test_groups_members_insert_input!] = {}
  ) {
    update_test_groups(
      where: {id: {_eq: $test_groupId}}
      _set: {name: $name, description: $description}
    ) {
      returning {
        id
        description
        name
      }
    }
    insert_test_groups_members(objects: $objects) {
      affected_rows
    }
  }
`

export const DELETE_GROUP = gql`
  mutation Delete_Group($test_groupId: Int) {
    delete_test_groups_members(where: {test_group_id: {_eq: $test_groupId}}) {
      affected_rows
    }
    delete_test_groups(where: {id: {_eq: $test_groupId}}) {
      affected_rows
    }
  }
`

export const DELETE_TEST_GROUP_MEMBERS = gql`
  mutation Delete_Test_Groups_Members($test_groupId: Int) {
    delete_test_groups_members(where: {id: {_eq: $test_groupId}}) {
      affected_rows
      returning {
        id
        user_id
        driver_id
        test_subject_id
      }
    }
  }
`
