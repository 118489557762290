import {Modal, Table, InputNumber} from "antd"
import styled from "styled-components"
import {ReactComponent as TimeSvg} from "../../images/time.svg"
import {ReactComponent as ButtonArrowRight} from "../../images/buttonArrowRight.svg"

export const MainBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  width: 100%;
  max-height: 90vh;
`

export const HeaderMainStretch = styled.div`
  width: 100%;
  background: rgba(250, 186, 21, 0.2);
  border-radius: 8px 8px 0px 0px;
`

export const HeaderMain = styled.div`
  margin: 0px auto;
  width: 83%;
  @media (max-width: 600px) {
    width: 90%;
  }
`
export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  height: 144px;
  max-height: 144px;
`

export const HeaderTextBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  width: 60%;
`

export const TextBoxTitle = styled.div`
  display: flex;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.6px;
  line-height: 25px;
  /* identical to box height */

  color: #495057;
`
export const TextBoxDescrition = styled.div`
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #495057;
`

export const HeaderImgBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40%;
  overflow: clip;
`

export const HeaderImg = styled.img`
  width: 244px;
  height: 144px;
`

export const BodyBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;

  // hide scrollbar
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  max-height: 90vh;
`

export const FooterBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 76px;
  background: rgba(250, 186, 21, 0.2);
  border-radius: 0px 0px 8px 8px;
`

export const FooterBoxInside = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ButtonGroup = styled.div`
  display: flex;
  padding: 20px 0;
`

export const ButtonMargin = styled.div`
  display: flex;
  margin: 0 55px 0 13px;
`

export const TableBox = styled.div`
  margin: 46px auto 32px auto;
  width: 83%;
  @media (max-width: 600px) {
    width: 90%;
  }
`

export const TableStyled = styled(Table)`
  border: none;
  border-right-style: hidden;

  thead > tr > th {
    height: 27px;
    padding-top: 0;
    padding-bottom: 0;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;

    @media (max-width: 600px) {
      font-size: 12px;
    }

    color: #495057;

    background: #ffffff;
    /* Conditional/divider */

    border: none;
    border-top: 1px solid #eff2f7;
    border-left: 0px;
    border-right: 0px;

    // remove border between header
    ::before {
      width: 0px;
    }
  }

  // remove border between header
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
  }

  tbody {
    tr {
      td {
        height: 7px;
        padding-top: 0;
        padding-bottom: 0;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        /* identical to box height */

        @media (max-width: 600px) {
          font-size: 12px;
        }

        color: #495057;

        border: none;
        border-top: 1px solid #eff2f7;
        border-radius: 0px;
      }
      td:first-child {
        font-weight: 600;
      }
    }
  }
`
export const TableBoxDescribe = styled.div`
  margin: 0px auto 29px auto;
  width: 83%;
  @media (max-width: 600px) {
    width: 90%;
  }
`
export const TableBoxDescribeTitle = styled.div`
  display: flex;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #495057;
`

export const TableBoxDescribeText = styled.div`
  display: flex;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #495057;
  margin-top: 10px;
`

export const InputStyle = styled(InputNumber)`
  background: #ffffff;
  /* Neutral/5 */

  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`
export const SusiImgDescBoxStretch = styled.div`
  background: rgba(250, 186, 21, 0.2);
  border-radius: 8px 8px 0px 0px;
`

export const SusiImgDescCenter = styled.div`
  margin: 0px auto;
  width: 83%;
  @media (max-width: 600px) {
    width: 90%;
  }
`

export const SusiImgDescBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const SusiDescBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
`

export const SusiDescBoxTittle = styled.div`
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.6px;
  line-height: 25px;
  /* identical to box height */

  color: #495057;
`

export const SusiDescBoxText = styled.div`
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #495057;
  margin-top: 9px;
`
export const SusiDescBoxTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const SusiDescBoxTimeGroup = styled.div`
  display: flex;
	margin-right: auto;
`

export const SusiDescBoxTimeSvg = styled(TimeSvg)`
	margin-top: 14px;
`

export const SusiDescBoxTimeText = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #495057;
  margin-left: 7px;
  margin-top: 10px;
  margin-right: 20px;
`

export const SusiImgBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40%;
`

export const SusiImg = styled.img`
  width: 100%;
`

export const SusiListStretch = styled.div`
  width: 100%;
`

export const SusiListCenter = styled.div`
  margin: 0px auto;
  width: 83%;
  @media (max-width: 600px) {
    width: 90%;
  }
`

export const SusiListBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
`

export const SusiListDiv = styled.div`
  min-height: 200px;
  max-height: 360px;
  overflow-y: scroll;
  margin: 30px 0;
  padding-right: 24px;

  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    width: 16px !important;
    background: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0.5px 0px 0px rgba(0, 0, 0, 0.07),
      inset 1px 0px 0px rgba(0, 0, 0, 0.02),
      inset -1px 0px 0px rgba(0, 0, 0, 0.02),
      inset -0.5px 0px 0px rgba(0, 0, 0, 0.07);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.51);
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.51);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  }
`

export const SusiListItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  border-radius: 8px 8px 0px 8px;

  ${({$isSusi}) =>
    $isSusi &&
    `
    justify-content: flex-start;
    `}

  ${({$isSusi}) =>
    !$isSusi &&
    `
    justify-content: flex-end;
    `}
`

export const Item = styled.div`
  ${({$isSusi}) =>
    $isSusi &&
    `
    background: rgba(85, 110, 230, 0.101961);
    border-radius: 8px 8px 8px 0px;
    `}

  ${({$isSusi}) =>
    !$isSusi &&
    `
    background: rgba(250, 186, 21, 0.101961);
    border-radius: 8px 8px 0px 8px;
    `}
    padding: 12px 24px;
  margin-bottom: 10px;
`

export const ItemsName = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  ${({$isSusi}) =>
    $isSusi &&
    `
    color: #556ee6;
    text-align: left;
    justify-content: flex-start;
  
  `}

  ${({$isSusi}) =>
    !$isSusi &&
    `
    color: #FABA15;
    text-align: left;
    justify-content: flex-end;
  
  `}
`

export const ItemsMessage = styled.div`
  display: flex;
  flex-direction: row;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #495057;

  ${({$isSusi}) =>
    $isSusi &&
    `
  justify-content: flex-start;

`}

  ${({$isSusi}) =>
    !$isSusi &&
    `
  justify-content: flex-end;

`}
`

export const CommentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
`

export const CommentTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #495057;
  margin-top: 29px;
`

export const CommentGroupInputButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin: 21px 0 35px 0;
`

export const CommentInputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 22px;
  width: 100%;
`
export const CommentInput = styled.textarea`
  background: #eff2f7;
  border-radius: 10px;
  border: 0px;
  height: 105px;
  border-radius: 10px;
  width: 100%;
  padding: 8px 14px;
  resize: none;
`

export const CommentButton = styled.button`
  background: #556ee6;
  border-radius: 50px;
  border: 0px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #ffffff;

  height: 36px;
  width: 110px;
  cursor: pointer;
`
export const ButtonArrowRightSvg = styled(ButtonArrowRight)`
  margin-left: 19px;
`

export const ModalStyle = styled(Modal)`
  border-radius: 25px !important;
  -moz-border-radius: 25px;

  top: 5vh;

  margin-bottom: 0px;
  padding-bottom: 0px;

  .ant-modal-footer {
    border-top: 0px;
  }

  .ant-modal-content {
    border-radius: 10px;
    box-shadow: -1px 0px 12px 0px rgba(0, 0, 0, 0.75);
    max-height: 90vh;
  }
  .ant-modal-body {
    padding: 0px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .ant-modal-wrap {
  }
`

export const Styled = {
  HeaderMainStretch,
  HeaderMain,
  MainBox,
  HeaderBox,
  HeaderTextBox,
  TextBoxTitle,
  TextBoxDescrition,
  HeaderImgBox,
  HeaderImg,
  BodyBox,
  FooterBox,
  FooterBoxInside,
  ButtonGroup,
  ButtonMargin,
  TableBox,
  TableStyled,
  TableBoxDescribe,
  TableBoxDescribeTitle,
  TableBoxDescribeText,
  InputStyle,

  SusiImgDescBoxStretch,
  SusiImgDescCenter,
  SusiImgDescBox,
  SusiDescBox,
  SusiDescBoxTittle,
  SusiDescBoxText,
  SusiDescBoxTime,
  SusiDescBoxTimeGroup,
  SusiDescBoxTimeSvg,
  SusiDescBoxTimeText,
  SusiImgBox,
  SusiImg,

  SusiListStretch,
  SusiListCenter,
  SusiListBox,
  SusiListDiv,
  SusiListItems,
  Item,
  ItemsName,
  ItemsMessage,
  CommentBox,
  CommentTitle,
  CommentGroupInputButton,
  CommentInputBox,
  CommentInput,
  CommentButton,
  ButtonArrowRightSvg,

  ModalStyle
}
