import React from "react"
import {useTranslation} from "react-i18next"

import {Styled} from "./achtung.styled"

const Achtung = ({isShow, title, description}) => {
  const {t} = useTranslation()

  return (
    <Styled.AchtungBox isShow={isShow}>
      <Styled.VertAlign>
        <Styled.Bold>{isShow && t(title)}</Styled.Bold>
        <Styled.TempDiv>{isShow && t(description)}</Styled.TempDiv>
      </Styled.VertAlign>
    </Styled.AchtungBox>
  )
}
export default Achtung
