/* eslint-disable semi */
/* eslint-disable import/order */
import React from "react"
import {useTranslation} from "react-i18next"
import {Select, Form, Input, message} from "antd"

import {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  GreyText,
  ResetPasswordWrapper,
  PrimaryButton,
  ContactLogo,
  ContactEllipse,
  ContactWrapper,
  OutterRowWrapper,
  NotificationBox
} from "./StyledComponents"

import SusiPortraitImg from "../../images/susi_portrait.png"
import Ellipse from "../../images/Ellipse.png"
import Contact from "../../images/Contact.png"
import {GRAPHQL_SERVER_URL, SWUI_BE_API_URL} from "../../const/const"
import axios from "axios"

const ResetPassword = ({setIsVisible}) => {
  const {Option} = Select
  const {t} = useTranslation()
  const [formInstance] = Form.useForm()

  const onFinish = async (values) => {
    setIsVisible(false)
  }

  const onFinishFailed = (error) => {
    message.error(t("passwordReset.resetFailed"))
  }

  return (
    <OutterRowWrapper>
      <LeftSideFormContainer>
        <SusiPortraitWrapper justify="space-evenly" align="middle" wrap={false}>
          <div>
            <OrangeText>{t("passwordReset.headlineResetPassword")}</OrangeText>
            <GreyText>
              {t("passwordReset.subHeadlineCreateNewPassword")}
            </GreyText>
          </div>
          <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
        </SusiPortraitWrapper>
        <ContactWrapper>
          <ContactEllipse src={Ellipse} alt="contact background" />
          <ContactLogo src={Contact} alt="contact" />
        </ContactWrapper>
        <FormContainer>
          <NotificationBox>
            {t("passwordReset.notificationInstructionsViaEmail")}
          </NotificationBox>
          <Form
            onSubmit={onFinish}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{user_type: "research_director"}}
            form={formInstance}
            layout="vertical"
          >
            <Form.Item
              form={formInstance}
              name="user_name"
              label={t("generic.forms.userName.label")}
              rules={[
                {
                  required: true,
                  message: t("generic.forms.userName.errorNoValue")
                }
              ]}
            >
              <Input placeholder={t("generic.forms.userName.placeholder")} />
            </Form.Item>

            <Form.Item
              form={formInstance}
              name="newPassword"
              label={t("passwordReset.forms.newPassword.label")}
              type="password"
              rules={[
                {
                  required: true,
                  message: t("passwordReset.forms.newPassword.errorNoValue")
                }
              ]}
            >
              <Input.Password
                placeholder={t("passwordReset.forms.newPassword.placeholder")}
              />
            </Form.Item>

            <Form.Item
              form={formInstance}
              name="repeatPassword"
              label={t("passwordReset.forms.repeatNewPassword.label")}
              type="password"
              rules={[
                {
                  required: true,
                  message: t(
                    "passwordReset.forms.repeatNewPassword.errorNoValue"
                  )
                },
                ({getFieldValue}) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      t(
                        "passwordReset.forms.repeatNewPassword.errorMismatchingPasswords"
                      )
                    )
                  }
                })
              ]}
            >
              <Input.Password
                placeholder={t(
                  "passwordReset.forms.repeatNewPassword.placeholder"
                )}
              />
            </Form.Item>

            <ResetPasswordWrapper>
              <PrimaryButton
                type="submit"
                htmlType="submit"
                align="stretch"
                block
              >
                {t("passwordReset.resetButton")}
              </PrimaryButton>
            </ResetPasswordWrapper>
            {/* {isResearchDirector && (
    					<ForgotPassword>
              	<LockFilled />
              	<Button type="text">{t("login.forgotPasswordButton")}</Button>
            	</ForgotPassword>
            )} */}
          </Form>
          <ResetPasswordWrapper>
            <PrimaryButton
              align="stretch"
              block
              onClick={() => setIsVisible(false)}
            >
              {t("passwordReset.backButton")}
            </PrimaryButton>
          </ResetPasswordWrapper>
        </FormContainer>
      </LeftSideFormContainer>
    </OutterRowWrapper>
  )
}

export default ResetPassword
