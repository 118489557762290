import SusiDriveScreen from "../components/susi-drive-screen/susi-drive-screen.component"
import Temperature from "../components/temperature"
import Fragen from "../components/fragen/fragen"
import FragenList from "../components/fragen/fragen-list/fragen-list.component"
import QuestionnaireAnswersOverview from "../components/questionnaire-answers-overview"
import ModalBeforeDrive from "../components/modal-before-drive"

export const getFragenStep = (data, tab) => {
  if (tab === 2) return null

  if (data !== undefined) {
    const questionsData = data.stages.filter((item) => item.id === tab)

    return questionsData[0].test_scenarios_stages[0].test_scenarios_questionnaires.map(
      (item, index) => ({
        id: index,
        name: item.questionaire.name,
        questionnaires_type: item.questionaire.questionnaires_type.name,
        questionnaires_questions: item.questionaire.questionnaires_questions
      })
    )
  }

  return []
}

export const getTrack = (data, tab) => {
  if (tab === 2) return null

  let questionsData = []

  if (data !== undefined) {
    questionsData = data.stages.filter((item) => item.id === tab)
  }

  return questionsData[0].test_scenarios_stages[0].test_scenario
}

export const getQuestions = (data, tab) => {
  if (tab === 2) return null

  if (data !== undefined) {
    const questionsData = data.stages.filter((item) => item.id === tab)

    return questionsData[0].test_scenarios_stages[0]
      .test_scenarios_questionnaires
  }

  return []
}

export const getObj = (data) => {
  const dataObj = {}

  if (data !== undefined) {
    let tab = 1

    const questionBeforeDrive = getFragenStep(data, 1).filter(
      (title) => title.questionnaires_type !== "temperature_weather"
    )

    const questionAfterDrive = getFragenStep(data, 3).filter(
      (title) => title.questionnaires_type !== "temperature_weather"
    )

    const tempQuestionBeforeDrive = getFragenStep(data, 1).filter(
      (title) => title.questionnaires_type === "temperature_weather"
    )

    const tempQuestionAfterDrive = getFragenStep(data, 3).filter(
      (title) => title.questionnaires_type === "temperature_weather"
    )

    getFragenStep(data, tab).map((item, index) => {
      if (index === 0)
        dataObj[`${tab}${index}`] = (
          <Temperature
            dataTemp={tempQuestionBeforeDrive}
            before={questionBeforeDrive.length}
            after={questionAfterDrive.length}
            track={getTrack(data, tab).test_track}
          />
        )
      else
        dataObj[`${tab}${index}`] = (
          <Fragen
            FragenListTitle={questionBeforeDrive}
            FragenList={
              <FragenList
                dataQuestions={getQuestions(data, tab)}
                startCount={0}
                before={questionBeforeDrive.length}
              />
            }
            startCount={0}
            before={questionBeforeDrive.length}
            after={questionAfterDrive.length}
          />
        )
      return dataObj
    })

    dataObj[`20`] = <SusiDriveScreen />

    tab = 3
    getFragenStep(data, tab).map((item, index) => {
      dataObj[`${tab}${index}`] = (
        <Fragen
          FragenListTitle={questionAfterDrive}
          FragenList={
            <FragenList
              dataQuestions={getQuestions(data, tab)}
              startCount={getQuestions(data, 1).length}
            />
          }
          startCount={
            getFragenStep(data, 1).filter(
              (title) => title.name !== "Wetterverhältnisse festlegen"
            ).length
          }
          before={questionBeforeDrive.length}
          after={questionAfterDrive.length}
        />
      )
      return dataObj
    })

    dataObj[`${tab}${questionAfterDrive.length}`] = (
      <Temperature
        dataTemp={tempQuestionAfterDrive}
        before={questionBeforeDrive.length}
        after={questionAfterDrive.length}
        track={getTrack(data, tab).test_track}
      />
    )

    dataObj[`${tab}${questionAfterDrive.length + 1}`] = (
      <QuestionnaireAnswersOverview
        before={questionBeforeDrive}
        after={questionAfterDrive}
        allQuestions={[...getFragenStep(data, 1), ...getFragenStep(data, 3)]}
      />
    )
  }

  return dataObj
}

// order by desc
function compare(a, b) {
  if (a.time < b.time) {
    return 1
  }
  if (a.time > b.time) {
    return -1
  }
  return 0
}

export const getScenarioListData = (data) => {
  if (data !== undefined) {
    const inProgress = data.in_progress_scenarios.map((item, index) => {
      const m_updated_at = item.test_subjects_questions_answers_aggregate
        .aggregate.max.updated_at
        ? new Date(
            item.test_subjects_questions_answers_aggregate.aggregate.max.updated_at
          ).getTime()
        : null

      const m_created_at = item.test_subjects_questions_answers_aggregate
        .aggregate.max.created_at
        ? new Date(
            item.test_subjects_questions_answers_aggregate.aggregate.max.created_at
          ).getTime()
        : null

      return {
        ...item.test_scenario,
        key: index,
        status: "Im Gange",
        time: Math.max(
          m_updated_at,
          m_created_at,
          new Date(item.created_at).getTime()
        ),
        history: item.id,
        scenarioId: item.test_scenario.key
      }
    })

    return [
      ...inProgress.sort(compare),
      ...data.all_scenarios_for_subject.map((item, index) => ({
        ...item,
        key: data.in_progress_scenarios.length + index + 1,
        status: "Ein neues",
        history: undefined,
        scenarioId: item.key
      }))
    ]
  }

  return []
}

export const handlerModalBeforeDrive = (
  setIsVisibleFinish,
  isVisibleFinish,
  lastTabClick
) => (
  <ModalBeforeDrive
    closable={false}
    isVisibleFinish={isVisibleFinish}
    setIsVisibleFinish={setIsVisibleFinish}
    lastTabClick={lastTabClick}
  />
)
