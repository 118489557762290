import styled from "styled-components"
import {Button} from "antd"

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
`

export const ButtonFill = styled(Button)`
  display: flex;
  justify-content: center;
  background: #faba15;
  border: 1px solid #faba15;
  border-radius: 4px;
  margin-left: 15px;
  color: #ffffff;
`

export const ButtonNoFill = styled(Button)`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background: #ffffff;
  border: 2px solid #faba15;
  color: #faba15;
`

export const Styled = {
  ButtonBox,
  ButtonFill,
  ButtonNoFill
}
