import styled from "styled-components"
import {DatePicker, Spin} from "antd"

const {RangePicker} = DatePicker

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
`

export const InsideBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 92%;

  height: 167px;
  border-radius: 4px;

  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 49px 0 46px;
`

export const GroupTextPickerBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const TextBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 452px;
  padding-right: 63px;
`

export const DatePickerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 2px;
`

export const RangePickerStyle = styled(RangePicker)`
  height: 40px;
  min-height: 40px;
  width: 392px;
`
export const ExportButtonBoxRelative = styled.div`
  position: relative;
  z-index: 0;
`
export const ExportButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 35px;
  width: 130px;
  min-width: 130px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #ffffff;

  border-radius: 4px;

  background: #f1b44c;
  border: 1px solid #f1b44c;
  border-radius: 4px;

  ${({$isExporting}) =>
    $isExporting &&
    `
    background-color: #ffffff;
    border: 1px solid #ced4da;
    color: #ced4da;
    `}
  ${({$isExporting}) =>
    !$isExporting &&
    `
    cursor: pointer;
    `}
`

export const SpinStyle = styled(Spin)`
  position: absolute;
  top: 23%;
  left: 42%;

  z-index: 1;
`

export const Styled = {
  Box,
  InsideBox,
  ContentBox,
  GroupTextPickerBox,
  TextBox,
  DatePickerBox,
  RangePickerStyle,
  ExportButtonBoxRelative,
  ExportButtonBox,
  SpinStyle
}
