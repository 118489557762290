import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useMutation} from "@apollo/client"
import {Styled} from "./temperature.styled"
import roadImg from "../../images/road.png"
import Pagination from "../../common/pagination"
import Achtung from "../../common/achtung"
import {DataContext, UserContext} from "../../context/context"
import {INSERT_ANSWER, UPDATE_ANSWER} from "../../const/const"
import AchtungQuestion from "../../common/achtung-question/achtung.component"
import ModalComponent from "../modal/modal.component"
import {List, ListOL} from "../fragen/fragen/fragen.styled"
import {isAuthDataWithoutParameter} from "../../middleware/auth"

const getAnswer = (data, value) =>
  data.questionnaires_questions.filter(
    (itemAnswer) => itemAnswer.question?.questions_type.name === value
  )[0]?.question?.test_subjects_questions_answers?.[0] || null

const getAnswerByName = (data) => {
  const tempArr = getAnswer(data, "temperature-celsius")

  const weatherArr = getAnswer(data, "weather-text")

  const answerAfterDriveArr =
    data.questionnaires_questions.filter(
      (itemAnswer) =>
        itemAnswer.question?.questions_type.name === "post-ride-events"
    )[0]?.question?.test_subjects_questions_answers?.[0] || null

  return [tempArr, weatherArr, answerAfterDriveArr]
}

const Temperature = ({dataTemp, before, after, track}) => {
  if (dataTemp.length === 0) return <Styled.MainBox />

  const {tabId, stageId} = useParams()
  const {state, handlerSet} = useContext(DataContext)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)

  const [isShow, setIsShow] = useState(false)
  const [isShowTemp, setIsShowTemp] = useState(false)
  const [isFocus, setFocus] = useState(false)
  const navigate = useNavigate()

  const [updateMutation, {loading: isUpdating}] = useMutation(UPDATE_ANSWER)
  const [insertMutation, {loading: isInserting}] = useMutation(INSERT_ANSWER)

  const [answerArray, setAnswerArray] = useState(getAnswerByName(dataTemp[0]))

  const questionId = dataTemp[0].questionnaires_questions.map(
    (item) => item.question.id
  )

  const temp = Number(answerArray?.[0]?.answer) || 0
  const weather = answerArray?.[1]?.answer || ""

  const questionAfterDrive =
    tabId === "3"
      ? dataTemp[0].questionnaires_questions.filter(
          (itemAnswer) =>
            itemAnswer.question?.questions_type?.name === "post-ride-events"
        )[0]?.question?.question
      : null

  const [tempObj, setTempObj] = useState([
    temp,
    weather,
    answerArray?.[2]?.answer || ""
  ])

  // update state
  useEffect(() => {
    const answerArrays = getAnswerByName(dataTemp[0])

    const temps = Number(answerArrays?.[0]?.answer) || 0
    const weathers = answerArrays?.[1]?.answer || ""

    setTempObj([temps, weathers, answerArrays[2]?.answer || ""])

    setAnswerArray(answerArrays)
  }, [tabId])

  async function updateData(value, answerCount) {
    if (answerArray[answerCount]) {
      await updateMutation({
        variables: {
          answerId: answerArray[answerCount].id,
          answer: answerCount === 0 ? `${value}` : value
        }
      })
    } else {
      const insertFieldId = await insertMutation({
        variables: {
          answer: answerCount === 0 ? `${value}` : value,
          question_id: questionId[answerCount],
          test_scenario_history_id: state.historyId
        }
      })

      setAnswerArray((prev) => {
        const newPrev = prev
        newPrev[answerCount] = {
          id: insertFieldId.data.insert_questions_answers_one.id,
          answer: answerCount === 0 ? `${value}` : value
        }
        return newPrev
      })
    }
  }

  const handleSetCountOnBlur = (value) => {
    if (isUpdating || isInserting) return

    const number = Number(value)
    let updateNumber = 0
    if (!Number.isNaN(number)) {
      if (number >= -99 && number <= 99) {
        setTempObj((prev) => {
          const newPrev = prev
          newPrev[0] = number
          return [...newPrev]
        })
        updateNumber = String(number)
      }
    } else {
      setTempObj((prev) => {
        const newPrev = prev
        newPrev[0] = 0
        return [...newPrev]
      })
      updateNumber = "0"
    }

    updateData(updateNumber, 0).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(updateNumber, 0)
          else navigate("/login")
        })
      }
    })
  }

  const handleSetCount = (value) => {
    // check -100 and 100
    if (
      (value[0] === "-" && value.length < 4) ||
      (value[0] !== "-" && value.length < 3)
    )
      setTempObj((prev) => {
        const newPrev = prev
        newPrev[0] = value
        return [...newPrev]
      })
  }

  const handleSetObj = (value, countCurrent) => {
    if (countCurrent === 0) handleSetCount(value)
    else
      setTempObj((prev) => {
        const newPrev = prev
        newPrev[countCurrent] = value
        return [...newPrev]
      })
  }

  const handleSetWeather = (value) => {
    if (isUpdating || isInserting) return

    updateData(value, 1).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(value, 1)
          else navigate("/login")
        })
      }
    })
  }

  const handleSetAnswerAfterDrive = (value) => {
    if (isUpdating || isInserting) return

    updateData(value, 2).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(value, 2)
          else navigate("/login")
        })
      }
    })
  }

  const handleSetIsshow = (value) => {
    setIsShow(value)
  }

  const handleSetIsshowTemp = (value) => {
    setIsShowTemp(value)
  }

  // set appear red border
  useEffect(() => {
    handleSetIsshowTemp(
      answerArray[0] === null &&
        stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
    )
    handleSetIsshow(
      tempObj[1] === "" &&
        stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
    )
  }, [answerArray[0], tempObj[1]])

  const handleAfterClose = () => {
    handlerSetUser({newHistory: false})
  }

  return (
    <>
      <ModalComponent
        text="So funktioniert die App:"
        initState={stateUserFlow.newHistory}
        closable
        modalName="Temp"
        handleAfterClose={handleAfterClose}
        description={
          <List>
            <ListOL>
              <li>
                Vor und nach der Fahrt bitte die Fragebögen ausfüllen und mit
                Klicken auf “Nächste“ weitergehen.
              </li>
              <li>
                Während der Fahrt können Sie ihren Zustand melden, indem Sie
                ihren Satz mit “Hey Karli“ starten, z.B. “Hey Karli, mir geht es
                nicht gut!“. Danach folgen Sie die Sprachanweisungen.
              </li>
            </ListOL>
          </List>
        }
      />
      <Styled.MainBox>
        <Styled.InsideBox>
          <Styled.TitleBox>{dataTemp[0]?.name}</Styled.TitleBox>
          <Achtung
            isShow
            title="dancer.titleTemp"
            description="dancer.descTemp"
          />

          <AchtungQuestion
            isShow={isShow}
            title="Bitte ausfüllen! Falls nicht möglich, dem Versuchsleiter zeigen."
          />
          <Styled.TemperatureBox>
            <Styled.TemperatureBoxAlign>
              <Styled.TemperatureInsideBox>
                <Styled.TempTurleyBox>
                  <Styled.TurleyBoxImg>
                    <Styled.RoadImg src={roadImg} />
                  </Styled.TurleyBoxImg>
                  <Styled.TurleyBoxText>{track.name}</Styled.TurleyBoxText>
                </Styled.TempTurleyBox>
                <Styled.TempCountBox>
                  <Styled.TempInputCount
                    value={isFocus ? `${tempObj[0]}` : `${tempObj[0]}°`}
                    onChange={(e) => handleSetObj(e.target.value, 0)}
                    onBlur={(e) => {
                      setFocus(false)
                      handleSetCountOnBlur(e.target.value)
                    }}
                    onFocus={() => setFocus(true)}
                    $isShow={isShowTemp}
                  />
                  <Styled.ArrowUp
                    onClick={() => handleSetCountOnBlur(tempObj[0] + 1)}
                  >
                    <Styled.KeyboardArrowUpIconLogo />
                  </Styled.ArrowUp>
                  <Styled.ArrowDown
                    onClick={() => handleSetCountOnBlur(tempObj[0] - 1)}
                  >
                    <Styled.KeyboardArrowDownIconLogo />
                  </Styled.ArrowDown>
                </Styled.TempCountBox>
                <Styled.TempMeteorologicalBox>
                  Wetterverhältnisse
                </Styled.TempMeteorologicalBox>

                <Styled.TempInputBox>
                  <Styled.MeteoInput
                    onChange={(e) => handleSetObj(e.target.value, 1)}
                    onBlur={(e) => handleSetWeather(e.target.value)}
                    value={tempObj[1]}
                    $isShow={isShow}
                  />
                </Styled.TempInputBox>
              </Styled.TemperatureInsideBox>
            </Styled.TemperatureBoxAlign>
            {/* {tabId === "3" && (
              <>
                <Styled.QuestionAfterDrive>
                  {questionAfterDrive}
                </Styled.QuestionAfterDrive>

                <Styled.TextAreaStyle
                  placeholder="Beschreibung eingeben"
                  autoSize={{minRows: 4}}
                  value={tempObj[2]}
                  onChange={(e) => handleSetObj(e.target.value, 2)}
                  onBlur={(e) => handleSetAnswerAfterDrive(e.target.value)}
                />
              </>
            )} */}
          </Styled.TemperatureBox>
          <Pagination before={before} after={after} />
        </Styled.InsideBox>
      </Styled.MainBox>
    </>
  )
}

export default Temperature
