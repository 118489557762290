import {useMutation} from "@apollo/client"
import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {INSERT_ANSWER, regex, UPDATE_ANSWER} from "../../../const/const"
import {DataContext, UserContext} from "../../../context/context"
import {isAuthDataWithoutParameter} from "../../../middleware/auth"
import AchtungQuestion from "../../achtung-question/achtung.component"

import Line from "../line"
import {
  ContainerMainTitle,
  ContainerMainTitleRelative,
  ContainerNumberTitle
} from "../rhombus/rhombus.styled"
import {Styled} from "./get-health.styled"

function GetHealth({
  title,
  description,
  question,
  isFirst,
  isLast,
  answer: answerParametr,
  questionId
}) {
  const [answer, setAnswer] = useState(answerParametr)

  const days = description.split(";")

  const [updateMutation, {loading}] = useMutation(UPDATE_ANSWER)

  const [insertMutation] = useMutation(INSERT_ANSWER)

  const {state, handlerSet} = useContext(DataContext)
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)

  const [activeButton, setActiveButton] = useState(
    days.indexOf(answer.length !== 0 ? answer[0].answer : -1)
  )

  const findDecimal = question !== null ? question.match(regex) : null

  const findDecimalTitle = title !== null ? title.match(regex) : null

  const {tabId, stageId} = useParams()

  const [isShow, setIsShow] = useState(false)

  const navigate = useNavigate()

  const handleSetIsshow = (value) => {
    setIsShow(value)
  }

  // set appear achtung message
  useEffect(() => {
    // if (true) - show message
    // if (not setted and this page is in array)
    handleSetIsshow(
      activeButton === -1 &&
        stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`)
    )
  }, [activeButton])

  async function updateData(index) {
    if (answer.length !== 0) {
      await updateMutation({
        variables: {
          answerId: answer[0].id,
          answer: days[index]
        }
      })
    } else {
      const insertFieldId = await insertMutation({
        variables: {
          answer: days[index],
          question_id: questionId,
          test_scenario_history_id: state.historyId
        }
      })
      setAnswer([
        {
          id: insertFieldId.data.insert_questions_answers_one.id,
          answer: days[index]
        }
      ])
    }
  }

  const handleClick = (index) => {
    if (loading) return
    setActiveButton(index)
    updateData(index).catch((err) => {
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes) updateData(index)
          else navigate("/login")
        })
      }
    })
  }

  return (
    <>
      {!isFirst && title !== null && <Line />}
      <AchtungQuestion
        isShow={isShow}
        title="Bitte ausfüllen! Falls nicht möglich, dem Versuchsleiter zeigen."
      />
      {title !== null && (
        <ContainerMainTitle>
          <ContainerMainTitleRelative>
            {title.match(regex) !== null && (
              <ContainerNumberTitle
                $isBigSpace={
                  findDecimalTitle !== null
                    ? Number(findDecimalTitle[0].split(".")[0]) >= 10
                    : 0
                }
              >
                {title.match(regex)}
              </ContainerNumberTitle>
            )}
          </ContainerMainTitleRelative>
          {findDecimalTitle !== null
            ? title.split(findDecimalTitle[0])[1]
            : title}
        </ContainerMainTitle>
      )}
      <Styled.MainBox>
        <Styled.TimeBoxInside>
          <Styled.Container>
            <Styled.InsideText>
              {question.match(regex) !== null && (
                <Styled.ContainerNumber
                  $isBigSpace={
                    findDecimal !== null
                      ? Number(findDecimal[0].split(".")[0]) >= 10
                      : 0
                  }
                >
                  {question.match(regex)}
                </Styled.ContainerNumber>
              )}
              {findDecimal !== null
                ? question.split(findDecimal[0])[1]
                : question}
            </Styled.InsideText>
            <Styled.ButtonGroup>
              {days.map((el, i) => (
                <Styled.DayButton
                  key={el}
                  id={i}
                  onClick={() => handleClick(i)}
                  active={i === activeButton ? "true" : ""}
                  first={i === 0}
                  last={i === 2}
                >
                  {el}
                </Styled.DayButton>
              ))}
            </Styled.ButtonGroup>
          </Styled.Container>
        </Styled.TimeBoxInside>
      </Styled.MainBox>
      {!isFirst && isLast && title !== null && <Line />}
    </>
  )
}

export default GetHealth
