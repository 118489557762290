import React, {useContext, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {Button, Dropdown, Menu} from "antd"
import {DownOutlined} from "@ant-design/icons"
import {Styled} from "./account.styled"
import {ModalStyle} from "../../pages/login/StyledComponents"
import Login from "../../pages/login"
import ResetPassword from "../resetPassword"
import {DataContext} from "../../context/context"
import {deleteCookie} from "../../middleware/auth"
import {stateObj} from "../../const/contexts"

const roleNavigation = {
  research_director: "/admin/users",
  test_subject: "/drive",
  driver: "/drive"
}

const Account = () => {
  const {state, handlerSet} = useContext(DataContext)
  const [isLoading, setLoading] = useState(false)
  const [loginModalVisible, setLoginModalVisible] = useState(true)
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false)
  const {t} = useTranslation()
  const [searchParams] = useSearchParams()

  const isLog = state.user_type && state.user_id

  const navigate = useNavigate()

  const handleOk = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setLoginModalVisible(false)
    }, 3000)
  }

  const handleForgotPasswordOk = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setLoginModalVisible(false)
    }, 3000)
  }

  const setDefaultState = () => {
    handlerSet(stateObj)
  }

  const logout = () => {
    deleteCookie()
      .then(() => {
        setDefaultState()
        navigate("/login")
      })
      .catch(() => null)
  }

  const handleClick = ({key}) => {
    if (key === "Logout") {
      setLoginModalVisible(false)
      logout()
    }
  }

  const handleAuthorized = (value) => {
    handlerSet({
      user_id: value.id,
      user_type: value.user_type,
      user_first_name: value.first_name,
      user_last_name: value.last_name,
      user_name: value.user_name,

      previousUserId: value.id,
      previousUserType: value.user_type
    })

    // if no parameter or previous user no same
    if (
      !searchParams.get("redirectTo") ||
      ((state.previousUserId !== value.id ||
        state.previousUserType !== value.user_type) &&
        value.user_type !== "research_director")
    )
      navigate(roleNavigation[value.user_type])

    // if previous user the same
    if (
      state.previousUserId === value.id &&
      state.previousUserType === value.user_type
    ) {
      handlerSet({
        scenarioActive: searchParams.get("scenarioId"),
        historyId: searchParams.get("historyId")
      })

      navigate(searchParams.get("redirectTo"))
    }

    if (
      searchParams.get("redirectTo") !== null &&
      value.user_type === "research_director"
        ? searchParams.get("redirectTo").includes("drive")
        : false
    ) {
      navigate("/admin/users")
    } else if (searchParams.get("redirectTo") !== null)
      navigate(searchParams.get("redirectTo"))
  }

  const handleClickedForgotPassword = () => {
    setResetPasswordModalVisible(true)
  }

  const items = [{label: t("account.menuItems.logout"), key: "Logout"}]

  const menu = (
    <Menu onClick={handleClick} onKeyDown={handleClick} items={items} />
  )

  return isLog ? (
    <Styled.MainBox>
      <Styled.BoxAvatar>
        <Styled.BoxAvatarBorder>
          {state.user_name[0].toUpperCase()}
        </Styled.BoxAvatarBorder>
      </Styled.BoxAvatar>
      <Styled.BoxDropDown>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Styled.MenuButton
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            type="button"
          >
            {state.user_name} <DownOutlined />
          </Styled.MenuButton>
        </Dropdown>
      </Styled.BoxDropDown>
    </Styled.MainBox>
  ) : (
    <>
      <Button
        key="1"
        type="primary"
        onClick={() => setLoginModalVisible(true)}
        onKeyPress={() => setLoginModalVisible(true)}
      >
        {t("account.loginButton")}
      </Button>
      <ModalStyle
        visible={loginModalVisible || !isLog}
        onOk={handleOk}
        footer={[]}
        closable={false}
        centered
      >
        <Login
          setIsVisible={setLoginModalVisible}
          handleAuthorized={handleAuthorized}
          handleClickedForgotPassword={handleClickedForgotPassword}
        />
      </ModalStyle>
      <ModalStyle
        visible={resetPasswordModalVisible}
        onOk={handleForgotPasswordOk}
        footer={[]}
        closable={false}
        centered
      >
        <ResetPassword setIsVisible={setResetPasswordModalVisible} />
      </ModalStyle>
    </>
  )
}

export default Account
