import {message} from "antd"
import axios from "axios"
import {SWUI_BE_API_URL} from "../const/const"
import {stateObjDefault} from "../const/contexts"

export const checkUserAllowedPath = (userType, location) => {
  let res = ""
  if (
    location === "/" ||
    (location === "/admin" && userType === "research_director")
  ) {
    res = "admin/users"
  }
  if (location !== "/" && userType === "research_director") {
    res = "admin"
  }
  if (userType !== "research_director") res = "drive"
  return res
}

export const isAllowedPath = (locationPath, userType) =>
  locationPath.includes(checkUserAllowedPath(userType))

export const isAuthDataWithoutParameter = async (handlerSet) =>
  /* eslint-disable-next-line */
  await axios
    .post(`${SWUI_BE_API_URL}/api/refresh_token`, {}, {withCredentials: true})
    .then(({data}) => {
      handlerSet({
        user_id: data.id,
        user_type: data.user_type,
        user_first_name: data.first_name,
        user_last_name: data.last_name,
        user_name: data.user_name
      })

      return data
    })
    .catch(() => {
      handlerSet(stateObjDefault)
    })

export const isLogIn = (data) =>
  data.user_type && data.user_id && data.user_name

export const deleteCookie = async () =>
  /* eslint-disable-next-line */
  await axios
    .get(`${SWUI_BE_API_URL}/api/logout`, {withCredentials: true})
    .catch((err) => {
      message.error("Fehler beim Abmelden")
      throw new Error(err)
    })
