import React, {useState, useRef, useEffect, useContext} from "react"
import {Empty} from "antd";
import {useMutation} from "@apollo/client";
import {Styled} from "./modal-susi.styled";
import ButtonStyle from "../../common/button-style";
import SusiPcImg from "../../images/pc.png";
import SusiPortrait from "../../images/susi_portrait.png";
import {getTime} from "../../middleware/math";
import {formatISODate, zeroPad} from "../../utils/utils";
import {DataContext} from "../../context/context";
import {UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_COMMENTS} from "../../const/const";

const {
  HeaderMainStretch,
  HeaderMain,
  MainBox,
  HeaderBox,
  BodyBox,
  FooterBox,
  HeaderTextBox,
  TextBoxTitle,
  TextBoxDescrition,
  HeaderImgBox,
  HeaderImg,
  FooterBoxInside,
  ButtonGroup,
  ButtonMargin,
  TableBox,
  TableStyled,
  TableBoxDescribe,
  TableBoxDescribeTitle,
  TableBoxDescribeText,
  InputStyle,

  SusiImgDescBoxStretch,
  SusiImgDescCenter,
  SusiImgDescBox,
  SusiDescBox,
  SusiDescBoxTittle,
  SusiDescBoxText,
  SusiDescBoxTimeGroup,
  SusiDescBoxTimeSvg,
  SusiDescBoxTimeText,
  SusiDescBoxTime,
  SusiImgBox,
  SusiImg,

  SusiListStretch,
  SusiListCenter,
  SusiListBox,
  SusiListDiv,
  SusiListItems,
  Item,
  ItemsName,
  ItemsMessage,
  CommentBox,
  CommentTitle,
  CommentGroupInputButton,
  CommentInputBox,
  CommentInput,
  CommentButton,
  ButtonArrowRightSvg,

  ModalStyle
} = Styled

const ModalSusi = ({closable, isVisible, setIsVisible, refetchDialogs, allDialogData, setAllDialogData, currentDialog}) => {
	const {state, handlerSet} = useContext(DataContext);
	const [misc, setMisc] = useState();
	const [comment, setComment] = useState('');
	const miscTimestampDate = formatISODate(currentDialog?.misc_timestamp);

  const [UpdateTestScenariosHistoryDialogsComments] = useMutation(UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_COMMENTS)

  useEffect(() => {
  	setMisc(currentDialog?.misc_number);
  	setComment(currentDialog?.comment ?? '');
  }, [isVisible, allDialogData, currentDialog])

  const handleMiscChange = (e) => {
  	setMisc(e);
  }

  const handleCommentChange = (e) => {
  	setComment(e.target.value);
  }

  const dataSource =  [
    {
      key: "1",
      countIndex: "1",
      NameIndex: "MISC Wert",
      ValueIndex: 
      <InputStyle 
    		min={0}
    		max={10}
      	placeholder="MISC Eingabe"
      	value={misc}
      	onChange={handleMiscChange}
      />,
      TimestampIndex: miscTimestampDate,
      UsernameIndex: currentDialog?.test_scenarios_history?.test_subject?.user_name
    },
  ]

  const columns = [
    {
      title: "#",
      dataIndex: "countIndex",
      key: "#",
      width: 10
    },
    {
      title: "Beschreibung",
      dataIndex: "NameIndex",
      key: "Name",
      width: 20
    },
    {
      title: "Wert",
      dataIndex: "ValueIndex",
      key: "Value",
      width: 20
    },
    {
      title: "Timestamp",
      dataIndex: "TimestampIndex",
      key: "Timestamp",
      width: 20
    },
    {
      title: "Username",
      dataIndex: "UsernameIndex",
      key: "Username",
      width: 20
    }
  ]

  const uppercaseFirstLetter = (str) => {
  	if(str.length <= 1) {
  		return str.toUpperCase();
  	}
  	return str[0].toUpperCase() + str.slice(1, str.length).toLowerCase();
  }

	const dialogObj = JSON.parse(currentDialog?.dialog_json ?? '[]').map((c, i) => {
		const senderName = c?.sender?.name;
		return {
			key: c?.datetime,
			message: c?.message,
			name: senderName === "susi" ? uppercaseFirstLetter(senderName) : c?.sender?.userName,
			isSusi: senderName === "susi"
		}
	});

	const handleApprove = async () => {
		setIsVisible(false);

		const resp = await UpdateTestScenariosHistoryDialogsComments({
			variables: {
				testScenarioHistoryDialogId: currentDialog?.id,
				miscNumber: parseInt(misc, 10),
				miscTimestamp: new Date().toISOString(),
				miscComment: comment
			}
		})
		.catch(err => console.error("Error updating the test scenario history dialogs with new comments", err));

		await refetchDialogs({
			testScenarioHistoryDialogId: state.historyId
		});
	}

  const handleCancel = () => {
    const newDialogData = JSON.parse(JSON.stringify(allDialogData));
    const currentIdx = newDialogData.test_scenarios_history_dialogs.map(c => c.id).findIndex(c => c === currentDialog.id);
    newDialogData.test_scenarios_history_dialogs[currentIdx].misc_number = parseInt(misc, 10);
    newDialogData.test_scenarios_history_dialogs[currentIdx].comment = comment;
		setAllDialogData(newDialogData);
    setIsVisible(false);
  };

  return (
    <>
      <ModalStyle
        visible={isVisible}
        footer={null}
        closable={closable}
        width={912}
      >
        <MainBox>
          <HeaderMainStretch>
            <HeaderMain>
              <HeaderBox>
                <HeaderTextBox>
                  <TextBoxTitle>Extrahierte Daten</TextBoxTitle>
                  <TextBoxDescrition>
                    Sie sehen hier den MISC-Wert, den Susi abgespeichert hat.
                  </TextBoxDescrition>
                </HeaderTextBox>
                <HeaderImgBox>
                  <HeaderImg src={SusiPcImg} />
                </HeaderImgBox>
              </HeaderBox>
            </HeaderMain>
          </HeaderMainStretch>
          <BodyBox>
            <TableBox>
              <TableStyled
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{y: 27 * 5}}
              />
            </TableBox>
            <TableBoxDescribe>
              <TableBoxDescribeTitle>Erklärung</TableBoxDescribeTitle>
              <TableBoxDescribeText>
                Sie können hier den Wert der MISC-Abfrage anpassen, falls Susi
                etwas Falsches verstanden hat.
              </TableBoxDescribeText>
            </TableBoxDescribe>
            <SusiImgDescBoxStretch>
              <SusiImgDescCenter>
                <SusiImgDescBox>
                  <SusiDescBox>
                    <SusiDescBoxTittle>Gesprächsverlauf</SusiDescBoxTittle>
                    <SusiDescBoxText>
                      Hier sehen Sie den Dialog mit Susi für die aktuell
                      ausgewählte MISC-Abfrage
                    </SusiDescBoxText>
                    <SusiDescBoxTime>
                      <SusiDescBoxTimeGroup>
    										{miscTimestampDate && (
    											<>
    												<SusiDescBoxTimeSvg />
    												<SusiDescBoxTimeText>
    													{miscTimestampDate}
    												</SusiDescBoxTimeText>
    											</>
    										)}
                      </SusiDescBoxTimeGroup>
                    </SusiDescBoxTime>
                  </SusiDescBox>
                  <SusiImgBox>
                    <SusiImg src={SusiPortrait} />
                  </SusiImgBox>
                </SusiImgDescBox>
              </SusiImgDescCenter>
            </SusiImgDescBoxStretch>
            <SusiListStretch>
              <SusiListCenter>
                <SusiListBox>
                  <SusiListDiv>
                    {dialogObj?.length ? dialogObj.map((item) => (
                      <SusiListItems
                        key={item.key}
                        $isSusi={item.isSusi}
                      >
                        <Item $isSusi={item.isSusi}>
                          <ItemsName $isSusi={item.isSusi}>
                            {uppercaseFirstLetter(item.name)}
                          </ItemsName>
                          <ItemsMessage $isSusi={item.isSusi}>
                            {item.message}
                          </ItemsMessage>
                        </Item>
                      </SusiListItems>
                    )) : <Empty description="Keine Daten"/>}
                  </SusiListDiv>
                  <CommentBox>
                    <CommentTitle>
											Hier können Sie einen Kommentar zu diesem Dialog eingeben
                    </CommentTitle>
                  </CommentBox>
                  <CommentGroupInputButton>
                    <CommentInputBox>
                      <CommentInput value={comment} onChange={handleCommentChange} placeholder="Kommentar hinzufügen" />
                    </CommentInputBox>
                  </CommentGroupInputButton>
                </SusiListBox>
              </SusiListCenter>
            </SusiListStretch>
          </BodyBox>
          <FooterBox>
            <FooterBoxInside>
              <ButtonGroup>
                <ButtonStyle
                  color="#FABA15"
                  borderColor="#faba15"
                  borderSize={1}
                  backgroundColor="#FFFFFF"
                  height={36}
                  name="Verwerfen"
                  borderRadius={4}
                  handleDataSet={handleCancel}
                />
                <ButtonMargin>
                  <ButtonStyle
                    color="#495057"
                    borderColor="#FABA15"
                    borderSize={1}
                    backgroundColor="#FABA15"
                    height={36}
                    name="Speichern"
                    borderRadius={4}
                    handleDataSet={handleApprove}
                  />
                </ButtonMargin>
              </ButtonGroup>
            </FooterBoxInside>
          </FooterBox>
        </MainBox>
      </ModalStyle>
    </>
  )
}

export default ModalSusi
