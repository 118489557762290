import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Form, Select, DatePicker, Input} from "antd"
import {
  RightSideUserFormContainer,
  LeftSideUserFormContainer,
  CreateUserButton,
  StyledInput,
  StyledForm,
  StyledButtonDontClick,
  LastRow,
  GenderRadio,
  StyledRadioButton
} from "./styledComponents"
import DriverLicenseTags from "./DriverLicenseTags"

const UserForm = ({
  formInstance,
  onFinish,
  resetForm,
  deleteCurrentUser,
  currentID,
  handleSetIsloading,
  isUserNoSaved
}) => {
  const {t} = useTranslation()

  const [isUpdate, setIsUpdate] = useState(false)
  const [userType, setUserType] = useState()

  const handleChange = () => {
    setIsUpdate(
      formInstance.getFieldValue("prevType") !==
        formInstance.getFieldValue("type")
    )
    setUserType(formInstance.getFieldValue("type"))
  }

  useEffect(() => {
    // for update not need password
    setIsUpdate(false)
  }, [currentID])

  const handlerFormChange = (data) => {
    isUserNoSaved(Object.keys(data).length > 0)
  }

  return (
    <StyledForm
      onFinish={(form) => {
        handleSetIsloading()
        onFinish(form, true)
      }}
      layout="vertical"
      form={formInstance}
      onValuesChange={handlerFormChange}
    >
      <LeftSideUserFormContainer>
        <Form.Item name="first_name" label={t("generic.forms.firstName.label")}>
          <StyledInput />
        </Form.Item>
        <Form.Item
          name="user_name"
          label={t("generic.forms.userName.label")}
          rules={[
            {
              required: true,
              message: "Bitte geben Sie Ihren Benutzernamen ein!"
            }
          ]}
        >
          <StyledInput />
        </Form.Item>
        <Form.Item name="address" label={t("users.address")}>
          <Input.TextArea autoSize={{minRows: 2, maxRows: 2}} />
        </Form.Item>
        <Form.Item
          name="type"
          label={t("generic.forms.userType.label")}
          rules={[
            {
              required: true,
              message: "Bitte geben Sie Ihren Typ ein!"
            }
          ]}
        >
          <Select
            dropdownMatchSelectWidth={false}
            placeholder={t("users.pleaseSelect")}
            onChange={() => handleChange()}
          >
            <Select.Option value="Fahrer">
              {t("generic.forms.userType.types.driver")}
            </Select.Option>
            <Select.Option value="Proband">
              {t("generic.forms.userType.types.testSubject")}
            </Select.Option>
            <Select.Option value="Testleiter">
              {t("generic.forms.userType.types.researchDirector")}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="email"
          label={t("generic.forms.email.label")}
          rules={[
            {
              required: userType === "Testleiter",
              message: "Bitte geben Sie Ihre E-Mail ein!"
            },
            {type: "email", message: "Dies ist keine gültige E-Mail!"}
          ]}
        >
          <StyledInput />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.type !== currentValues.type
          }
        >
          {({getFieldValue}) =>
            getFieldValue("type") === "Fahrer" && currentID ? (
              <DriverLicenseTags currentID={currentID} />
            ) : null
          }
        </Form.Item>
      </LeftSideUserFormContainer>
      <RightSideUserFormContainer>
        <Form.Item name="last_name" label={t("generic.forms.lastName.label")}>
          <StyledInput />
        </Form.Item>
        <Form.Item name="phone" label={t("users.phone")}>
          <StyledInput />
        </Form.Item>
        <Form.Item name="date_of_birth" label={t("users.dateOfBirth")}>
          <DatePicker
            format="YYYY/MM/DD"
            placeholder={t("users.dateOfBirthPlaceholder")}
          />
        </Form.Item>
        <Form.Item
          name="gender"
          label={t("generic.forms.gender.label")}
          style={{marginTop: "47px"}}
        >
          <GenderRadio>
            <StyledRadioButton value="male">
              {t("generic.forms.gender.male")}
            </StyledRadioButton>
            <StyledRadioButton value="female">
              {t("generic.forms.gender.female")}
            </StyledRadioButton>
            <StyledRadioButton value="diverse">
              {t("generic.forms.gender.diverse")}
            </StyledRadioButton>
            <StyledRadioButton value="notStated">
              {t("generic.forms.gender.notStated")}
            </StyledRadioButton>
          </GenderRadio>
        </Form.Item>

        <Form.Item
          name="password"
          label={t("generic.forms.password.label")}
          rules={[
            {
              required:
                (currentID === null || isUpdate) && userType === "Testleiter",
              message: "Bitte geben Sie Ihr Passwort ein!"
            }
          ]}
        >
          <StyledInput type="password" />
        </Form.Item>
        <LastRow>
          <StyledButtonDontClick onClick={deleteCurrentUser}>
            {t("generic.forms.buttons.delete")}
          </StyledButtonDontClick>
          <StyledButtonDontClick onClick={resetForm}>
            {t("generic.forms.buttons.cancel")}
          </StyledButtonDontClick>
          <CreateUserButton onClick={formInstance.submit}>
            {t("generic.forms.buttons.save")}
          </CreateUserButton>
        </LastRow>
      </RightSideUserFormContainer>
    </StyledForm>
  )
}

export default UserForm
