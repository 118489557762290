import React from "react"
import {useOutletContext, useParams} from "react-router-dom"

function Tests() {
  const data = useOutletContext()

  const {tabId, stageId} = useParams()

  return <>{data !== undefined && data[`${tabId}${stageId}`]}</>
}
export default Tests
