import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 111px;
  background: transparent;
  border-radius: 4px;
`

export const BoxAvatar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
`

export const BoxAvatarBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 50%;
  width: 30px;
  height: 30px;
`

export const BoxDropDown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 21px;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`
export const MenuButton = styled.button`
  background: white;
  color: "#555B6D";
  font-weight: "bold";
  border: 0;
  cursor: pointer;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Styled = {
  MainBox,
  BoxAvatar,
  BoxAvatarBorder,
  BoxDropDown,
  MenuButton
}
