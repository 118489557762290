import React from "react"
import {useNavigate} from "react-router-dom"
import {ModalStyle} from "../../pages/login/StyledComponents"
import {Styled} from "./modal-before-drive.styled"
import SusiPortraitImg from "../../images/susi_portrait.png"

const {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  OutterRowWrapper,
  TitleBox
} = Styled

const ModalBeforeDrive = ({
  closable,
  isVisibleFinish,
  setIsVisibleFinish,
  lastTabClick
}) => {
  const navigate = useNavigate()

  const handleClose = () => {
    setIsVisibleFinish(false)
  }

  return (
    <>
      <ModalStyle
        visible={isVisibleFinish}
        footer={[]}
        centered
        closable={closable}
        afterClose={handleClose}
      >
        <OutterRowWrapper>
          <LeftSideFormContainer>
            <SusiPortraitWrapper
              justify="space-evenly"
              align="middle"
              wrap={false}
            >
              <TitleBox>
                <OrangeText>Hallo, ich bin SUSI.</OrangeText>
              </TitleBox>
              <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
            </SusiPortraitWrapper>
            <FormContainer>
              <Styled.Description>
                Sie haben nicht alle Fragen beantwortet! Möchten Sie trotzdem
                zum nächsten Bereich gehen?
              </Styled.Description>

              <Styled.ButtonsGroup>
                <Styled.ButtonStyled
                  onClick={() => {
                    setIsVisibleFinish(false)
                    navigate(`/drive/${lastTabClick}/0`)
                  }}
                >
                  Ja
                </Styled.ButtonStyled>
                <Styled.ButtonStyled onClick={() => handleClose()}>
                  Nein
                </Styled.ButtonStyled>
              </Styled.ButtonsGroup>
            </FormContainer>
          </LeftSideFormContainer>
        </OutterRowWrapper>
      </ModalStyle>
    </>
  )
}

export default ModalBeforeDrive
