import styled from "styled-components"

export const Line = styled.div`
  margin: 10px 0;
  height: 1px;
  background-color: #dedede;
  color: #dedede;
  border: 1px solid #dedede;
`

export const Styled = {
  Line
}
