import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef
} from "react"
import axios from "axios"
import {useQuery, gql, useMutation} from "@apollo/client"
import {useNavigate, useLocation} from "react-router-dom"
import {Space, Typography, Button, message} from "antd"
import {BookOutlined} from "@ant-design/icons"
import useWebSocket, {ReadyState} from "react-use-websocket"
import useSpeechToText from "react-hook-speech-to-text"
import uuid from "react-uuid"
import io from "socket.io-client"
import {useTranslation} from "react-i18next"
import {
  PICOVOICE_ACCESS_KEY,
  SWUI_BE_API_URL,
  SWUI_BE_API_WS_TRIGGER_URL,
  EVA_API_URL,
  EVA_WORKFLOW_ID,
  GOOGLE_SPEECH_RECOGNITION_API_KEY,
  UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS,
  UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_MISC,
  UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_MESSAGES,
  GET_TEST_SCENARIOS_HISTORY_TIME_DRIVE_START_END,
  UPDATE_TEST_SCENARIOS_HISTORY_TEST_DRIVE_START,
  UPDATE_TEST_SCENARIOS_HISTORY_TEST_DRIVE_END
} from "../../const/const"
import {playAudio, pauseAudio} from "./utils"
import {Styled} from "./susi-drive-screen.styled"
import susi_rund from "../../images/susi_rund.png"
import {DataContext} from "../../context/context"
import TestDriveAudioBook1 from "../../audio/Audiobuch-1.mp3"
import TestDriveAudioBook2 from "../../audio/Audiobuch-2.mp3"
import TestDriveAudioBook3 from "../../audio/Audiobuch-3.mp3"
import TestDriveAudioBook4 from "../../audio/Audiobuch-4.mp3"
import PorcupineModelParamsPV from "../../models/porcupine_params_de.pv"
import HeySusiWakeWordPPN from "../../models/Hey-Susi_de_wasm_v2_1_0.ppn"
import HeyKarliWakeWordPPN from "../../models/Hey-Karli_de_wasm_v2_1_0.ppn"

import EvaAudioDriveCancellation from "../../audio/bitte-fahren-sie-rechts-ran-und-unterbrechen-sie-die-fahrt.mp3"
import EvaAudioAnswerMiscInputValues from "../../audio/bitte-misery-scale-beantworten-bitte-werte-angeben.mp3"
import EvaAudioLookOut from "../../audio/bitte-rausschauen.mp3"
import EvaAudioInputValues from "../../audio/bitte-werte-angeben.mp3"
import EvaAudioInputSuccess from "../../audio/eingabe-erfolgreich.mp3"
import EvaAudioDialogEnd from "../../audio/ich-werde-das-gespräch-beenden.mp3"
import EvaAudioInputMisc from "../../audio/möchten-sie-die-misery-scale-ausfüllen.mp3"
import EvaAudioNotUnderstandingInputValues from "../../audio/ich-habe-sie-nicht-verstanden-bitte-werte-angeben.mp3"
import EvaAudioNotUnderstandingMiscInputValues from "../../audio/leider-habe-ich-sie-immer-noch-nicht-verstanden-die-werte-der-misery-scale-sehen-sie-im-auto-bitte-werte-angeben.mp3"
import EvaAudioNotUnderstandingRepeat from "../../audio/entschuldigung-leider-konnte-ich-sie-nicht-richtig-verstehen-könnten-sie-ihre-eingabe-bitte-wiederholen.mp3"

import WarningDurationAudio from "../../audio/die-fahrt-ist-gleich-zu-ende.mp3"
import WarningDirectionAudio from "../../audio/warnung-wir-werden-bald-die-richtung-ändern.mp3"
import WarningCurveAudio from "../../audio/warnung-ein-kurvenreicher-teil-der-strecke-nähert-sich.mp3"
import PleaseKeepWorkingAudio from "../../audio/bitte-arbeiten-sie-weiter.mp3"
import PleaseLookOutTheWindowAudio from "../../audio/bitte-schauen-sie-aus-dem-fenster.mp3"
import WarningTriggerAudio from "../../audio/susi-warnton.mp3"
import WarningAccelerationAudio from "../../audio/warnung-wir-werden-in-kürze-beschleunigen.mp3"
import {useKeywordRecognizer} from "./useKeywordRecognizer"

const RECOGNITION_META = {
  interactionType: "VOICE_SEARCH",
  microphoneDistance: "MIDFIELD",
  originalMediaType: "AUDIO",
  recordingDeviceType: "VEHICLE"
}

const DEFAULT_STT_REQUEST_CONFIG = {
  useLegacyResults: false,
  useOnlyGoogleCloud: true,
  crossBrowser: false,
  continuous: true,
  timeout: 20000,
  googleApiKey: GOOGLE_SPEECH_RECOGNITION_API_KEY,
  googleCloudRecognitionConfig: {
    encoding: "LINEAR16",
    languageCode: "de-DE",
    useEnhanced: false,
    maxAlternatives: 1,
    enableSpokenPunctuation: false,
    enableAutomaticPunctuation: false,
    model: "command_and_search",
    metadata: RECOGNITION_META
  }
}

const keywordModel = [
  {
    publicPath: HeySusiWakeWordPPN,
    label: "Hey Susi (German)"
  },
  {
    publicPath: HeyKarliWakeWordPPN,
    label: "Hey Karli (German)"
  }
]

const porcupineModel = {
  publicPath: PorcupineModelParamsPV
}

const triggerAudiosObj = {
  Trigger_LookOutside_Counter: new Audio(PleaseLookOutTheWindowAudio),
  Trigger_Read_Counter: new Audio(PleaseKeepWorkingAudio),
  Trigger_Warning_Counter: new Audio(WarningTriggerAudio),
  Warning_Direction_Value: new Audio(WarningDirectionAudio),
  Warning_AccelerationIntensity_Value: new Audio(WarningAccelerationAudio),
  Warning_Duration_Value: new Audio(WarningDurationAudio)
}

const evaAudiosObj = {
  // utter_started_misc_trigger
  "Bitte Werte angeben!": new Audio(EvaAudioInputValues),
  "Bitte geben Sie nun einen Misery Scale Wert an!": new Audio(
    EvaAudioInputValues
  ),

  // utter_ask_misc_value
  "Bitte Misery Scale beantworten! Bitte Werte angeben!": new Audio(
    EvaAudioAnswerMiscInputValues
  ),
  "Bitte geben Sie einen Misery Scale Wert an.": new Audio(
    EvaAudioAnswerMiscInputValues
  ),
  "Bitte geben Sie einen Misery Scale Wert an. Bitte geben Sie nun einen Misery Scale Wert an!":
    new Audio(EvaAudioAnswerMiscInputValues),

  // utter_wrong_misc_value
  "Ich habe Sie nicht verstanden. Bitte Werte angeben!": new Audio(
    EvaAudioNotUnderstandingInputValues
  ),
  "Ich konnte leider keinen gültigen Wert erkennen.": new Audio(
    EvaAudioNotUnderstandingInputValues
  ),
  "Ich konnte leider keinen gültigen Wert erkennen. Bitte geben Sie nun einen Misery Scale Wert an!":
    new Audio(EvaAudioNotUnderstandingInputValues),

  // utter_wrong_misc_value_twice
  "Leider habe ich Sie immer noch nicht verstanden. Die Werte der Misery Scale sehen Sie im Auto. Bitte Werte angeben!":
    new Audio(EvaAudioNotUnderstandingMiscInputValues),
  "Leider habe ich Sie immer noch nicht richtig verstanden. Die Werte der Misery Scale sehen Sie im Auto.":
    new Audio(EvaAudioNotUnderstandingMiscInputValues),
  "Leider habe ich Sie immer noch nicht richtig verstanden. Die Werte der Misery Scale sehen Sie im Auto. Bitte geben Sie nun einen Misery Scale Wert an!":
    new Audio(EvaAudioNotUnderstandingMiscInputValues),

  // utter_wrong_misc_value_fallback
  "Ich werde das Gespräch beenden.": new Audio(EvaAudioDialogEnd),
  "Aufgrund eines technischen Problems werde ich das Gespräch nun beenden.":
    new Audio(EvaAudioDialogEnd),
  "Da Sie die Eingabe weder bestätigt, noch verneint haben, beende ich nun das Gespräch. Sie können mich jederzeit erneut kontaktieren.":
    new Audio(EvaAudioDialogEnd),

  // utter_misc_lower_five
  "Eingabe erfolgreich!": new Audio(EvaAudioInputSuccess),
  "Die Eingabe war erfolgreich, danke.": new Audio(EvaAudioInputSuccess),

  // utter_enter_misc_greater_five
  "Bitte fahren Sie rechts ran und unterbrechen Sie die Fahrt!": new Audio(
    EvaAudioDriveCancellation
  ),
  "Misery Scale Wert größer 5 erkannt. Bitte fahren Sie rechts ran und unterbrechen Sie die Fahrt!":
    new Audio(EvaAudioDriveCancellation),

  // utter_window_warning
  "Bitte rausschauen!": new Audio(EvaAudioLookOut),
  "Bitte schauen Sie für einen Moment aus dem Fenster.": new Audio(
    EvaAudioLookOut
  ),

  "Möchten Sie die Misery Scale ausfüllen?": new Audio(EvaAudioInputMisc),
  "Entschuldigung, leider konnte ich Sie nicht richtig verstehen. Könnten Sie Ihre Eingabe bitte wiederholen?":
    new Audio(EvaAudioNotUnderstandingRepeat)
}

const audioBooksFilenameArr = [
  TestDriveAudioBook1,
  TestDriveAudioBook2,
  TestDriveAudioBook3,
  TestDriveAudioBook4
]

const audioBooksMetaArr = audioBooksFilenameArr.map((audio, idx) => ({
  isActive: false,
  id: idx
}))

const audioBooksArr = audioBooksFilenameArr.map((audio) => new Audio(audio))

const SusiDriveScreen = () => {
  const {state, handlerSet} = useContext(DataContext)
  const {historyId: currentTestScenarioHistoryId} = state
  const [isDialogError, setIsDialogError] = useState(false)
  const [speechQueue, setSpeechQueue] = useState([])
  const [isEvaWebsocketResponseWaiting, setIsEvaWebsocketResponseWaiting] =
    useState(false)
  const [isSpeechWebsocketReady, setIsSpeechWebsocketReady] = useState(false)
  const [evaAudios, setEvaAudios] = useState(evaAudiosObj)
  const [triggerAudios, setTriggerAudios] = useState(triggerAudiosObj)
  const [audioBooksMeta, setAudioBooksMeta] = useState(audioBooksMetaArr)
  const [audioBooks, setAudioBooks] = useState(audioBooksArr)
  const [microphoneColor, setMicrophoneColor] = useState("#495057")
  const [isDrive, setIsDrive] = useState(true)
  const [isDialogInProgress, setIsDialogInProgress] = useState(false)
  const [speechSocket, setSpeechSocket] = useState()
  const speechQueueRef = useRef([])
  const isEndOfSpeechMsgRef = useRef()
  const evaAudioRef = useRef()
  const isEvaAudioPlayingRef = useRef(false)
  const triggerAudioRef = useRef()
  const isTriggerAudioPlayingRef = useRef(false)
  const isMicrophoneListening = useRef(true)
  const audioBookRef = useRef()
  const isAudioBookPlayingRef = useRef(false)
  const isUserDialogInProgress = useRef(false)
  const wasAudioBookPlaying = useRef(false)
  const isComponentMounted = useRef(true)
  const lastWorkflowExecutionId = useRef()
  const currentWorkflowExecutionId = useRef()
  const currentTimeoutTimerId = useRef()
  const currentWebsocketReconnectTimeoutId = useRef()
  const wasLastQuestionMiscRef = useRef()
  const dialogHistoryRef = useRef([])
  const [nextSpeechContext, setNextSpeechContext] = useState(undefined)
  const [sttConfig, setSttConfig] = useState(DEFAULT_STT_REQUEST_CONFIG)

  const {t} = useTranslation()

  const navigate = useNavigate()
  const {
    loading: isGetHistoryQueryLoading,
    error: isGetHistoryQueryError,
    data: getQueryHistoryData,
    refetch
  } = useQuery(GET_TEST_SCENARIOS_HISTORY_TIME_DRIVE_START_END, {
    variables: {
      id: state.historyId
    }
  })

  const [UpdateTestScenariosHistoryDialogs] = useMutation(
    UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS
  )
  const [UpdateTestScenariosHistoryDialogsMisc] = useMutation(
    UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_MISC
  )
  const [UpdateTestScenariosHistoryDialogsMessages] = useMutation(
    UPDATE_TEST_SCENARIOS_HISTORY_DIALOGS_MESSAGES
  )
  const [UpdateTestScenariosHistoryTestDriveStart] = useMutation(
    UPDATE_TEST_SCENARIOS_HISTORY_TEST_DRIVE_START
  )
  const [UpdateTestScenariosHistoryTestDriveEnd] = useMutation(
    UPDATE_TEST_SCENARIOS_HISTORY_TEST_DRIVE_END
  )

  useEffect(() => {
    if (getQueryHistoryData && state?.historyId) {
      const {
        test_scenarios_history: [entry]
      } = getQueryHistoryData
      if (entry && !entry?.test_drive_start_time) {
        UpdateTestScenariosHistoryTestDriveStart({
          variables: {
            id: state.historyId
          }
        })
          .then((res) =>
            console.log(
              "Successfully updated the test drive start date to: ",
              res
            )
          )
          .catch((err) =>
            console.error(
              "Error updating the start date of the test scenario history",
              err
            )
          )
      }
    }
  }, [getQueryHistoryData])

  const sendTestScenarioHistoryDialogMessageUpdateMutation = async (
    historyCopy = undefined
  ) => {
    const dialogJson = JSON.stringify(historyCopy ?? dialogHistoryRef.current)

    const queryObj = {
      variables: {
        workflowId: EVA_WORKFLOW_ID,
        workflowExecutionId: currentWorkflowExecutionId.current,
        dialogJson
      }
    }

    const resp = await UpdateTestScenariosHistoryDialogsMessages(
      queryObj
    ).catch((error) =>
      console.error(
        "Error updating test scenarios history dialog messages ",
        error
      )
    )
  }

  const saveDialogMessageToHistory = async (msgText, senderName) => {
    let senderObj = {}
    if (senderName === "susi") {
      senderObj = {
        name: senderName,
        userId: "",
        userName: "",
        userType: "",
        firstName: "",
        lastName: ""
      }
    } else {
      senderObj = {
        name: senderName,
        userId: state.user_id,
        userType: state.user_type,
        userName: state.user_name,
        firstName: state.user_first_name,
        lastName: state.user_last_name
      }
    }

    const msg = {
      message: msgText,
      sender: senderObj,
      datetime: new Date().toISOString()
    }
    dialogHistoryRef.current.push(msg)
    console.log("Adding message to dialog history array: ", msg)
    console.log("Current dialog history is ", dialogHistoryRef.current)

    await sendTestScenarioHistoryDialogMessageUpdateMutation()
  }

  const {
    keywordDetection,
    isLoaded: isWakeWordEngineLoaded,
    isListening: isWakeWordEngineListening,
    error: wakeWordEngineError,
    init: initWakeWordEngine,
    start: startWakeWordEngine,
    stop: stopWakeWordEngine,
    release: releaseWakeWordEngine
  } = useKeywordRecognizer()

  const {
    error: speechRecognitionError,
    interimResult: speechRecognitionInterimResult,
    isRecording: speechRecognitionIsRecording,
    results: speechRecognitionResults,
    startSpeechToText,
    stopSpeechToText,
    setResults
  } = useSpeechToText(sttConfig)

  const triggerOptions = {
    onOpen(e) {
      console.debug("Connection to trigger endpoint established.")
    },
    onClose(e) {
      console.debug("Connection to trigger endpoint closed.")
    },
    onMessage(e) {
      console.debug("Received forwared trigger from backend.")
    },
    onError(e) {
      console.error("Connection to trigger endpoint failed!", e)
    },
    shouldReconnect(closeEvent) {
      return isComponentMounted.current === false
    },
    reconnectAttempts: 10,
    reconnectInterval: 3000
  }

  const {
    sendMessage: sendTriggerMessage,
    lastMessage: lastTriggerMessage,
    readyState: triggerReadyState
  } = useWebSocket(SWUI_BE_API_WS_TRIGGER_URL, triggerOptions)

  const handleTriggerAudioEnd = (e) => {
    isTriggerAudioPlayingRef.current = false
    if (wasAudioBookPlaying.current) {
      playAudio(audioBookRef, isAudioBookPlayingRef, stopSpeechToText)
    }
    wasAudioBookPlaying.current = false
  }

  const handleMicrophoneClick = () => {
    const newMicrophoneColor =
      microphoneColor === "#FABA15" ? "#495057" : "#FABA15"
    setMicrophoneColor(newMicrophoneColor)
    if (newMicrophoneColor === "#FABA15") {
      isMicrophoneListening.current = false
    } else {
      isMicrophoneListening.current = true
    }
  }

  const handleNext = () => {
    navigate("/drive/3/0")
  }

  const handleStopDrive = async () => {
    setIsDrive(false)
    if (isAudioBookPlayingRef.current) {
      pauseAudio(audioBookRef, isAudioBookPlayingRef)
      wasAudioBookPlaying.current = true
    } else {
      wasAudioBookPlaying.current = false
    }
  }

  const handleStartDrive = () => {
    setIsDrive(true)
    if (wasAudioBookPlaying.current) {
      if (audioBookRef.current) {
        const playPromise = audioBookRef.current.play()
        if (playPromise) {
          playPromise
            .then((audioRes) => {
              isAudioBookPlayingRef.current = true
            })
            .catch((err) => {
              console.error(err)
              isAudioBookPlayingRef.current = false
            })
        }
      }
    }
  }

  const handleBookClick = (e) => {
    e.preventDefault()
    const {
      currentTarget: {id}
    } = e
    if (id) {
      const idNumeric = parseInt(id, 10)
      const newState = audioBooksMeta.map((obj) => {
        if (obj.id === idNumeric && obj.isActive === true) {
          /* eslint-disable no-param-reassign */
          obj.isActive = false
          pauseAudio(audioBookRef, isAudioBookPlayingRef)
          audioBookRef.current = audioBooks[id]
          audioBookRef.current.currentTime = 0
          pauseAudio(audioBookRef, isAudioBookPlayingRef)
          if (wasAudioBookPlaying.current) {
            wasAudioBookPlaying.current = false
          }
        } else if (obj.id === idNumeric) {
          /* eslint-disable no-param-reassign */
          obj.isActive = true
          pauseAudio(audioBookRef, isAudioBookPlayingRef)
          audioBookRef.current = audioBooks[id]
          audioBookRef.current.currentTime = 0
          playAudio(audioBookRef, isAudioBookPlayingRef, stopSpeechToText)
        } else {
          obj.isActive = false
        }
        return obj
      })
      setAudioBooksMeta(newState)
    }
  }

  const sanitizeTranscription = (transcription) =>
    transcription ? transcription.replace(/sex/gi, "sechs") : transcription

  const extractMiscFromMsg = (msg) => {
    const matches = [
      ...msg.matchAll(
        /null|eins|zwei|drei|vier|fünf|sechs|sieben|acht|neun|zehn|10|[0-9]/gim
      )
    ]
    if (matches.length === 0) {
      return undefined
    }
    const str2Number = {
      null: 0,
      eins: 1,
      zwei: 2,
      drei: 3,
      vier: 4,
      fünf: 5,
      sechs: 6,
      sieben: 7,
      acht: 8,
      neun: 9,
      zehn: 10
    }

    const lastMatch = matches[matches.length - 1]
    return str2Number[lastMatch] ?? parseInt(lastMatch, 10)
  }

  const saveMiscValueFromTranscription = (miscNumber) =>
    new Promise((res, rej) => {
      if (!currentWorkflowExecutionId.current) {
        const msg =
          "The workflow execution id is not defined. Unable to save transcription"
        console.error(msg)
        rej(msg)
      }

      if (miscNumber === null || miscNumber === undefined) {
        const msg = `The MISC number ${miscNumber} is null or undefined and cannot be saved to the database. Ignoring`
        rej(msg)
      }

      const queryObj = {
        variables: {
          workflowId: EVA_WORKFLOW_ID,
          workflowExecutionId: currentWorkflowExecutionId.current,
          miscNumber,
          miscTimestamp: new Date().toISOString()
        }
      }

      console.debug("Saving MISC value ...", queryObj)

      const queryResponse = UpdateTestScenariosHistoryDialogsMisc(queryObj)
        .then((answer) => {
          res(answer)
        })
        .catch((err) => {
          console.error("Error inserting new MISC value", err)
          rej(err)
        })
    })

  const saveEvaWorkflow = () =>
    new Promise((res, rej) => {
      const workflowExecutionId = currentWorkflowExecutionId.current

      if (!workflowExecutionId) {
        const msg =
          "The workflow execution id is not defined. Unable to start dialog"
        console.error(msg)
        rej(msg)
      }

      const queryObj = {
        variables: {
          testScenarioHistoryId: currentTestScenarioHistoryId,
          workflowId: EVA_WORKFLOW_ID,
          workflowExecutionId
        }
      }

      // Create (insert) dialog history entry in 'test_scenarios_history_dialogs'
      const queryResponse = UpdateTestScenariosHistoryDialogs(queryObj)
        .then((answer) => {
          console.debug("Saved dialog history with: ", queryObj)
          res(answer)
        })
        .catch((err) => {
          console.error("Error inserting new workflow execution id", err)
          rej(err)
        })
    })

  const continueDialog = () => {
    startSpeechToText()
      .then((res) => {
        console.log("Starting to listen for a speech to text during dialog")
      })
      .catch((err) => {
        console.error(
          "Error starting speech to text transcription during dialog: ",
          err
        )
      })
  }

  const connectSpeechWebsocket = (sessionUUID = "undefinedonpurpose") => {
    const url = `${EVA_API_URL.replace(
      /api$/,
      ""
    )}?eva_workflow_id=${EVA_WORKFLOW_ID}&session_uuid=${sessionUUID}`

    const wsEngine = process.env.NODE_ENV === "production" ? "wss" : "ws"
    let socket = null
    try {
      socket = io(url, {wsEngine})
    } catch (e) {
      console.error("Failed to connect via Websocket with EVA")
    }

    return socket
  }

  const initDialog = () => {
    currentWorkflowExecutionId.current = lastWorkflowExecutionId.current
    console.debug(`Initializing dialog 
        with workflow_execution_id '${currentWorkflowExecutionId.current}' ...`)
    saveEvaWorkflow()
    setIsDialogInProgress(true)
    console.debug("Dialog successfully initialized.")
  }

  const restartWebsocketConnection = () => {
    console.debug("Restarting websocket connection ...")
    console.debug("Stopping speech-to-text recognition ...")
    stopSpeechToText()

    dialogHistoryRef.current = []
    currentWorkflowExecutionId.current = null
    evaAudioRef.current = null
    isEndOfSpeechMsgRef.current = false
    isUserDialogInProgress.current = false
    lastWorkflowExecutionId.current = null
    currentWorkflowExecutionId.current = null
    wasLastQuestionMiscRef.current = false
    isAudioBookPlayingRef.current = false
    isTriggerAudioPlayingRef.current = false

    if (speechSocket) {
      speechSocket.disconnect()
      setSpeechSocket(null)
      console.warn("Websocket connection destroyed!")
    }

    const socket = connectSpeechWebsocket()
    setSpeechSocket(socket)
    console.debug("Websocket reconnected.")
  }

  const endDialog = ({
    isError = false,
    errorMsg = "",
    isWarning = false,
    warningMsg = ""
  } = {}) => {
    console.debug("Ending current dialog ...")

    if (wasAudioBookPlaying.current) {
      playAudio(audioBookRef, isAudioBookPlayingRef, stopSpeechToText)
      wasAudioBookPlaying.current = false
    }

    if (isWarning) {
      console.warn(warningMsg)
      message.warning(warningMsg, 10)
      setIsDialogError(false)
    }

    if (isError) {
      console.error(errorMsg)
      message.error(errorMsg, 10)
      setIsDialogError(true)
    }

    startWakeWordEngine()

    const historyCopy = JSON.parse(JSON.stringify(dialogHistoryRef.current))

    // This function is asynchronous.
    // This is done on purpose to update the dialog history in the BE while symultaneously non-blocking
    // the dialog execution, which would happen if we would 'await' or '.then' the line below
    sendTestScenarioHistoryDialogMessageUpdateMutation(historyCopy)

    restartWebsocketConnection()
    setResults([])
    console.debug("Dialog ended.")
  }

  const handleEvaAudioEnd = () => {
    if (speechQueueRef.current.length > 0) {
      speechQueueRef.current = speechQueueRef.current.filter((s, i) => i !== 0)
      setSpeechQueue(speechQueueRef.current)

      if (isEndOfSpeechMsgRef.current) {
        console.debug("EndOfSpeech recognized - stopping dialog ...")
        endDialog()
      } else {
        console.debug("EndOfSpeech not recognized - continue dialog ...")
        continueDialog()
      }
    }
  }

  const handleAudiobookAudioEnd = (e) => {
    const newState = audioBooksMeta.map((obj) => {
      /* eslint-disable no-param-reassign */
      obj.isActive = false
      return obj
    })
    setAudioBooksMeta(newState)
    isAudioBookPlayingRef.current = false
  }

  /**
   * Replaces current timeout and cancels the old timeout beforehand.
   */
  const setCurrentTimeoutTimerId = (timeoutId) => {
    if (currentTimeoutTimerId.current) {
      clearTimeout(currentTimeoutTimerId.current)
      console.debug("Timeout timer cleared.")
    }
    currentTimeoutTimerId.current = timeoutId
    console.debug("New timeout timer set.")
  }

  /**
   * Cancels (clears) the last timeout timer which was set.
   */
  const clearCurrentTimeoutTimerId = () => {
    if (currentTimeoutTimerId.current) {
      clearTimeout(currentTimeoutTimerId.current)
    }
    currentTimeoutTimerId.current = null
    console.debug("Timeout timer cleared.")
  }

  useEffect(() => {
    console.debug("Dialog error state changed: ", isDialogError)

    clearCurrentTimeoutTimerId()
    if (isDialogError) {
      // Note this timeout is not refering to cancel dialogs directly.
      const timeout = setTimeout(() => {
        if (
          isWakeWordEngineLoaded &&
          isWakeWordEngineListening &&
          isSpeechWebsocketReady
        ) {
          setIsDialogError(false)
        }
      }, 3 * 1000)
    }
  }, [isDialogError])

  useEffect(() => {
    if (speechRecognitionIsRecording) {
      const nSecs = 20
      const origQueue = dialogHistoryRef.current.slice()
      const timeoutId = setTimeout(() => {
        const areQueuesEqual =
          JSON.stringify(origQueue) === JSON.stringify(dialogHistoryRef.current)
        if (
          speechRecognitionIsRecording &&
          areQueuesEqual &&
          isUserDialogInProgress.current
        ) {
          endDialog({
            isWarning: true,
            warningMsg: `Der Dialog wurde beendet, weil ${nSecs} lang keine Spracherkennungsnachricht empfangen würde. Bitte sagen Sie noch einmal "Hey Susi" oder "Hey Karli".`
          })
        }
      }, nSecs * 1000)
      console.debug(
        "Created timeout timer after 'speechRecognitionIsRecording'."
      )
      setCurrentTimeoutTimerId(timeoutId)
    }
  }, [speechRecognitionIsRecording])

  useEffect(() => {
    if (isEvaWebsocketResponseWaiting) {
      const nSecs = 20
      const origQueue = dialogHistoryRef.current.slice()
      const timeoutId = setTimeout(() => {
        const areQueuesEqual =
          JSON.stringify(origQueue) === JSON.stringify(dialogHistoryRef.current)
        if (
          isEvaWebsocketResponseWaiting &&
          areQueuesEqual &&
          isUserDialogInProgress.current
        ) {
          endDialog({
            isError: true,
            errorMsg: `Der Dialog wurde beendet, weil mehr als ${nSecs} Sekunden lang keine Antwort von Susi erhalten wurde. Bitte sagen Sie noch einmal "Hey Susi" oder "Hey Karli".`
          })
        }
      }, nSecs * 1000)
      console.debug(
        "Created timeout timer after 'isEvaWebsocketResponseWaiting'."
      )
      setCurrentTimeoutTimerId(timeoutId)
    }
  }, [isEvaWebsocketResponseWaiting])

  /**
   * Handle incoming AI responses.
   */
  useEffect(() => {
    const nMessages = speechQueueRef.current.length
    if (nMessages > 0) {
      const eosResponses = [
        "Aufgrund eines technischen Problems werde ich das Gespräch nun beenden.",
        "Ich werde das Gespräch beenden.",
        "Eingabe erfolgreich!",
        "Bitte fahren Sie rechts ran und unterbrechen Sie die Fahrt!",
        "Da Sie die Eingabe weder bestätigt, noch verneint haben, beende ich nun das Gespräch. Sie können mich jederzeit erneut kontaktieren.",
        "Die Eingabe war erfolgreich, danke.",
        "Misery Scale Wert größer 5 erkannt. Bitte fahren Sie rechts ran und unterbrechen Sie die Fahrt!"
      ]

      const errorResponses = ["Sorry, an issue occured please contact support!"]

      const miscResponses = [
        "Bitte Misery Scale beantworten! Bitte Werte angeben!",
        "Bitte geben Sie einen Misery Scale Wert an. Bitte geben Sie nun einen Misery Scale Wert an!",
        "Bitte Werte angeben!",
        "Ich habe Sie nicht verstanden. Bitte Werte angeben!",
        "Leider habe ich Sie immer noch nicht verstanden. Die Werte der Misery Scale sehen Sie im Auto. Bitte Werte angeben!",
        "Bitte geben Sie einen Misery Scale Wert an.",
        "Bitte geben Sie nun einen Misery Scale Wert an!",
        "Ich konnte leider keinen gültigen Wert erkennen.",
        "Leider habe ich Sie immer noch nicht richtig verstanden. Die Werte der Misery Scale sehen Sie im Auto.",
        "Ich konnte leider keinen gültigen Wert erkennen. Bitte geben Sie nun einen Misery Scale Wert an!",
        "Leider habe ich Sie immer noch nicht richtig verstanden. Die Werte der Misery Scale sehen Sie im Auto. Bitte geben Sie nun einen Misery Scale Wert an!"
      ]

      const lastMessage = speechQueueRef.current[nMessages - 1].trim()

      if (!lastMessage) {
        console.error(
          "The message ",
          "'" + lastMessage + "'",
          " is not defined",
          lastMessage
        )
        return
      }

      if (!evaAudiosObj[lastMessage]) {
        console.error(
          "The message ",
          "'" + lastMessage + "'",
          " does not exist in the audios object ",
          evaAudiosObj
        )
        return
      }

      const isErrorMsg = !!errorResponses.find((r) => r === lastMessage)

      if (isErrorMsg) {
        endDialog({
          isError: true,
          errorMsg:
            "In unserem EVA-Server ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut, indem Sie 'Hey Susi' oder 'Hey Karli' sagen."
        })
      }

      wasLastQuestionMiscRef.current = !!miscResponses.find(
        (r) => r === lastMessage
      )

      isEndOfSpeechMsgRef.current = !!eosResponses.find(
        (r) => r === lastMessage
      )

      evaAudioRef.current = evaAudios[lastMessage]
      evaAudioRef.current.currentTime = 0
      playAudio(evaAudioRef, isEvaAudioPlayingRef, stopSpeechToText)
    } else {
      console.log(
        "Ignoring queue event with queue: ",
        speechQueueRef.current,
        " because the queue is empty"
      )
    }
  }, [speechQueue])

  useEffect(() => {
    ;(async () => {
      try {
        console.debug("Received STT result: ", speechRecognitionResults)

        if (!currentWorkflowExecutionId.current) {
          console.debug(
            "Speech recognition input is not being processed because there is no current workflow execution id."
          )
          return
        }

        if (!isMicrophoneListening.current) {
          console.warn(
            "Speech recognition input is not being processed because the microphone is muted. Exitting"
          )
          isUserDialogInProgress.current = false
          setIsDialogInProgress(false)
          setIsEvaWebsocketResponseWaiting(false)
          return
        }

        const resultsLength = speechRecognitionResults.length
        if (resultsLength <= 0) {
          console.warn(
            "Speech recognition array has no results",
            speechRecognitionResults
          )
          isUserDialogInProgress.current = false
          setIsDialogInProgress(false)
          setIsEvaWebsocketResponseWaiting(false)
          return
        }

        const transcription = sanitizeTranscription(
          speechRecognitionResults[resultsLength - 1]?.transcript
        )

        /* eslint-disable no-undef */
        if (!transcription || transcription === "null") {
          console.error(
            "Transcription: ",
            transcription,
            " not processable. Ignoring"
          )
          isUserDialogInProgress.current = false
          setIsDialogInProgress(false)
          setIsEvaWebsocketResponseWaiting(false)
          return
        }

        if (!transcription?.length) {
          console.error(
            "Transcription length too short. Cancelling transcription request"
          )
          isUserDialogInProgress.current = false
          setIsDialogInProgress(false)
          setIsEvaWebsocketResponseWaiting(false)
          return
        }

        if (isEvaWebsocketResponseWaiting) {
          console.warn(
            "Ignoring transcription",
            transcription,
            "a speech response is already being processed"
          )
          return
        }

        if (speechSocket) {
          console.log(
            "Sending transcription: ",
            transcription,
            "to websocket: ",
            speechSocket
          )
          stopSpeechToText()
          speechSocket.emit("message", transcription)
          saveDialogMessageToHistory(transcription, "user")
          setIsEvaWebsocketResponseWaiting(true)
          if (wasLastQuestionMiscRef.current) {
            const miscNumber = extractMiscFromMsg(transcription)
            saveMiscValueFromTranscription(miscNumber)
              .then((res) =>
                console.log("MISC value was saved successfully", res)
              )
              .catch((err) => console.error("Error saving MISC value", err))
          }
        }
      } catch (err) {
        console.error("An error ocurred while processing transcription: ", err)
        isUserDialogInProgress.current = false
        setIsEvaWebsocketResponseWaiting(false)
        setIsDialogInProgress(false)
      }
    })()
  }, [speechRecognitionResults])

  const handleDialogManualStart = () => {
    console.debug("Triggered manual dialog start.")

    if (isUserDialogInProgress.current) {
      console.warn("User dialog in progress - stop first!")
      return
    }

    if (speechSocket) {
      initDialog()
      const msg = "Hey Susi"
      speechSocket.emit("message", msg)
      saveDialogMessageToHistory(msg, "user")
      setIsEvaWebsocketResponseWaiting(true)
      isUserDialogInProgress.current = true

      if (isAudioBookPlayingRef.current) {
        wasAudioBookPlaying.current = true
        pauseAudio(audioBookRef, isAudioBookPlayingRef)
      }
    } else {
      console.error(
        "Ignoring keyword ",
        keywordDetection,
        " because there is no speech websocket configured yet"
      )
      if (wasAudioBookPlaying.current) {
        playAudio(audioBookRef, isAudioBookPlayingRef, stopSpeechToText)
        wasAudioBookPlaying.current = false
      }
    }
  }

  useEffect(() => {
    if (!keywordDetection) {
      return
    }

    if (!isMicrophoneListening.current) {
      console.warn(
        `Ignoring keyword: ${keywordDetection} because microphone is muted`
      )
      return
    }

    const isKeywordValid = !!keywordModel.find(
      (c) => c.label === keywordDetection?.label
    )
    if (!isKeywordValid) {
      console.error(
        `Ignoring keyword. Unknown keyword was used: ${keywordDetection}`
      )
      return
    }

    if (isUserDialogInProgress.current) {
      console.warn(
        "User dialog in progress. Keyword: ",
        keywordDetection,
        " ignored"
      )
      return
    }

    if (speechSocket) {
      stopWakeWordEngine()
      initDialog()
      const msg =
        keywordDetection?.label === "Hey Susi (German)"
          ? "Hey Susi"
          : "Hey Karli"
      speechSocket.emit("message", msg)
      saveDialogMessageToHistory(msg, "user")
      setIsEvaWebsocketResponseWaiting(true)
      isUserDialogInProgress.current = true
      if (isAudioBookPlayingRef.current) {
        wasAudioBookPlaying.current = true
        pauseAudio(audioBookRef, isAudioBookPlayingRef)
      }
    } else {
      console.error(
        "Ignoring keyword ",
        keywordDetection,
        " because there is no speech websocket configured yet"
      )
      if (wasAudioBookPlaying.current) {
        playAudio(audioBookRef, isAudioBookPlayingRef, stopSpeechToText)
        wasAudioBookPlaying.current = false
      }
    }
  }, [keywordDetection])

  const clearReconnectTimeout = () => {
    if (currentWebsocketReconnectTimeoutId.current) {
      clearTimeout(currentWebsocketReconnectTimeoutId.current)
    }
  }

  useEffect(() => {
    if (speechSocket) {
      speechSocket.on("connect", (socket) => {
        console.debug("Connection to EVA BE established.")
        clearReconnectTimeout()
        setIsSpeechWebsocketReady(true)
      })

      speechSocket.on("connect_error", (event) => {
        // On connection errors, try to restart websocket connection.
        console.error("Connection to EVA BE failed", event)
        setIsSpeechWebsocketReady(false)
        clearReconnectTimeout()
        const reconnectTimeout = setTimeout(() => {
          console.warn(
            "Trying to restart websocket connection after reconnect timeout!"
          )
          restartWebsocketConnection()
        }, 3 * 1000)

        currentWebsocketReconnectTimeoutId.current = reconnectTimeout
      })

      speechSocket.on("init", (data) => {
        clearReconnectTimeout()
        const parsedData = JSON.parse(data)

        if (parsedData?.init === true) {
          if (!parsedData?.workflow_execution_id) {
            throw new Error(
              "No workflow execution id was sent by eva to initialize this dialog"
            )
          }

          lastWorkflowExecutionId.current = parsedData.workflow_execution_id
          setIsSpeechWebsocketReady(true)
        } else {
          console.error("Error initializing websocket", parsedData)
          setIsSpeechWebsocketReady(false)
        }
      })

      speechSocket.on("unlock", (data) => {
        const parsedData = JSON.parse(data)
        if (parsedData.unlock === true) {
          console.debug(
            "Received 'unlock' event - EVA ready to accept user input."
          )
          setIsSpeechWebsocketReady(true)
        } else {
          console.error(
            "The unlock websocket connection is locked. Response is: ",
            parsedData
          )
          setIsSpeechWebsocketReady(false)
        }
      })

      speechSocket.on("message", (data) => {
        const parsedData = JSON.parse(data)

        const msg = parsedData.reduce((prev, cur) => {
          const concat = prev + cur.text
          return concat
        }, "")

        const responseSpeechContext = parsedData.reduce(
          (prev, cur) => cur?.speech_context || prev,
          null
        )

        const cleanedUpSpeechContext = responseSpeechContext?.phrases?.filter(
          (el) => typeof el === "string"
        )

        console.debug("Received AI text from eva: ", msg)

        const localSttConfig = DEFAULT_STT_REQUEST_CONFIG

        if (responseSpeechContext) {
          responseSpeechContext.phrases = cleanedUpSpeechContext
          localSttConfig.googleCloudRecognitionConfig.speechContexts = [
            responseSpeechContext
          ]
          console.debug(
            "Set specific STT speechContext ",
            responseSpeechContext
          )
        } else {
          console.debug("No STT speechContexts provided.")
        }

        setNextSpeechContext(() => localSttConfig)

        if (!msg) {
          console.error(
            "Response from eva",
            parsedData,
            "does not have a text property. The message received is",
            msg
          )
        }

        speechQueueRef.current = speechQueueRef.current.concat(msg)
        setSpeechQueue(speechQueueRef.current)
        saveDialogMessageToHistory(msg, "susi")
        setIsEvaWebsocketResponseWaiting(false)
        setIsDialogError(false)
      })
    }
  }, [speechSocket])

  /**
   * This effect will initialize driving screen, as soon as this component
   * is mountent. Please note that due to a component rendering issue
   * it's required to initialize wakeword engine only once, by using
   * a timeout (currently not possible based on state or refs).
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      initWakeWordEngine(PICOVOICE_ACCESS_KEY, keywordModel, porcupineModel)
        .then((initRes) => {
          startWakeWordEngine()
            .then((startRes) =>
              console.debug("Driving-Screen started listening for keywords ...")
            )
            .catch((error) =>
              console.error(
                "Driving-Screen failed to initialize KeywordRecognizer!",
                error
              )
            )
        })
        .catch((error) =>
          console.error(
            "Driving-Screen failed to initialize KeywordRecognizer!",
            error
          )
        )
    }, 500)

    return () => {
      clearTimeout(timeoutId)
      releaseWakeWordEngine()
    }
  }, [])

  useEffect(() => {
    Object.entries(triggerAudios).forEach(([key, obj]) => {
      obj.addEventListener("ended", handleTriggerAudioEnd)
    })
    audioBooks.map((obj) =>
      obj.addEventListener("ended", handleAudiobookAudioEnd)
    )
    Object.values(evaAudios).map((obj) =>
      obj.addEventListener("ended", handleEvaAudioEnd)
    )

    const socket = connectSpeechWebsocket()
    setSpeechSocket(socket)

    return () => {
      Object.entries(triggerAudios).forEach(([key, obj]) => {
        obj.removeEventListener("ended", handleTriggerAudioEnd)
      })

      audioBooks.map((obj) =>
        obj.removeEventListener("ended", handleAudiobookAudioEnd)
      )
      Object.values(evaAudios).map((obj) =>
        obj.removeEventListener("ended", handleEvaAudioEnd)
      )

      if (state.historyId) {
        UpdateTestScenariosHistoryTestDriveEnd({
          variables: {
            id: state.historyId
          }
        })
          .then((res) =>
            console.log(
              "Successfully updated the test drive end date to: ",
              res
            )
          )
          .catch((err) =>
            console.error(
              "Error updating the end date of the test scenario history",
              err
            )
          )
      }

      wasAudioBookPlaying.current = false
      isUserDialogInProgress.current = false
      isComponentMounted.current = false
      isEndOfSpeechMsgRef.current = false
      dialogHistoryRef.current = []
      pauseAudio(evaAudioRef, isEvaAudioPlayingRef)
      pauseAudio(triggerAudioRef, isTriggerAudioPlayingRef)
      pauseAudio(audioBookRef, isAudioBookPlayingRef)
      evaAudioRef.current = null
      triggerAudioRef.current = null
      audioBookRef.current = null

      if (speechSocket) {
        speechSocket.disconnect()
        console.warn(
          "Disconnecting speech socket: ",
          speechSocket,
          "because component is being unmounted"
        )
      }

      if (speechRecognitionIsRecording) {
        stopSpeechToText()
      }
    }
  }, [])

  /**
   * Handles incoming triggers, which are send to backend endpoint '/api/ws_trigger'
   * and forwarded to connected UI clients. This handler is executed, if
   * a new trigger was received and set in 'lastTriggerMessage'.
   */
  useEffect(() => {
    ;(async () => {
      if (!lastTriggerMessage?.data) {
        console.debug(
          "Ignoring event: ",
          lastTriggerMessage,
          " because no data was received"
        )
        return
      }

      const {data} = lastTriggerMessage

      if (isUserDialogInProgress.current) {
        console.debug(
          "Ignoring event: ",
          data,
          " because of dialog in progress"
        )
        return
      }

      if (isTriggerAudioPlayingRef.current) {
        console.debug(
          "Ignoring event: ",
          data,
          " because there is another trigger event reproduction in progress"
        )
        return
      }

      if (!data) {
        console.debug(
          "Data is not defined with value: ",
          data,
          ". Skipping playing audio trigger"
        )
        return
      }

      if (triggerAudiosObj[data]) {
        triggerAudioRef.current = triggerAudios[data]
        playAudio(triggerAudioRef, isTriggerAudioPlayingRef, stopSpeechToText)
        return
      }

      if (data === "Trigger_MISC_Counter") {
        if (!isMicrophoneListening.current) {
          handleMicrophoneClick()
        }

        try {
          if (speechSocket) {
            initDialog()
            const msg = "MISC Trigger"
            speechSocket.emit("message", msg)
            isUserDialogInProgress.current = true
            saveDialogMessageToHistory(msg, "user")
            setIsEvaWebsocketResponseWaiting(true)
            if (isAudioBookPlayingRef.current) {
              pauseAudio(audioBookRef, isAudioBookPlayingRef)
              wasAudioBookPlaying.current = true
            }
          }
        } catch (err) {
          console.error(
            "An error ocurred while processing transcription: ",
            err
          )
          isUserDialogInProgress.current = false
          setIsDialogInProgress(false)
          setIsEvaWebsocketResponseWaiting(false)
          if (wasAudioBookPlaying.current) {
            playAudio(audioBookRef, isAudioBookPlayingRef, stopSpeechToText)
            wasAudioBookPlaying.current = false
          }
          console.error(
            "Error: the key in the data of event ",
            data,
            " does NOT exist in the object",
            triggerAudiosObj,
            "and it is not Trigger_MISC_Counter"
          )
        }
      }
    })()
  }, [lastTriggerMessage])

  return (
    <Styled.MainBox>
      <Styled.InsideBox>
        <Styled.SusiBox>
          <Styled.SusiImgBox>
            <Styled.SusiColorRing
              isListening={
                triggerReadyState === ReadyState.OPEN &&
                isWakeWordEngineListening &&
                isSpeechWebsocketReady
              }
              isWaitingResponse={isEvaWebsocketResponseWaiting}
              isSpeechRecognitionRecording={speechRecognitionIsRecording}
              isError={isDialogError}
              isSpeaking={evaAudioRef.current}
            >
              <Styled.SusiImg src={susi_rund} />
            </Styled.SusiColorRing>

            {process.env.REACT_APP_NO_WAKEWORD && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  margin: "20px 0"
                }}
              >
                <Button
                  style={{marginRight: "10px"}}
                  onClick={() => handleDialogManualStart()}
                >
                  Dialog starten
                </Button>

                <Button
                  onClick={() => {
                    endDialog({
                      isWarning: true,
                      warningMsg: "Der Dialog wurde manuell beendet!"
                    })
                    stopSpeechToText()
                    evaAudioRef.current = null
                  }}
                >
                  Dialog stoppen
                </Button>
              </div>
            )}
          </Styled.SusiImgBox>
          <Styled.SusiColorStatus>
            {triggerReadyState === ReadyState.OPEN &&
              isWakeWordEngineListening &&
              isSpeechWebsocketReady &&
              t("susi.status.ready")}
            {speechRecognitionIsRecording &&
              t("susi.status.speechRecognitionIsRecording")}
            {isEvaWebsocketResponseWaiting &&
              t("susi.status.isEvaWebsocketResponseWaiting")}
            {isDialogError && t("susi.status.error")}
            {!(
              (triggerReadyState === ReadyState.OPEN &&
                isWakeWordEngineListening &&
                isSpeechWebsocketReady) ||
              speechRecognitionIsRecording ||
              isEvaWebsocketResponseWaiting ||
              isDialogError
            ) && t("susi.status.error")}
          </Styled.SusiColorStatus>

          <Styled.SusiAudioBookControls>
            {audioBooksMeta.map((obj, idx) => (
              <Styled.SusiAudioBookAndDescription
                active={obj.isActive}
                key={obj.id}
                id={obj.id}
                onClick={handleBookClick}
              >
                <Styled.SusiBookOutlined />
                <Styled.SusiAudioBookDescription>
                  {`Audiobuch ${obj.id + 1}`}
                </Styled.SusiAudioBookDescription>
              </Styled.SusiAudioBookAndDescription>
            ))}
          </Styled.SusiAudioBookControls>
          <Styled.SusiAlign>
            <Styled.SusiImgGroup>
              <Styled.SusiMicroBox onClick={handleMicrophoneClick}>
                <Styled.SusiMicroImg fill={microphoneColor} />
                {!isMicrophoneListening.current && (
                  <Styled.MicrophoneImgDescription>
                    Gerade hört Susi nicht
                  </Styled.MicrophoneImgDescription>
                )}
              </Styled.SusiMicroBox>

              {isDrive && (
                <Styled.SusiCarBox>
                  <Styled.SusiCarImg onClick={handleStopDrive} />

                  <Styled.SusiCarImgDescription>
                    Fahrt pausieren
                  </Styled.SusiCarImgDescription>
                </Styled.SusiCarBox>
              )}

              {!isDrive && (
                <Styled.SusiCarPlayBox>
                  <Styled.SusiCarPlayImg
                    fill="#495057"
                    onClick={handleStartDrive}
                  />
                  <Styled.SusiCarImgPlayDescription>
                    Weiterfahren
                  </Styled.SusiCarImgPlayDescription>
                </Styled.SusiCarPlayBox>
              )}

              {!isDrive && (
                <Styled.SusiNextBox onClick={handleNext}>
                  <Styled.SusiNextImg fill="#495057" />
                </Styled.SusiNextBox>
              )}
            </Styled.SusiImgGroup>
          </Styled.SusiAlign>
        </Styled.SusiBox>
      </Styled.InsideBox>
    </Styled.MainBox>
  )
}

export default SusiDriveScreen
