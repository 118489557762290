import {ConfigProvider, message} from "antd"
import React, {useState} from "react"
import axios from "axios"
import locale from "antd/es/locale/de_DE"
import moment from "moment"
import {Styled} from "./export-view.styled"
import "./index.css"
import {SWUI_BE_API_URL} from "../../../const/const"

moment.locale("de-DE", {
	monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
	weekdaysMin: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
  week: {
    dow: 1 /// Date offset
  }
})

const ExportView = () => {
  const [isExporting, setIsExporting] = useState(false)
  const [valueDataPicker, setValueDataPicker] = useState(null)
  const [startDate, setStartdate] = useState()
  const [endDate, setEnddate] = useState()

  const getDataForCSV = async () => {
    /* eslint-disable-next-line */
    await axios
      .post(
        `${SWUI_BE_API_URL}/api/export_data`,
        {
          start_date: startDate,
          end_date: endDate
        },
        {
          headers: {
            "Content-Type": "application/json"
          },
          responseType: "blob"
        }
      )
      .then(({data}) => {
        const href = URL.createObjectURL(data)

        // create "a" HTML element with href to file & click
        const link = document.createElement("a")
        link.href = href
        link.setAttribute("download", "file.csv")
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)

        setIsExporting(false)
        setValueDataPicker(null)

        return data
      })
      .catch((err) => {
        setIsExporting(false)
        setValueDataPicker(null)
        message.error("Fehler beim Herunterladen der CSV-Exportdatei")
      })
  }

  const handleExportData = async () => {
    if (isExporting || !valueDataPicker) return

    setIsExporting(true)

    await getDataForCSV()
  }

  const handleSetValueDataPicker = (value, dateString) => {
    setValueDataPicker(value)
    setStartdate(
      dateString[0].split(" ")[0] +
        "T" +
        dateString[0].split(" ")[1] +
        ".000+00:00"
    )
    setEnddate(
      dateString[1].split(" ")[0] +
        "T" +
        dateString[1].split(" ")[1] +
        ".000+00:00"
    )
  }

  return (
    <Styled.Box>
      <Styled.InsideBox>
        <Styled.ContentBox>
          <Styled.GroupTextPickerBox>
            <Styled.TextBox>
              Alle Daten innerhalb des Zeitraums als Excel-File exportieren
            </Styled.TextBox>
            <Styled.DatePickerBox>
              <ConfigProvider locale={locale}>
                <Styled.RangePickerStyle
    							placeholder={["Startdatum", "Enddatum"]}
                  id="RangePicker"
                  showTime
                  value={valueDataPicker}
                  onChange={handleSetValueDataPicker}
                />
              </ConfigProvider>
            </Styled.DatePickerBox>
          </Styled.GroupTextPickerBox>
          <Styled.ExportButtonBoxRelative>
            <Styled.ExportButtonBox
              id="exportButton"
              onClick={handleExportData}
              $isExporting={isExporting || !valueDataPicker}
            >
              Export (Excel)
            </Styled.ExportButtonBox>
            {isExporting && <Styled.SpinStyle size="middle" />}
          </Styled.ExportButtonBoxRelative>
        </Styled.ContentBox>
      </Styled.InsideBox>
    </Styled.Box>
  )
}

export default ExportView
