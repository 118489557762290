/* eslint-disable semi */
import {Row, Button, Tag, Input, Form, Radio, Select} from "antd";
import styled from "styled-components";
import {AgGridReact} from "ag-grid-react";

export const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #f8f8fb;
`

export const RightSideUserFormContainer = styled.div`
  width: 50%;

  background: #fff;
  padding: 1%;
`

export const LeftSideUserFormContainer = styled.div`
  width: 50%;
  background: #fff;
  padding: 1%;
`

export const UserRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`

export const CreateUserButton = styled(Button)`
  color: white;
  background: #f1b44c;
  border-radius: 4px;
  margin-left: 20px;
  margin-bottom: 10px;
  border-color: #f1b44c;
`

export const UserTableTwo = styled(AgGridReact)`
  &&& .ag-theme-alpine > * > .ag-row-selected {
    background-color: rgba(220, 53, 69, 0.3); /* red */
  }
  div {
    border: solid 0px;
  }
  --ag-borders: solid 0px !important;
  &&& {
    border: solid 0px;
  }
`

export const ScenarioTag = styled(Tag)`
  background-color: #fff;
  border-style: dashed;
`

export const StyledInput = styled(Input)`
  width: 100%;
`

export const StyledDropDown = styled(Select)`
  display: flex;
  justify-content: flex-end;
  width: 80%;
  background-color: crimson;
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selector {
    background-color: crimson;
    width: 80% !important;
  }
`

export const StyledDeleteButton = styled(Button)`
  width: 20px;
  height: 20px;
`

export const StyledTitle = styled.p`
  font-weight: bold;
`

export const StyledTable = styled(AgGridReact)`
  div.ag-header-cell-label {
    background-color: #f8f8fb;
    font-weight: bold;
  }
  div.ag-row-focus {
    background-color: #f8f8fb;
  }
  div.ag-row-selected {
    background-color: #f8f8fb;
  }
`

export const StyledForm = styled(Form)`
  border-radius: 4px;
  padding: 20px;
  padding-right: 50px;
  margin: 40px;

  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
`

export const StyledButtonDontClick = styled(Button)`
  background-color: white;
  color: #f1b44c;
  border-radius: 4px;
  border: 1px solid;
  margin-left: 20px;
  margin-bottom: 10px;
  border-color: #f1b44c;
`

export const LastRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`

export const StyledRadioButton = styled(Radio.Button)`
  color: black;
  border-radius: 4px;
  background-color: white;
`

export const GenderRadio = styled(Radio.Group)`
  .ant-radio-button-wrapper-checked {
    color: white;
  }
`

export const PaginationRow = styled(Row)`
  margin-top: -90px;
  display: flex;
  justify-content: flex-end;
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background-color: #d9d9d9 !important;
  }
`
export const PageDisplay = styled.div`
  padding: 0px 10px;
  gap: 10px;
  font-size: large;
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`

export const PaginationButton = styled(Button)`
  margin-left: 3px;
  margin-right: 3px;
  padding: 1px 7px;
  gap: 10px;
  background-color: #d9d9d9;
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
`
