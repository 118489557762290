import {formatDistanceStrict} from "date-fns"
import {de} from "date-fns/locale"

export const getYear = () => {
  const year = new Date().getFullYear()
  return year
}

export const cutText = (value, count) => {
  let text = ""
  if (value.length > count + 2) {
    text = `${value.slice(0, count)}...`
  } else text = value

  return text
}

export const getDayYear = () => {
  const year = new Date().toLocaleString("default", {month: "long"})
  const day = new Date().getUTCDate()
  return `${day}  ${year}`
}

export const getTime = () => {
  const now = new Date()
  const h = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours()
  const m = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
  return `${h}:${m}`
}

export const getTimeScenarioList = (timeBefore) => {
  const res = formatDistanceStrict(new Date(timeBefore), new Date(), {
    locale: de
  })

  if (res.includes("Tag")) {
    if (Number(res.split(" ")[0]) > 7)
      return `${Math.floor(Number(res.split(" ")[0]) / 7) + 1} Wochen`
  }

  return res
}

function isArrayEquals(prev, curr) {
  return (
    Array.isArray(prev) &&
    Array.isArray(curr) &&
    prev.length === curr.length &&
    prev.every((val, index) => val === curr[index])
  )
}

export const isEquelTwoObj = (prev, curr) => {
  const res =
    prev.plate !== curr.plate ||
    prev.name !== curr.name ||
    prev.type !== curr.type ||
    Number(prev.max_speed) !== Number(curr.max_speed) ||
    (prev.max_speed === undefined && curr.max_speed === undefined) ||
    prev.notes !== curr.notes ||
    !isArrayEquals(
      prev?.vehicles_driving_licensesValue?.sort(),
      curr?.vehicles_driving_licensesValue?.sort()
    ) ||
    prev.vehicle_manufacturersValue !== curr.vehicle_manufacturersValue

  return res
}

export const isValueObjChange = (prev, curr) => {
  const res =
    (prev?.plate !== curr?.plate && curr?.plate !== "") ||
    (prev?.name !== curr?.name && curr?.name !== "") ||
    prev?.type !== curr?.type ||
    (curr?.max_speed !== undefined && curr?.max_speed !== "") ||
    (prev?.notes !== curr?.notes && curr?.notes !== "") ||
    !isArrayEquals(
      prev?.vehicles_driving_licensesValue?.sort(),
      curr?.vehicles_driving_licensesValue?.sort()
    ) ||
    curr?.vehicle_manufacturersValue !== null

  return res
}

export const isFilledRequiredFields = (prev, curr) =>
  curr.plate !== undefined &&
  curr.plate !== "" &&
  curr.name !== undefined &&
  curr.name !== ""

export const getIdForLicenses = (value) =>
  value.vehicles_driving_licensesValue.map((item) => ({
    id: value.vehicles_driving_licenses.find(
      (itemFind) => itemFind.name === item
    ).id,
    name: item
  }))

export const formMissedQuestions = (allQuestions) =>
  allQuestions
    .map((questionItem) => {
      const {name} = questionItem
      const question = questionItem.questionnaires_questions.filter(
        (item) =>
          item.question.test_subjects_questions_answers.length === 0 &&
          item.question?.questions_type.name !== "post-ride-events" // filtered last temp question
      )
      return question.map((item, index) => ({
        question: name,
        questionText: item.question.question,
        counts: index === 0 ? question.length : 0
      }))
    })
    .reduce((prev, curr) => [...prev, ...curr], [])
    .map((itemArr, index) => ({
      key: index,
      ...itemArr
    }))

export const isFilledRequiredFieldsTestGroup = (prev, curr) =>
  curr.name !== undefined && curr.name !== ""

export const isEquelTwoObjTestGroup = (prev, curr) => {
  const res =
    prev.name !== curr.name ||
    prev.description !== curr.description ||
    curr?.selectedUserArr?.length !== 0

  return res
}

export const isValueObjChangeTestGroup = (prev, curr) => {
  const res =
    (prev?.name !== curr?.name && curr?.name !== "") ||
    (prev?.description !== curr?.description && curr?.description !== "") ||
    curr?.selectedUserArr?.length !== 0

  return res
}

export const getAge = (dateString) => {
  const today = new Date()
  const birthDate = new Date(dateString)

  return today.getFullYear() - birthDate.getFullYear()
}

export const makeSalt = (length) => {
  let result = ""
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
