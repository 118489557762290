import React, {useState, useEffect} from "react"
import pbkdf2 from "pbkdf2"
import {useQuery, useMutation} from "@apollo/client"
import {Form, message} from "antd"
import {UserWrapper, GroupBox, Relative, Dark} from "./styledComponents"
import UserForm from "./UserForm"
import UserList from "./UserList"
import TestGroup from "../../../components/test-groups"
import {
  getGenderMapping,
  getUserType,
  getDateOfBirth,
  getGenderIntegerMapping
} from "./helper"
import {
  GET_USERS,
  CREATE_USER,
  CREATE_DRIVER,
  DELETE_USER,
  DELETE_DRIVER,
  GET_DRIVERS,
  GET_TEST_SUBJECT,
  CREATE_TEST_SUBJECTS,
  DELETE_TEST_SUBJECTS,
  UPDATE_DRIVERS,
  UPDATE_TEST_SUBJECTS,
  UPDATE_USERS,
  UPDATE_DRIVERS_WITHOUT_PASSWORD,
  UPDATE_TEST_SUBJECTS_WITHOUT_PASSWORD,
  UPDATE_USERS_WITHOUT_PASSWORD
} from "./api"
import {makeSalt} from "../../../middleware/math"

const NewUsers = () => {
  const [userType, setUserType] = useState(null)
  const [formInstance] = Form.useForm()
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [selectedUserType, setSelectedUserType] = useState(null)
  const [updateGroups, setUpdateGroups] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // check if user no saved but admin choose other users from list
  const [isUserNoSavedState, setIsUserNoSaved] = useState(false)

  const {data: users} = useQuery(GET_USERS)
  const {data: drivers} = useQuery(GET_DRIVERS)
  const {data: test_subjects} = useQuery(GET_TEST_SUBJECT)

  const [create_user] = useMutation(CREATE_USER)
  const [create_test_subjects] = useMutation(CREATE_TEST_SUBJECTS)
  const [create_driver] = useMutation(CREATE_DRIVER)

  const [delete_driver] = useMutation(DELETE_DRIVER)
  const [delete_user] = useMutation(DELETE_USER)
  const [delete_test_subjects] = useMutation(DELETE_TEST_SUBJECTS)

  const [update_drivers] = useMutation(UPDATE_DRIVERS)
  const [update_test_subjects] = useMutation(UPDATE_TEST_SUBJECTS)
  const [update_users] = useMutation(UPDATE_USERS)

  const [update_drivers_without_password] = useMutation(
    UPDATE_DRIVERS_WITHOUT_PASSWORD
  )
  const [update_test_subjects_without_password] = useMutation(
    UPDATE_TEST_SUBJECTS_WITHOUT_PASSWORD
  )
  const [update_users_without_password] = useMutation(
    UPDATE_USERS_WITHOUT_PASSWORD
  )

  /* eslint-disable */

  const resetForm = () => {
    formInstance.resetFields()
    setSelectedUserId(null)
    setSelectedUserType(null)
    setUpdateGroups(!updateGroups)

    // check if user no save
    isUserNoSaved(false)
  }

  const handleSetIsloading = () => {
    setIsLoading(true)
  }
  const onFinish = (formValues, isReset) => {
    return new Promise((resolve, reject) => {
      const iteration = formValues.password !== undefined ? 260000 : 1

      const password =
        formValues.password !== undefined ? formValues.password : ""

      const salt = makeSalt(16)
      pbkdf2.pbkdf2(
        password,
        salt,
        iteration,
        32,
        "sha256",
        async (err, key) => {
          const encryptedPassword =
            "pbkdf2:sha256:260000$" + salt + "$" + key.toString("hex")

          const obj = {
            ...formValues,
            date_of_birth:
              formValues?.date_of_birth !== undefined &&
              formValues?.date_of_birth !== null
                ? formValues?.date_of_birth.format("YYYY-MM-DD")
                : null,
            password: encryptedPassword,
            first_name:
              formValues.first_name === undefined ? "" : formValues.first_name,
            last_name:
              formValues.last_name === undefined ? "" : formValues.last_name,
            gender:
              formValues.gender !== undefined
                ? `${getGenderIntegerMapping(formValues.gender)} `
                : null
          }

          try {
            // if there is no user selected the form should create a new driver or user
            if (!selectedUserId) {
              if (formValues.type === "Fahrer") {
                await create_driver({
                  variables: obj,
                  refetchQueries: [{query: GET_DRIVERS}]
                })
              }
              if (formValues.type === "Proband") {
                await create_test_subjects({
                  variables: obj,
                  refetchQueries: [{query: GET_TEST_SUBJECT}]
                })
              }
              if (formValues.type === "Testleiter") {
                await create_user({
                  variables: obj,
                  refetchQueries: [{query: GET_USERS}]
                })
              }
            } else {
              // 1.1 delete and create drivers
              if (
                selectedUserType !== obj.type &&
                formValues.type === "Fahrer"
              ) {
                if (selectedUserType === "Testleiter") {
                  await delete_user({
                    variables: {
                      id: selectedUserId
                    },
                    refetchQueries: [{query: GET_USERS}]
                  })
                }
                if (selectedUserType === "Proband") {
                  await delete_test_subjects({
                    variables: {
                      id: selectedUserId
                    },
                    refetchQueries: [{query: GET_TEST_SUBJECT}]
                  })
                }

                await create_driver({
                  variables: {...obj},
                  refetchQueries: [{query: GET_DRIVERS}]
                })
              }

              // 1.2 update driver
              if (
                selectedUserType === obj.type &&
                formValues.type === "Fahrer" &&
                password !== ""
              ) {
                await update_drivers({
                  variables: {...obj, id: selectedUserId},
                  refetchQueries: [{query: GET_DRIVERS}]
                })
              }
              if (
                selectedUserType === obj.type &&
                formValues.type === "Fahrer" &&
                password === ""
              ) {
                delete obj.password
                await update_drivers_without_password({
                  variables: {...obj, id: selectedUserId},
                  refetchQueries: [{query: GET_DRIVERS}]
                })
              }

              // 2.1 delete and create test_subjects
              if (
                selectedUserType !== obj.type &&
                formValues.type === "Proband"
              ) {
                if (selectedUserType === "Testleiter") {
                  await delete_user({
                    variables: {
                      id: selectedUserId
                    },
                    refetchQueries: [{query: GET_USERS}]
                  })
                }
                if (selectedUserType === "Fahrer") {
                  await delete_driver({
                    variables: {
                      id: selectedUserId
                    },
                    refetchQueries: [{query: GET_DRIVERS}]
                  })
                }

                await create_test_subjects({
                  variables: {...obj},
                  refetchQueries: [{query: GET_TEST_SUBJECT}]
                })
              }

              // 2.2 update test_subjects
              if (
                selectedUserType === obj.type &&
                formValues.type === "Proband" &&
                password !== ""
              ) {
                await update_test_subjects({
                  variables: {
                    ...obj,
                    id: selectedUserId
                  },
                  refetchQueries: [{query: GET_TEST_SUBJECT}]
                })
              }
              if (
                selectedUserType === obj.type &&
                formValues.type === "Proband" &&
                password === ""
              ) {
                delete obj.password
                await update_test_subjects_without_password({
                  variables: {
                    ...obj,
                    id: selectedUserId
                  },
                  refetchQueries: [{query: GET_TEST_SUBJECT}]
                })
              }

              // 3.1 delete and create users
              if (
                selectedUserType !== obj.type &&
                formValues.type === "Testleiter"
              ) {
                if (selectedUserType === "Proband") {
                  await delete_test_subjects({
                    variables: {
                      id: selectedUserId
                    },
                    refetchQueries: [{query: GET_TEST_SUBJECT}]
                  })
                }
                if (selectedUserType === "Fahrer") {
                  await delete_driver({
                    variables: {
                      id: selectedUserId
                    },
                    refetchQueries: [{query: GET_DRIVERS}]
                  })
                }

                await create_user({
                  variables: {...obj},
                  refetchQueries: [{query: GET_USERS}]
                })
              }

              // 3.2 update user
              if (
                selectedUserType === obj.type &&
                formValues.type === "Testleiter" &&
                password !== ""
              ) {
                await update_users({
                  variables: {...obj, id: selectedUserId},
                  refetchQueries: [{query: GET_USERS}]
                })
              }
              if (
                selectedUserType === obj.type &&
                formValues.type === "Testleiter" &&
                password === ""
              ) {
                delete obj.password
                await update_users_without_password({
                  variables: {...obj, id: selectedUserId},
                  refetchQueries: [{query: GET_USERS}]
                })
              }
            }
            setIsLoading(false)
            if (isReset) resetForm()
            setIsLoading(false)

            resolve("ok")
          } catch (err) {
            message.error("Benutzername oder E-Mail ist doppelt vorhanden")
            setIsLoading(false)

            reject(new Error({message: "error"}))
          }
        }
      )
    })
  }

  const deleteCurrentUser = async () => {
    setIsLoading(true)
    try {
      if (!selectedUserId) return

      if (userType === "Fahrer") {
        await delete_driver({
          variables: {id: selectedUserId},
          refetchQueries: [{query: GET_DRIVERS}]
        })
      }
      if (userType === "Proband") {
        await delete_test_subjects({
          variables: {id: selectedUserId},
          refetchQueries: [{query: GET_TEST_SUBJECT}]
        })
      }
      if (userType === "Testleiter") {
        await delete_user({
          variables: {id: selectedUserId},
          refetchQueries: [{query: GET_USERS}]
        })
      }

      resetForm()
      setIsLoading(false)
    } catch (err) {
      message.error(
        "Der Benutzer kann nicht gelöscht werden, denn es wurden schon Daten erhoben, die mit ihm assoziiert sind."
      )
      setIsLoading(false)
    }
  }

  const handleCreateUserButtonClick = () => {
    resetForm()
  }

  const handleRowClick = (data) => {
    if (data?.type === "Fahrer") {
      setSelectedUserId(data.id)
      setSelectedUserType(data.type)
      setUserType("Fahrer")
      return
    }
    if (data?.type === "Testleiter") {
      setSelectedUserId(data.id)
      setSelectedUserType(data.type)
      setUserType("Testleiter")
      return
    }
    if (data?.type === "Proband") {
      setSelectedUserId(data.id)
      setSelectedUserType(data.type)
      setUserType("Proband")
      return
    }
  }

  useEffect(() => {
    if (selectedUserId) {
      let selectedUserData
      if (userType === "Fahrer") {
        selectedUserData = drivers?.drivers.filter(
          (driver) => driver.id === selectedUserId
        )[0]
      }
      if (userType === "Testleiter") {
        selectedUserData = users?.users.filter(
          (user) => user.id === selectedUserId
        )[0]
      }
      if (userType === "Proband") {
        selectedUserData = test_subjects?.test_subjects.filter(
          (subject) => subject.id === selectedUserId
        )[0]
      }

      formInstance.setFieldsValue({
        first_name: selectedUserData?.first_name,
        last_name: selectedUserData?.last_name,
        user_name: selectedUserData?.user_name,
        phone: selectedUserData?.phone,
        address: selectedUserData?.address,
        date_of_birth: getDateOfBirth(selectedUserData?.date_of_birth),
        type: getUserType(userType),
        prevType: getUserType(userType),
        email: selectedUserData?.email,
        gender: getGenderMapping(selectedUserData?.gender),
        password: ""
      })
    } else {
      formInstance.resetFields()
    }
  }, [selectedUserId, userType])

  const isUserNoSaved = (isData) => {
    setIsUserNoSaved(isData)
  }

  const handleSaveDataIfNoSaved = async () => {
    const formFiels = formInstance.getFieldValue()
    if (
      formFiels.user_name === undefined ||
      formFiels.type === undefined ||
      (formFiels.type === "Testleiter" &&
        (formFiels.password === undefined ||
          formFiels.email === undefined ||
          formInstance.getFieldError("email").length > 0))
    ) {
      message.error(
        "Bitte füllen Sie alle erforderlichen Felder oder eine gültige E-Mail-Adresse aus"
      )

      throw new Error({message: "error"})
    }

    try {
      await onFinish(formInstance.getFieldValue(), false)
      return
    } catch (err) {
      throw new Error({message: "error"})
    }
  }

  return (
    <UserWrapper>
      <UserList
        handleCreateUserButtonClick={handleCreateUserButtonClick}
        handleRowClick={handleRowClick}
        users={
          Array.isArray(users?.users) &&
          Array.isArray(drivers?.drivers) &&
          Array.isArray(test_subjects?.test_subjects) && [
            ...users.users.map((user, index) => {
              const NewUser = {...user, key: index}
              NewUser.type = "Testleiter"
              return NewUser
            }),
            ...drivers.drivers.map((driver, index) => {
              const newDriver = {...driver, key: index + users.users.length}
              newDriver.type = "Fahrer"
              return newDriver
            }),
            ...test_subjects.test_subjects.map((subjects, index) => {
              const newTest_subjects = {
                ...subjects,
                key: index + users.users.length + drivers.drivers.length
              }
              newTest_subjects.type = "Proband"
              return newTest_subjects
            })
          ]
        }
        isUserNoSavedState={isUserNoSavedState}
        setIsUserNoSaved={setIsUserNoSaved}
        handleSaveDataIfNoSaved={handleSaveDataIfNoSaved}
      />

      <GroupBox>
        <Relative>
          <Dark $isLoading={isLoading}>
            <UserForm
              formInstance={formInstance}
              deleteCurrentUser={deleteCurrentUser}
              onFinish={onFinish}
              resetForm={resetForm}
              currentID={selectedUserId}
              handleSetIsloading={handleSetIsloading}
              isUserNoSaved={isUserNoSaved}
            />
          </Dark>
        </Relative>
        <TestGroup updateGroups={updateGroups} />
      </GroupBox>
    </UserWrapper>
  )
}

export default NewUsers
