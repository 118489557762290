import styled from "styled-components"
import {Table, Button} from "antd"

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const InsideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const DescriptionTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #495057;
  margin-bottom: 20px;
`
export const DescriptionText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  color: #495057;
  margin-bottom: 20px;
`

export const ListBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const ListBoxTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const ChooseScenarioBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const ChooseScenarioBoxButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 15px;
`

export const TableStyled = styled(Table)`
  thead > tr > th {
    height: 56px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
    /* Conditional/divider */

    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 0px;
  }

  tbody {
    tr {
      td {
        height: 56px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        /* identical to box height, or 169% */

        color: #495057;
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 0px;
      }
    }
  }
`
export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;

  background: #faba15;
  border: 1px solid #faba15;
  border-radius: 4px;
  color: #ffffff;

  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
`

export const Styled = {
  Box,
  InsideBox,
  DescriptionBox,
  DescriptionTitle,
  DescriptionText,
  ListBoxStyle,
  ListBoxTitle,
  ChooseScenarioBox,
  ChooseScenarioBoxButton,
  TableStyled,
  ButtonStyled,
  ButtonBox
}
