import styled from "styled-components"

export const MainBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 853px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  width: 100%;
  width: 92%;
  margin-bottom: 100px;
`
