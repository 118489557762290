import styled from "styled-components"

const parametr = "90"

export const ButtonListBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 86px;
  margin: 30px 0 20px 0;
`

export const GroupBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${parametr / 2}px; // padding===topLabel width/2 !!
`

export const StepBoxRelative = styled.div`
  position: relative;

  ${({$labelLength}) =>
    $labelLength
      ? `
    margin-bottom: ${$labelLength * 10 + 20}px;
    `
      : `
    margin-bottom: 0px;
    `}
`

export const StepBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`

export const TopLabelBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
`

export const TopLabel = styled.div`
  display: flex;
  justify-content: center;

  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  width: 10px;
  border: 2px solid white;
  color: #808080;

  transition: 0.4s;

  ${({isGray}) =>
    isGray &&
    `
    border: 2px solid #F1F1F1;
    color: #808080;;
    background-color: #F1F1F1;
    `}

  ${({active}) =>
    active &&
    `
    border: 2px solid #FABA15;
    color: #ffffff;
    background-color: #FABA15;
    `}

  ${({active, isGray}) =>
    !active &&
    !isGray &&
    `
    color: #ffffff;

    `}
`
export const BottomLabel = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #808080;
  white-space: wrap;
  min-height: 32px;
  text-align: center;
`

export const ButtonsBoxRelative = styled.div`
  position: relative;
  z-index: 1;
`

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  margin: 10px 0;
  color: #495057;

  transform: rotate(45deg);
  transition: 0.4s;

  cursor: pointer;
  background: #ffffff;
  border: 2px solid #f1f1f1;

  ${({size}) =>
    `
    width: ${size};
    height: ${size};
    `}

  ${({active}) =>
    active &&
    `
    border: 2px solid #FABA15;
    `}
`
export const ButtonsTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #808080;
  transform: rotate(-45deg);
`

export const LineBox = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 0;
  background-color: #f1f1f1;
  height: 5px;

  ${({width}) =>
    `
    width: ${width}px;
  `}

  ${({size}) =>
    !size &&
    `
    top: 52%;
  `}
`

export const TopLabelAbsolute = styled.div`
  position: absolute;

  top: -30px;
  z-index: 2;
  width: ${parametr}px;

  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #808080;
  white-space: wrap;

  text-align: center;

  ${({size}) =>
    size &&
    `
    left: ${-parametr / 2 + 16 / 2}px;
  `}

  ${({size}) =>
    !size &&
    `
    left: ${-parametr / 2 + 30 / 2}px;
  `}
`
export const TopLabelAbsoluteText = styled.div`
  display: inline-block;

  transition: 0.1s;

  ${({isGray}) =>
    isGray &&
    `
    border: 2px solid #F1F1F1;
    color: #808080;;
    background-color: #F1F1F1;
  `}

  ${({active}) =>
    active &&
    `
    border: 2px solid #FABA15;
    color: #ffffff;
    background-color: #FABA15;
  `}

  ${({active, isGray}) =>
    !active &&
    !isGray &&
    `
    color: #ffffff;

  `}
  padding: 0 10px;
`

export const BottomLabelAbsolute = styled.div`
  position: absolute;
  z-index: 2;
  width: ${parametr}px;
  max-width: 350%;

  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #808080;
  word-wrap: break-word;
  min-height: 32px;

  text-align: center;

  left: -20px;

  top: 40px;
`

export const Styled = {
  StepBoxRelative,
  StepBoxContent,
  ButtonListBox,
  GroupBox,
  TopLabel,
  TopLabelBox,
  BottomLabel,
  ButtonsBox,
  Buttons,
  ButtonsTitle,
  LineBox,
  ButtonsBoxRelative,
  TopLabelAbsolute,
  BottomLabelAbsolute,
  TopLabelAbsoluteText
}
