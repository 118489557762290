/* eslint-disable semi */
import {Tag} from "antd"
import styled from "styled-components"
import UserForm from "./UserForm"

export const StyledForm = styled(UserForm)`
  width: 100%;
`

export const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #f8f8fb;
`

export const ScenarioTag = styled(Tag)`
  background-color: #fff;
  border-style: dashed;
`

export const GroupBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Relative = styled.div`
  position: relative;
  z-index: 0;
`

export const Dark = styled.div`
  ${({$isLoading}) =>
    $isLoading &&
    `
    filter: brightness(80%) blur(1px);
  
    `}
`
