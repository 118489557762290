import React, {useState} from "react"
import {ModalStyle} from "../../pages/login/StyledComponents"
import {Styled} from "./modal.styled"
import SusiPortraitImg from "../../images/susi_portrait.png"

const {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  GreyText,
  OutterRowWrapper,
  TitleBox,
  DescriptionBox,
  TextBox
} = Styled

const ModalComponent = ({
  text,
  description,
  closable,
  initState,
  handleAfterClose
}) => {
  const [visible, setVisible] = useState(initState)

  const handleCancel = () => {
    if (closable) {
      setVisible(false)
    }
  }

  const handleClose = () => {
    if (handleAfterClose) handleAfterClose()
  }

  return (
    <>
      <ModalStyle
        visible={visible}
        onCancel={handleCancel}
        footer={[]}
        centered
        closable={closable}
        afterClose={handleClose}
      >
        <OutterRowWrapper>
          <LeftSideFormContainer>
            <SusiPortraitWrapper
              justify="space-evenly"
              align="middle"
              wrap={false}
            >
              <TitleBox>
                <OrangeText>Hallo, ich bin SUSI.</OrangeText>
              </TitleBox>
              <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
            </SusiPortraitWrapper>
            <FormContainer>
              <TextBox>{text}</TextBox>
              <DescriptionBox>{description}</DescriptionBox>
            </FormContainer>
          </LeftSideFormContainer>
        </OutterRowWrapper>
      </ModalStyle>
    </>
  )
}

export default ModalComponent
