import React from "react"
import {Navigate, Route, Routes} from "react-router-dom"
import {Styled} from "./main.styled"
import Footer from "../../components/footer"
import Header from "../../components/header"

import UserFlow from "../drive/UserFlow"
import AdminFlow from "../admin/AdminFlow"
import Tests from "../drive/Tests"
import menuItems from "../../components/top-menu/top-menu.data"
import useAuth from "../../auth/useAuth"

function Main() {
  const [pending, islogIn] = useAuth();

  return (
    <Styled.MainBox>
      <Header />
      <Styled.Content>
        <Routes>
          <Route path="drive" element={<UserFlow pending={pending} />}>
            <Route path=":tabId/:stageId" element={<Tests />} />
            <Route path="*" element={<Navigate to="/drive" replace />} />
          </Route>
          <Route path="admin" element={<AdminFlow menuItems={menuItems} />}>
            {menuItems.map((item) => (
              <Route key={item.title} path={item.path} element={item.element} />
            ))}
            <Route path="*" element={<Navigate to="/admin/users" replace />} />
          </Route>
        </Routes>
      </Styled.Content>
      <Footer />
    </Styled.MainBox>
  )
}
export default Main
