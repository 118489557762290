import styled from "styled-components"
import {Button, Steps} from "antd"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import {MainBoxStyle} from "../../../common/main-box/main-box"

const {Step} = Steps

export const MainBox = styled(MainBoxStyle)``

export const InsideBox = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 0px;
  margin-top: 48px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 30px;
`

export const TitleBox = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #495057;
  margin-bottom: 30px;
  padding: 10px 30px;

  background: #fef8e8;
`

export const AchtungBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  height: 73px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fde1e1;
  border: 1px solid #fcd2d2;
  border-radius: 4px;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  color: #924040;
`

export const VertAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  margin-left: 5px;
`

export const Bold = styled.div`
  display: flex;
  font-weight: 700;
  flex-direction: column;
  justify-content: center;
`

export const TempDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
`

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
`

export const ButtonFill = styled(Button)`
  display: flex;
  height: 35px;
  width: 62px;
  background: #faba15;
  border: 1px solid #faba15;
  border-radius: 4px;
  margin-left: 15px;
  color: #ffffff;
`

export const ButtonNoFill = styled(Button)`
  display: flex;
  height: 35px;
  width: 82px;
  border-radius: 4px;
  background: #ffffff;
  border: 2px solid #faba15;
  color: #faba15;
`

export const KeyboardArrowDownIconLogo = styled(KeyboardArrowDownIcon)`
  position: absolute;
  right: 35%;
  bottom: 30%;
  color: #ffffff;
  cursor: pointer;
`

export const KeyboardArrowUpIconLogo = styled(KeyboardArrowUpIcon)`
  position: absolute;
  right: 35%;
  bottom: 30%;
  color: #ffffff;
  cursor: pointer;
`
export const StepBoxOutside = styled.div`
  position: relative;
  cursor: pointer;
`

export const StepBox = styled(Step)`
  cursor: pointer;

  .ant-steps-item-icon {
    background: transparent !important;
    ${({isafter}) =>
      isafter
        ? `
        
        border-color: #ACACAC !important;
        color: #ACACAC !important;
  
    `
        : `
        border-color: #FABA15 !important;
        color: #FABA15 !important;
    `}
  }

  .ant-steps-item-icon > .ant-steps-icon {
    ${({isafter}) =>
      isafter
        ? `border-color: #ACACAC !important;
         color: #ACACAC !important;`
        : `color: #faba15 !important;`}
  }

  ::before {
    display: none !important;
  }

  ${({isactive}) =>
    isactive &&
    `
    .ant-steps-item-container > .ant-steps-item-icon {
      background-color: #FABA15 !important;
    }
    
    .ant-steps-item-icon > .ant-steps-icon {
      color: white !important;
    }
  `}

  @media (min-width: 580px) and (max-width: 800px) {
    ::after {
      left: 90% !important;
    }
  }

  ${({$isLast}) =>
    $isLast &&
    `
    ::after {
    display: none !important;
  }
  `}
`
export const ArrowBox = styled.div``

export const List = styled.div`
  display: flex;
  margin: 0px;
`

export const ListOL = styled.ol`
  padding-left: 20px;
`

export const Styled = {
  MainBox,
  InsideBox,
  TitleBox,
  AchtungBox,
  VertAlign,
  Bold,
  TempDiv,

  ButtonBox,
  ButtonFill,
  ButtonNoFill,
  StepBoxOutside,
  StepBox,
  ArrowBox,
  List,
  ListOL
}
