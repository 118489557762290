import {gql} from "@apollo/client"

export const GET_DRIVING_LICENSES = gql`
  query get_driving_licenses {
    driving_licenses {
      class
      id
    }
  }
`

export const GET_USERS = gql`
  query get_users {
    users {
      first_name
      id
      gender
      last_name
      last_login
      is_researcher
      is_research_administrator
      email
      date_of_birth
      user_name
      country
      phone
      address
      password
    }
  }
`

export const GET_DRIVERS = gql`
  query get_drivers {
    drivers {
      phone
      address
      date_of_birth
      id
      last_name
      gender
      first_name
      email
      user_name
    }
  }
`
export const GET_TEST_SUBJECT = gql`
  query get_test_subjects {
    test_subjects {
      address
      date_of_birth
      email
      first_name
      gender
      id
      password
      last_name
      phone
      received_introductory_tutorial
      created_at
      user_name
    }
  }
`
export const GET_DRIVING_LICENSES_BY_DRIVER = gql`
  query getDrivingLicensesByDriver($driverId: Int!) {
    drivers_driving_licenses(where: {driver_id: {_eq: $driverId}}) {
      license_id
      driver_id
      driving_licence {
        class
      }
    }
  }
`

export const DELETE_DRIVERS_LICENSE_DRIVER_CONNECTION = gql`
  mutation deleteDriversLicenseDriverConnection(
    $driverId: Int!
    $licenseId: Int!
  ) {
    delete_drivers_driving_licenses(
      where: {driver_id: {_eq: $driverId}, license_id: {_eq: $licenseId}}
    ) {
      affected_rows
    }
  }
`

export const CREATE_USER = gql`
  mutation create_user(
    $address: String
    $country: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $created_at: String
    $gender: String
    $last_name: String
    $password: String
    $phone: String
    $user_name: String
  ) {
    insert_users_one(
      object: {
        address: $address
        country: $country
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        created_at: "now()"
        gender: $gender
        last_name: $last_name
        password: $password
        phone: $phone
        user_name: $user_name
      }
    ) {
      id
    }
  }
`
export const UPDATE_USERS = gql`
  mutation update_users(
    $id: Int
    $address: String
    $country: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_login: timestamp
    $last_name: String
    $password: String
    $phone: String
    $user_name: String
  ) {
    update_users(
      where: {id: {_eq: $id}}
      _set: {
        address: $address
        country: $country
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        last_name: $last_name
        password: $password
        phone: $phone
        user_name: $user_name
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_USERS_WITHOUT_PASSWORD = gql`
  mutation update_users(
    $id: Int
    $address: String
    $country: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_login: timestamp
    $last_name: String
    $phone: String
    $user_name: String
  ) {
    update_users(
      where: {id: {_eq: $id}}
      _set: {
        address: $address
        country: $country
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        last_name: $last_name
        phone: $phone
        user_name: $user_name
      }
    ) {
      affected_rows
    }
  }
`
export const CREATE_TEST_SUBJECTS = gql`
  mutation create_test_subjects(
    $address: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_name: String
    $password: String
    $phone: String
    $user_name: String
  ) {
    insert_test_subjects(
      objects: {
        address: $address
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        last_name: $last_name
        password: $password
        phone: $phone
        user_name: $user_name
      }
    ) {
      affected_rows
    }
  }
`
export const UPDATE_TEST_SUBJECTS = gql`
  mutation update_test_subjects(
    $id: Int
    $user_name: String
    $address: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_name: String
    $password: String
    $phone: String
    $updated_at: timestamptz
  ) {
    update_test_subjects(
      where: {id: {_eq: $id}}
      _set: {
        user_name: $user_name
        address: $address
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        last_name: $last_name
        password: $password
        phone: $phone
        updated_at: "now()"
      }
    ) {
      affected_rows
    }
  }
`
export const UPDATE_TEST_SUBJECTS_WITHOUT_PASSWORD = gql`
  mutation update_test_subjects(
    $id: Int
    $user_name: String
    $address: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_name: String
    $phone: String
    $updated_at: timestamptz
  ) {
    update_test_subjects(
      where: {id: {_eq: $id}}
      _set: {
        user_name: $user_name
        address: $address
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        last_name: $last_name
        phone: $phone
        updated_at: "now()"
      }
    ) {
      affected_rows
    }
  }
`
export const CREATE_DRIVER = gql`
  mutation create_driver(
    $address: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_name: String
    $password: String
    $phone: String
    $user_name: String
    $created_at: timestamptz
  ) {
    insert_drivers_one(
      object: {
        address: $address
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        created_at: "now()"
        gender: $gender
        last_name: $last_name
        password: $password
        phone: $phone
        user_name: $user_name
      }
    ) {
      id
    }
  }
`

export const UPDATE_DRIVERS = gql`
  mutation update_drivers(
    $id: Int
    $address: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_name: String
    $password: String
    $phone: String
    $user_name: String
    $updated_at: timestamptz
  ) {
    update_drivers(
      where: {id: {_eq: $id}}
      _set: {
        address: $address
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        last_name: $last_name
        password: $password
        phone: $phone
        user_name: $user_name
        updated_at: "now()"
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_DRIVERS_WITHOUT_PASSWORD = gql`
  mutation update_drivers(
    $id: Int
    $address: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_name: String
    $phone: String
    $user_name: String
    $updated_at: timestamptz
  ) {
    update_drivers(
      where: {id: {_eq: $id}}
      _set: {
        address: $address
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        last_name: $last_name
        phone: $phone
        user_name: $user_name
        updated_at: "now()"
      }
    ) {
      affected_rows
    }
  }
`

export const DELETE_USER = gql`
  mutation delete_user($id: Int!) {
    delete_test_groups_members(where: {user_id: {_eq: $id}}) {
      affected_rows
    }
    delete_users_by_pk(id: $id) {
      id
    }
  }
`
export const DELETE_TEST_SUBJECTS = gql`
  mutation delete_test_subjects($user_name: String, $id: Int) {
    delete_test_groups_members(where: {test_subject_id: {_eq: $id}}) {
      affected_rows
    }
    delete_test_subjects(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`
export const DELETE_DRIVER = gql`
  mutation delete_driver($id: Int!) {
    delete_test_groups_members(where: {driver_id: {_eq: $id}}) {
      affected_rows
    }
    delete_drivers_driving_licenses(where: {driver_id: {_eq: $id}}) {
      affected_rows
    }
    delete_drivers_by_pk(id: $id) {
      id
    }
  }
`

export const UPDATE_USER = gql`
  mutation update_user(
    $address: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $id: Int!
    $is_research_administrator: Int
    $is_researcher: Int
    $last_name: String
    $password: String
    $phone: String
    $user_name: String
  ) {
    update_users_by_pk(
      pk_columns: {id: $id}
      _set: {
        address: $address
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        id: $id
        is_research_administrator: $is_research_administrator
        is_researcher: $is_researcher
        last_name: $last_name
        password: $password
        phone: $phone
        user_name: $user_name
      }
    ) {
      id
    }
  }
`

export const UPDATE_DRIVER = gql`
  mutation updateDriver(
    $address: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $gender: String
    $last_name: String
    $password: String
    $phone: String
    $updated_at: timestamptz
    $user_name: String
    $id: Int!
  ) {
    update_drivers_by_pk(
      pk_columns: {id: $id}
      _set: {
        address: $address
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        gender: $gender
        last_name: $last_name
        password: $password
        phone: $phone
        updated_at: $updated_at
        user_name: $user_name
      }
    ) {
      id
    }
  }
`

export const CREATE_DRIVER_LICENSE_CONNECTION = gql`
  mutation createDriverLicenseConnection($driverId: Int!, $licenseId: Int!) {
    insert_drivers_driving_licenses(
      objects: {driver_id: $driverId, license_id: $licenseId}
    ) {
      returning {
        id
        expiracy_date
        expedition_date
        driver_id
        created_at
        country_of_expedition
        license_id
      }
    }
  }
`
