import styled from "styled-components"

export const MainBox = styled.div``

export const QuestionBox = styled.div``

export const SubTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #495057;
  padding-bottom: 24px;

  @media screen and (max-width: 700px) {
    padding-bottom: 5px;
  }
`
export const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: #495057;
  padding-bottom: 36px;

  @media screen and (max-width: 700px) {
    padding-bottom: 5px;
  }
`

export const Styled = {
  MainBox,
  QuestionBox,
  SubTitle,
  Description
}
