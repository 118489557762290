import styled from "styled-components"
import {Table, Input, Select, Popconfirm} from "antd"
import {ReactComponent as PlusSvg} from "../../../images/Plus.svg"
import {ReactComponent as ButtonLicenseIcon} from "../../../images/ButtonLicenseIcon.svg"

const {Option} = Select

const {TextArea} = Input

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
`

export const InsideBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  width: 92%;
`
export const VehicleListBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  /* width: 100%; */
  max-width: 520px;
  min-width: 400px;

  min-height: 852px;

  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;

  padding: 21px 40px;
`

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  width: 100%;
`

export const DescriptionTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #495057;

  margin-bottom: 20px;
  margin-left: -8px;
`
export const DescriptionButtonBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;

  background: #f1b44c;
  border: 1px solid #f1b44c;
  border-radius: 4px;

  width: 157px;
  height: 36px;

  padding: 8px 30px 8px 11px;
`
export const DescriptionButtonIcon = styled(PlusSvg)`
  padding-top: 4px;
  width: 15px;
  height: 15px;
`

export const DescriptionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #ffffff;
  padding-left: 30px;
`

export const ListBoxStyle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 100%;
  padding-top: 30px;
`

export const ListBoxTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 100%;
`

export const ChooseVehicleBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 100%;
`

export const ChooseVehicleBoxButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  padding: 20px 15px;
`

export const TableStyled = styled(Table)`
  thead > tr > th {
    height: 54px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: rgba(0, 0, 0, 0.85);

    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 0px;
  }

  tbody {
    tr {
      td {
        height: 54px;

        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #495057;

        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 0px;

        text-overflow: ellipsis;
      }
    }
  }

  // pagination border color
  .ant-pagination-item-active {
    background-color: #faba15;

    border: 1px solid #faba15;
    border-radius: 2px;

    color: rgba(0, 0, 0, 0.85);
    transition: 0.3s;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
`

// right panel
export const VehicleEdit = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  /* width: 300px; */

  min-width: 300px;

  width: 100%;

  height: 100%;

  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 3.25px;

  padding: 21px 40px 25px 40px;

  margin-left: 30px;
`

export const VehicleEditSelectGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`

export const VehicleEditGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 200px;

  ${({$isPaddingRight}) =>
    $isPaddingRight &&
    `
    padding-right: 20px;
    margin-bottom: 5px;
    `}
`

export const VehicleEditInputLabel = styled.div`
  display: flex;
  flex-direction: column;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #74788d;
  padding-bottom: 2px;
`

export const VehicleEditInput = styled(Input)`
  height: 36px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #74788d;

  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 12px;

  ${({$isError}) =>
    $isError &&
    `
    border-color:red;
    outline : none;
    `}
`

export const VehicleSelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 200px;

  ${({$isPaddingRight}) =>
    $isPaddingRight &&
    `
    padding-right: 20px;
    margin-bottom: 5px;
    `}
`

export const VehicleSelectLabel = styled.div`
  display: flex;
  flex-direction: column;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #74788d;
  padding-bottom: 2px;
`
export const VehicleSelect = styled(Select)`
  /* min-width: 200px; */
  min-width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #74788d;

  background: #ffffff;

  .ant-select-selector {
    min-height: 36px;
    padding-top: 2px !important;
    border-radius: 4px !important;
  }
`
export const VehicleOption = styled(Option)``

export const VehicleEditTextAreaLabel = styled.div`
  display: flex;
  flex-direction: column;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  color: #495057;

  padding-top: 20px;
  padding-bottom: 9px;
`

export const VehicleEditTextArea = styled(TextArea)`
  width: 100%;
  min-height: 132px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #74788d;

  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;

  padding-left: 12px;
  white-space: pre-line;
`

export const VehicleEditButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 55px;
  flex-wrap: wrap;
`

export const VehicleEditButtonsDelete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 78px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #faba15;

  background-color: #ffffff;
  border: 1px solid #f1b44c;
  border-radius: 4px;

  margin-left: 18px;
  height: 32px;
  min-height: 32px;

  ${({$isDisable}) =>
    $isDisable &&
    `
    background-color: #ffffff;
    border: 1px solid #ced4da;
    color: #ced4da;
    `}

  ${({$isDisable}) =>
    !$isDisable &&
    `
    background-color: #ffffff;
    border: 1px solid #faba15;
    color: #faba15;
    `}

  cursor: pointer;
`

export const VehicleEditButtonsAbort = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #faba15;

  background-color: #ffffff;
  border: 1px solid #f1b44c;
  border-radius: 4px;

  margin-left: 18px;
  margin-bottom: 20px;

  width: 82px;
  height: 32px;
  min-height: 32px;

  ${({$isDisable}) =>
    $isDisable &&
    `
    background-color: #ffffff;
    border: 1px solid #ced4da;
    color: #ced4da;
    `}

  ${({$isDisable}) =>
    !$isDisable &&
    `
    background-color: #ffffff;
    border: 1px solid #faba15;
    color: #faba15;
    `}

    cursor: pointer;
`

export const VehicleEditButtonsSave = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 130px;

  height: 32px;
  min-height: 32px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #faba15;

  background-color: #ffffff;
  border: 1px solid #f1b44c;
  border-radius: 4px;

  margin-left: 18px;

  ${({$isDisable}) =>
    $isDisable &&
    `
    background-color: #ffffff;
    border: 1px solid #ced4da;
    color: #ced4da;
    `}

  ${({$isDisable}) =>
    !$isDisable &&
    `
    background-color: #faba15;
    border: 1px solid #faba15;
    color: #ffffff;
    `}
    cursor: pointer;
`

export const VehicleAddGroup = styled(VehicleEditButtonsDelete)`
  display: flex;
  flex-direction: column;

  min-height: 32px;
  width: 100%;
  margin-left: 0px;
`
export const PopconfirmStyle = styled(Popconfirm)``

export const LicenseBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 50%;

  margin-top: 65px;
`

export const LicenseTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  margin-left: -8px;

  color: #495057;
`

export const LicenseSelectlabel = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  width: 100%;

  margin-top: 13px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  color: #808080;
`

export const LicenseSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  width: 100%;
  margin-top: 13px;
`

export const LicenseSelect = styled(Select)`
  min-width: 150px;
  margin-right: 11px;

  .ant-select-selector {
    min-height: 36px;
    padding-top: 2px !important;
    border-radius: 4px !important;
  }
`

export const LicenseSelectButtonIcon = styled(ButtonLicenseIcon)`
  height: 14px;
  width: 14px;
`

export const LicenseSelectButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;

  width: 40px;
  height: 36px;
  min-width: 40px;
  min-height: 36px;

  left: 1193px;
  top: 644px;

  background: #f1b44c;
  border: 1px solid #f1b44c;
  border-radius: 4px;
`

export const VehicleAddManufacture = styled.input`
  height: 36px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #74788d;

  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding-left: 12px;
`
export const Error = styled.div`
  color: red;
`

export const Styled = {
  Box,
  InsideBox,
  VehicleListBox,
  DescriptionBox,
  DescriptionTitle,
  DescriptionButtonBox,
  DescriptionButtonIcon,
  DescriptionButton,
  ListBoxStyle,
  ListBoxTitle,
  ChooseVehicleBox,
  ChooseVehicleBoxButton,
  TableStyled,

  VehicleEdit,
  VehicleEditSelectGroup,

  VehicleEditGroup,
  VehicleEditInputLabel,
  VehicleEditInput,

  VehicleSelectGroup,
  VehicleSelectLabel,
  VehicleSelect,
  VehicleOption,

  VehicleEditTextAreaLabel,
  VehicleEditTextArea,
  VehicleEditButtonsGroup,
  VehicleEditButtonsSave,
  VehicleEditButtonsAbort,
  VehicleEditButtonsDelete,

  VehicleAddGroup,
  PopconfirmStyle,

  LicenseBox,
  LicenseTitle,
  LicenseSelectlabel,
  LicenseSelectBox,
  LicenseSelect,
  LicenseSelectButton,
  LicenseSelectButtonIcon,

  VehicleAddManufacture,
  Error
}
