import styled from "styled-components"
import {Button} from "antd"

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonStyle = styled(Button)`
  ${({
    $height,
    $borderSize,
    $bordercolor,
    $backgroundcolor,
    $color,
    $borderradius,
    $width,
    $paddingleft
  }) =>
    `
    height: ${$height}px;
    background: ${$backgroundcolor};
    color: ${$color};
    border:${$borderSize}px solid ${$bordercolor};
    border-radius: ${$borderradius}px;
    width: ${$width}px;
    padding-left: ${$paddingleft}px;    
  `}
  padding-top: 3px;
`

export const Styled = {
  ButtonBox,
  ButtonStyle
}
