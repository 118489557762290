import styled from "styled-components"

export const Input = styled.input`
  cursor: pointer;
  -webkit-appearance: none;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: none;
  border: 1px solid #d9d9d9;
  margin-top: 8px;

  :before {
    content: "";
    display: block;
    min-width: 8px;
    min-height: 8px;
    width: 8px;
    height: 8px;
    margin: 3px 3px;
    border-radius: 20px;
  }

  :checked:before {
    background: #faba15;
  }
  :checked {
    border-color: #faba15;
  }
`
export const DarkInsideText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #495057;

  padding-right: 30px;
  width: 100%;
  margin: 5px;
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const MainBox = styled.div``

export const Styled = {
  MainBox,
  DarkInsideText,
  Container,
  Input
}
