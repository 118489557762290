import React, {useState} from "react"
import {regex} from "../../../const/const"
import AchtungQuestion from "../../achtung-question/achtung.component"
import QuestionStep from "../../question-step"
import Line from "../line"
import {Styled} from "./rhombus.styled"

function Rhombus({
  sequence_id,
  title,
  question,
  answer,
  data,
  lastConnected,
  isFirst,
  isLast,
  questionId
}) {
  const [isShow, setIsShow] = useState(false)

  const handleSetIsshow = (value) => {
    setIsShow(value)
  }
  let newData = ""

  const size = data.split(";")[0] === "0" ? "big" : "small"

  if (size === "big") {
    newData = data
      .split(";")
      .filter((item) => item !== null)
      .map((item, index) => {
        const container = {}

        container.id = index
        container.topLabel = index
        container.bottomLabel = ""
        container.buttonName = index

        return container
      })
  } else {
    newData = data.split(";").map((item, index) => {
      const container = {}

      container.id = index
      container.topLabel = item
      container.bottomLabel = item
      container.buttonName = ""

      return container
    })
  }

  const findDecimal = question !== null ? question.match(regex) : null

  const findDecimalTitle = title !== null ? title.match(regex) : null

  return (
    <>
      {!isFirst && title !== null && <Line />}
      <AchtungQuestion
        isShow={isShow}
        title="Bitte ausfüllen! Falls nicht möglich, dem Versuchsleiter zeigen."
      />
      {title !== null && (
        <Styled.ContainerMainTitle>
          <Styled.ContainerMainTitleRelative>
            {title.match(regex) !== null && (
              <Styled.ContainerNumberTitle
                $isBigSpace={
                  findDecimalTitle !== null
                    ? Number(findDecimalTitle[0].split(".")[0]) >= 10
                    : 0
                }
              >
                {title.match(regex)}
              </Styled.ContainerNumberTitle>
            )}
          </Styled.ContainerMainTitleRelative>
          {findDecimalTitle !== null
            ? title.split(findDecimalTitle[0])[1]
            : title}
        </Styled.ContainerMainTitle>
      )}
      <Styled.ContainerRhombus>
        <Styled.ContainerBox>
          {question.match(regex) !== null && (
            <Styled.ContainerNumber
              $isBigSpace={
                findDecimal !== null
                  ? Number(findDecimal[0].split(".")[0]) >= 10
                  : 0
              }
            >
              {question.match(regex)}
            </Styled.ContainerNumber>
          )}
          <Styled.ContainerRhombusText>
            {findDecimal !== null
              ? question.split(findDecimal[0])[1]
              : question}
          </Styled.ContainerRhombusText>
        </Styled.ContainerBox>
        <Styled.ContainerRhombusItem>
          <QuestionStep
            data={newData}
            lastConnected={lastConnected}
            size={size}
            answer={answer}
            questionId={questionId}
            handleSetIsshow={handleSetIsshow}
          />
        </Styled.ContainerRhombusItem>
      </Styled.ContainerRhombus>
      {!isFirst && isLast && title !== null && <Line />}
    </>
  )
}

export default Rhombus
