import React, {useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {Styled} from "./top-menu.styled"
import menuItems from "./top-menu.data"

const TopMenu = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [currentItem, setCurrenItem] = useState()

  useEffect(() => {
    setCurrenItem(
      menuItems.findIndex(
        (item) => item.title === location.pathname.split("/")[2]
      )
    )
  }, [location.pathname])

  return (
    <Styled.MainBox>
      {menuItems.map((item, index) => (
        <Styled.BoxMenuItem
          id={item.path}
          $currentItem={currentItem === index}
          key={item.title}
          onClick={() => navigate(`admin/${item.path}`)}
        >
          {t(item.textTranslationHandle)}
        </Styled.BoxMenuItem>
      ))}
    </Styled.MainBox>
  )
}

export default TopMenu
