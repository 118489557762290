import styled from "styled-components"

import {BookOutlined} from "@ant-design/icons"
import {ReactComponent as SusiNextSvg} from "../../images/next.svg"
import {ReactComponent as SusiMicro} from "../../images/micro.svg"
import {ReactComponent as SusiPlay} from "../../images/play.svg"
import {ReactComponent as SusiCarSvg} from "../../images/car.svg"

export const SusiAudioBookDescription = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: inherit;
  @media (max-width: 600px) {
    font-size: 10px;
    text-align: left;
    bottom: -25px;
    right: 10px;
  }
`

export const SusiAudioBookControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 32px auto 16px auto;
`

export const SusiAudioBookAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  color: ${({active}) => (active ? "#FABA15" : "#495057")};
`

export const SusiBookOutlined = styled(BookOutlined)`
  margin: auto 16px 16px 16px;
  font-size: 110px;
  color: inherit;
`

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 853px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  width: 92%;
  margin-bottom: 100px;
`

export const InsideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SusiBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 168px 0;
`

export const SusiImgBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SusiImg = styled.img`
  width: 550px;
  @media (max-width: 600px) {
    width: 350px;
  }
`

export const SusiAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const SusiImgGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  margin-top: 50px;
  height: 100px;
  @media (max-width: 600px) {
    margin-top: 30px;
    height: 60px;
  }
`

export const SusiMicroBox = styled.div`
  position: relative;
  text-align: center;
  width: 100px;
  @media (max-width: 600px) {
    width: 50px;
  }
`

export const SusiMicroImg = styled(SusiMicro)`
  width: 90px;
  height: 89px;
  @media (max-width: 600px) {
    width: 50px;
    height: 55px;
  }
`

export const SusiCarBox = styled.div`
  position: relative;
  text-align: center;
  width: 90px;
  @media (max-width: 600px) {
    width: 55px;
  }
`

export const SusiCarImg = styled(SusiCarSvg)`
  width: 110px;
  height: 110px;
  @media (max-width: 600px) {
    width: 65px;
    height: 65px;
  }
`

export const MicrophoneImgDescription = styled.div`
  margin-top: 20px;
  position: absolute;
  right: 0;
  z-index: 2;
  color: #faba15;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;

  @media (max-width: 600px) {
    font-size: 10px;
    text-align: center;
  }
`

export const SusiCarImgDescription = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;

  color: #495057;

  @media (max-width: 600px) {
    font-size: 10px;
    text-align: center;
  }
`

export const SusiCarPlayBox = styled.div`
  position: relative;
  text-align: center;

  @media (max-width: 600px) {
    width: 55px;
  }
`

export const SusiCarPlayImg = styled(SusiPlay)`
  width: 90px;
  height: 90px;
  @media (max-width: 600px) {
    width: 55px;
    height: 55px;
  }
`
export const SusiCarImgPlayDescription = styled.div`
  position: absolute;
  right: 12.5px;
  bottom: -30px;
  z-index: 2;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;

  color: #495057;

  @media (max-width: 600px) {
    font-size: 10px;
    text-align: left;
    bottom: -37px;
    right: 0px;
  }
`

export const SusiNextBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90px;
  height: 90px;
  @media (max-width: 600px) {
    width: 55px;
    height: 55px;
  }
`

export const SusiNextImg = styled(SusiNextSvg)`
  width: 100%;
`

export const SusiColorRing = styled.div`
  border-radius: 50%;

  ${({
    isListening,
    isSpeechRecognitionRecording,
    isWaitingResponse,
    isError,
    isSpeaking
  }) => {
    if (isError) {
      // red
      return `background-color: red;`
    } 

    if (isWaitingResponse) {
      // light purple
      return `background-color: #9370db;`
    } 

    if (isSpeechRecognitionRecording) {
      // blue
      return `background-color: #0345fc;`
    } 

    if (isSpeaking) {
      // orange
      return `background-color: #feb310;`
    }

    if (isListening) {
      // green
      return `background-color: #03fc49;`
    }

    // grey (unkown/undefined state)
    return `background-color: #d0d0d0;`
  }}
`

export const SusiColorStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid #ddf;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 500;
`

export const Styled = {
  SusiColorRing,
  MainBox,
  InsideBox,
  SusiBox,
  SusiImgBox,
  SusiImg,
  SusiAlign,
  SusiImgGroup,
  SusiMicroBox,
  SusiMicroImg,
  SusiCarBox,
  SusiCarImg,
  SusiCarImgDescription,
  SusiCarPlayBox,
  SusiCarPlayImg,
  SusiCarImgPlayDescription,
  SusiNextBox,
  SusiNextImg,
  SusiAudioBookControls,
  SusiBookOutlined,
  SusiAudioBookAndDescription,
  SusiAudioBookDescription,
  MicrophoneImgDescription,
  SusiColorStatus
}
