import React, {useContext, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {useQuery} from "@apollo/client"
import {DataContext, UserContext} from "../../context/context"
import {Styled} from "./pagination.styled"
import {
  getFragenStep,
  handlerModalBeforeDrive,
  formMissedQuestions
} from "../../middleware/mathPagination"
import {GET_SCENARIOS_DATA} from "../../const/const"

const Pagination = ({before, after, handleClick}) => {
  const {stateUserFlow, handlerSetUser} = useContext(UserContext)
  const navigate = useNavigate()
  const {tabId, stageId} = useParams()
  const [isVisibleFinish, setIsVisibleFinish] = useState(false)
  const {state} = useContext(DataContext)

  const {refetch} = useQuery(GET_SCENARIOS_DATA, {
    variables: {scenarioId: state.scenarioActive},
    skip: true
  })

  const checkSetTab = async (value) => {
    // get data for  modal
    const {data} = await refetch({
      scenarioId: state.scenarioActive,
      historyScenarioId: state.historyId,
      testSubjectId: state.user_id
    })
    const dataQuestionBeforeDrive = formMissedQuestions(getFragenStep(data, 1))

    // show modal if not answered before SUSI
    if (
      tabId === "1" &&
      Number(stageId) === before &&
      dataQuestionBeforeDrive.length !== 0 &&
      value > 0
    ) {
      setIsVisibleFinish(true)
      return
    }

    // set Fragen viewed
    if (!stateUserFlow.isFragenViewed.includes(`${tabId}${stageId}`))
      handlerSetUser({
        isFragenViewed: [...stateUserFlow.isFragenViewed, `${tabId}${stageId}`]
      })

    if (tabId === "1" && Number(stageId) >= 0 && Number(stageId) <= before) {
      if (value > 0) {
        navigate(`/drive/1/${Number(stageId) + 1}`)
      }
      if (value < 0) {
        navigate(`/drive/1/${Number(stageId) - 1}`)
      }
    }
    if (tabId === "1" && Number(stageId) === before) {
      if (value > 0) {
        navigate(`/drive/2/0`)
      }
    }

    if (tabId === "3" && Number(stageId) >= 0 && Number(stageId) <= after + 1) {
      if (value > 0) {
        navigate(`/drive/3/${Number(stageId) + 1}`)
      }
      if (value < 0) {
        navigate(`/drive/3/${Number(stageId) - 1}`)
      }
    }
    if (tabId === "3" && Number(stageId) === 0) {
      if (value < 0) {
        navigate(`/drive/2/0`)
      }
    }
  }
  // for appear modal
  const modalBeforeDrive = handlerModalBeforeDrive(
    setIsVisibleFinish,
    isVisibleFinish,
    2
  )

  return (
    <>
      {modalBeforeDrive}
      <Styled.ButtonBox>
        {!(tabId === "1" && stageId === "0") && (
          <Styled.ButtonNoFill onClick={() => checkSetTab(-1)}>
            Vorherige{" "}
          </Styled.ButtonNoFill>
        )}
        {!(tabId === "3" && Number(stageId) === after + 1) && (
          <Styled.ButtonFill onClick={() => checkSetTab(1)}>
            {" "}
            Nächste
          </Styled.ButtonFill>
        )}
        {tabId === "3" && Number(stageId) === after + 1 && (
          <Styled.ButtonFill onClick={handleClick}>
            Testszenario beenden
          </Styled.ButtonFill>
        )}
      </Styled.ButtonBox>
    </>
  )
}

export default Pagination
