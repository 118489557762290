import {useMutation, useQuery} from "@apollo/client"
import {Button, Divider, Input, message, Select, Space} from "antd"
import React, {useContext, useEffect, useState, useRef} from "react"
import {useNavigate} from "react-router-dom"

import {
  DELETE_VEHICLES,
  GET_VEHICLES_LIST,
  INSERT_VEHICLES,
  INSERT_VEHICLES_MANUFACTURERS,
  DELETE_AND_INSERT_VEHICLES_DRIVING_LICENSES,
  INSERT_VEHICLES_DRIVING_LICENSES,
  UPDATE_VEHICLES,
  DELETE_VEHICLES_AND_DRIVING_LICENSES,
  DELETE_VEHICLES_MANUFACTURERS
} from "../../../const/const"
import {DataContext} from "../../../context/context"
import {isAuthDataWithoutParameter} from "../../../middleware/auth"
import {
  getIdForLicenses,
  isEquelTwoObj,
  isFilledRequiredFields,
  isValueObjChange
} from "../../../middleware/math"

import {Styled} from "./vehicle-view.styled"

const {Option} = Select

const columns = [
  {
    title: "Kennzeichen",
    dataIndex: "name",
    width: 50
  },
  {
    title: "Typ",
    dataIndex: "type",
    align: "center",
    width: 50
  }
]

const VehicleView = () => {
  const navigate = useNavigate()

  // for table
  const [dataSource, setDataSource] = useState([])

  // for selected row
  const [selectedVehicleData, setSelectedVehicleData] = useState([])
  const [selectedVehiclePrevData, setSelectedVehiclePrevData] = useState([])
  const {state, handlerSet} = useContext(DataContext)
  const [statePlateError, setStatePlateError] = useState(false)
  const inputRef = useRef(null)

  const [name, setName] = useState("")

  const onNameChange = (event) => {
    setName(event.target.value)
  }

  const addItem = (e) => {
    e.preventDefault()

    if (
      selectedVehicleData.vehicle_manufacturersAll.filter(
        (item) => item.name === name
      )[0] !== undefined
    ) {
      message.error("Dieser Fahrzeughersteller existiert bereits")
      setName("")
      return
    }

    setSelectedVehicleData((prev) => ({
      ...prev,
      vehicle_manufacturersAll: [
        ...prev.vehicle_manufacturersAll,
        {id: -prev.vehicle_manufacturersAll.length - 1, name}
      ],
      vehicle_manufacturersValue: -prev.vehicle_manufacturersAll.length - 1
    }))

    setName("")

    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const {
    data,
    loading,
    refetch: refetchGetVehicleList
  } = useQuery(GET_VEHICLES_LIST, {fetchPolicy: "no-cache"})

  const [mutationInsertVehicleManufacturers] = useMutation(
    INSERT_VEHICLES_MANUFACTURERS
  )
  const [mutationInsertVehicle] = useMutation(INSERT_VEHICLES)
  const [mutationDeleteInsertVehicleDrivingLicenses] = useMutation(
    DELETE_AND_INSERT_VEHICLES_DRIVING_LICENSES
  )
  const [mutationInsertVehicleDrivingLicenses] = useMutation(
    INSERT_VEHICLES_DRIVING_LICENSES
  )
  const [mutationUpdateVehicle] = useMutation(UPDATE_VEHICLES)
  const [mutationDeleteVehicle] = useMutation(DELETE_VEHICLES)
  const [mutationDeleteVehicleAndDriving] = useMutation(
    DELETE_VEHICLES_AND_DRIVING_LICENSES
  )

  const [mutationDeleteVehicleManufacturers] = useMutation(
    DELETE_VEHICLES_MANUFACTURERS
  )

  const setDefaultState = () => {
    setStatePlateError(false)
    setSelectedVehicleData({
      vehicle_manufacturersAll: data.vehicle_manufacturers,
      vehicles_driving_licenses: data.driving_licenses,
      vehicles_driving_licensesValue: [],
      vehicle_manufacturersValue: null
    })
  }

  const handleDataInsert = async () => {
    setStatePlateError(false)

    let resInsertManufacturers
    let resInsertCar
    let resLicense
    try {
      if (selectedVehicleData.vehicle_manufacturersValue < 0) {
        resInsertManufacturers = await mutationInsertVehicleManufacturers({
          variables: {
            manufacturersName:
              selectedVehicleData.vehicle_manufacturersAll.filter(
                (item) =>
                  item.id === selectedVehicleData.vehicle_manufacturersValue
              )[0].name
          }
        })
      }

      resInsertCar = await mutationInsertVehicle({
        variables: {
          max_speed: selectedVehicleData.max_speed,
          model_name: " test model_name",
          motor_type: selectedVehicleData.type,
          name: selectedVehicleData.name,
          notes: selectedVehicleData.notes,
          plate: selectedVehicleData.plate,
          vin: "test vin",
          manufacturer_id:
            selectedVehicleData.vehicle_manufacturersValue < 0
              ? resInsertManufacturers.data.insert_vehicle_manufacturers
                  .returning[0].id
              : selectedVehicleData.vehicle_manufacturersValue
        }
      })

      // get id for licenses
      const vehicles_driving_licensesValue_Id =
        getIdForLicenses(selectedVehicleData)

      const objectsArr = vehicles_driving_licensesValue_Id.map(
        (itemLicense) => ({
          license_id: itemLicense.id,
          vehicle_id: resInsertCar.data.insert_vehicles.returning[0].id
        })
      )

      resLicense = await mutationInsertVehicleDrivingLicenses({
        variables: {
          objects: objectsArr
        }
      })

      message.success("Das Fahrzeug würde erfolgreich gespeichert")
      await refetchGetVehicleList()
      setDefaultState()
    } catch (err) {
      // Uniqueness violation. duplicate key value violates unique constraint "vehicles_plate_key"
      if (err.graphQLErrors[0].extensions.code === "constraint-violation") {
        message.error("Wiederholte Bezeichnung oder Marke")
        setStatePlateError(true)

        return
      }

      if (!window.navigator.onLine) message.error("Keine Internetverbindung")
      else if (
        (selectedVehicleData.vehicle_manufacturersValue < 0 &&
          resInsertManufacturers !== undefined) ||
        resInsertCar === undefined ||
        resLicense === undefined
      )
        message.error("Fehler beim Verbinden mit der Datenbank")

      // delete manufacturers id new and no add vehicle
      if (
        resInsertCar === undefined &&
        selectedVehicleData.vehicle_manufacturersValue < 0 &&
        resInsertManufacturers !== undefined
      ) {
        await mutationDeleteVehicleManufacturers({
          variables: {
            manufacturer_id:
              resInsertManufacturers.data.insert_vehicle_manufacturers
                .returning[0].id
          }
        })
      }
    }
  }

  const handleDataUpdate = async () => {
    setStatePlateError(false)

    if (selectedVehicleData.id === undefined) return

    let resInsertManufacturers
    let resUpdateCar
    let resLicense
    try {
      if (selectedVehicleData.vehicle_manufacturersValue < 0) {
        resInsertManufacturers = await mutationInsertVehicleManufacturers({
          variables: {
            manufacturersName:
              selectedVehicleData.vehicle_manufacturersAll.filter(
                (item) =>
                  item.id === selectedVehicleData.vehicle_manufacturersValue
              )[0].name
          }
        })
      }

      resUpdateCar = await mutationUpdateVehicle({
        variables: {
          id: selectedVehicleData.id,
          max_speed: selectedVehicleData.max_speed,
          model_name: " test model_name",
          motor_type: selectedVehicleData.type,
          name: selectedVehicleData.name,
          notes: selectedVehicleData.notes,
          plate: selectedVehicleData.plate,
          vin: "test vin",
          manufacturer_id:
            selectedVehicleData.vehicle_manufacturersValue < 0
              ? resInsertManufacturers.data.insert_vehicle_manufacturers
                  .returning[0].id
              : selectedVehicleData.vehicle_manufacturersValue
        }
      })

      // get id for licenses
      const vehicles_driving_licensesValue_Id =
        getIdForLicenses(selectedVehicleData)

      const objectsArr = vehicles_driving_licensesValue_Id.map(
        (itemLicense) => ({
          license_id: itemLicense.id,
          vehicle_id: selectedVehicleData.id
        })
      )

      resLicense = await mutationDeleteInsertVehicleDrivingLicenses({
        variables: {
          id: selectedVehicleData.id,
          objects: objectsArr
        }
      })

      message.success("Daten erfolgreich aktualisiert")
      await refetchGetVehicleList()
      setDefaultState()
    } catch (err) {
      // Uniqueness violation. duplicate key value violates unique constraint "vehicles_plate_key"
      if (err.graphQLErrors[0].extensions.code === "constraint-violation") {
        message.error("Wiederholte Bezeichnung / Marke")
        setStatePlateError(true)

        return
      }

      if (!window.navigator.onLine) message.error("Keine Internetverbindung")
      else if (
        (selectedVehicleData.vehicle_manufacturersValue < 0 &&
          resInsertManufacturers !== undefined) ||
        resUpdateCar === undefined ||
        resLicense === undefined
      )
        message.error("Fehler beim Verbinden mit der Datenbank")

      // delete manufacturers id new and no add vehicle
      if (
        resUpdateCar === undefined &&
        selectedVehicleData.vehicle_manufacturersValue < 0 &&
        resInsertManufacturers !== undefined
      ) {
        await mutationDeleteVehicleManufacturers({
          variables: {
            manufacturer_id:
              resInsertManufacturers.data.insert_vehicle_manufacturers
                .returning[0].id
          }
        })
      }
    }
  }

  // insert or update
  const handleDataSave = async () => {
    if (selectedVehicleData.id === undefined) {
      await handleDataInsert()
    } else {
      await handleDataUpdate()
    }
  }

  // delete vehicle
  const handleDataDelete = async () => {
    if (selectedVehicleData.id === undefined) return

    await mutationDeleteVehicleAndDriving({
      variables: {vehicleId: Number(selectedVehicleData.id)}
    })
      .then(() => message.info("Szenario wurde gelöscht"))
      .catch((err) => {
        message.error("Szenario könnte nicht gelöscht werden")
        if (err.response.status === 403) {
          isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
            if (dataRes)
              mutationDeleteVehicle({
                variables: {vehicleId: selectedVehicleData.id}
              }).catch(() => {
                message.error("Szenario kann nicht gelöscht werden")
                navigate("/login")
              })
            else navigate("/login")
          })
        }
      })

    await refetchGetVehicleList()
    setDefaultState()
  }

  const handleAbort = () => {
    setStatePlateError(false)
    if (selectedVehicleData.id === undefined) setDefaultState()
    else {
      setSelectedVehicleData(selectedVehiclePrevData)
    }
  }

  // update group
  const handleChangeData = (value, key) => {
    setSelectedVehicleData((prev) => ({
      ...prev,
      [key]: Number(value) < 0 ? 0 : value
    }))
  }

  const handleNewCar = () => {
    setStatePlateError(false)
    const obj = {
      vehicle_manufacturersAll: data.vehicle_manufacturers,
      vehicles_driving_licenses: data.driving_licenses,
      vehicles_driving_licensesValue: [],
      vehicle_manufacturersValue: null
    }

    setSelectedVehicleData(obj)
    setSelectedVehiclePrevData(obj)
  }

  // change and set dataSource for table
  useEffect(() => {
    if (!data) return

    setDataSource(
      data.vehicles.map((item) => ({
        ...item,
        type: `${item.motor_type}`,
        key: `${item.id}`
      }))
    )
    handleNewCar()
  }, [data])

  const handleSelect = (value) => {
    setSelectedVehicleData((prev) => ({
      ...prev,
      vehicles_driving_licensesValue: [
        ...prev.vehicles_driving_licensesValue,
        value
      ]
    }))
  }

  const handleDeselect = (value) => {
    setSelectedVehicleData((prev) => ({
      ...prev,
      vehicles_driving_licensesValue:
        selectedVehicleData.vehicles_driving_licensesValue.filter(
          (item) => item !== value
        )
    }))
  }

  const handleSetDataFromRecord = (id) => {
    setStatePlateError(false)

    const vehicle = data.vehicles.filter(
      (itemVehicle) => itemVehicle.id === id
    )?.[0]

    const StateObj = {
      plate: vehicle.plate,
      name: vehicle.name,
      id: vehicle.id,
      type: vehicle.motor_type,
      max_speed: vehicle.max_speed,
      notes: vehicle.notes,
      vehicles_driving_licenses: data.driving_licenses,
      vehicles_driving_licensesValue: vehicle.vehicles_driving_licenses.map(
        (itemLicense) => itemLicense.driving_licence.name
      ),
      vehicle_manufacturersAll: data.vehicle_manufacturers,
      vehicle_manufacturersValue: vehicle.vehicle_manufacturer?.id,
      vin: vehicle.vin,
      vehicles_driving_licensesId: vehicle.vehicles_driving_licenses
    }
    setSelectedVehicleData(StateObj)
    setSelectedVehiclePrevData(StateObj)
  }

  const isSaveAllow =
    !isFilledRequiredFields(selectedVehiclePrevData, selectedVehicleData) ||
    !isEquelTwoObj(selectedVehiclePrevData, selectedVehicleData) ||
    !isValueObjChange(selectedVehiclePrevData, selectedVehicleData)

  const isAbort =
    !isEquelTwoObj(selectedVehiclePrevData, selectedVehicleData) ||
    !isValueObjChange(selectedVehiclePrevData, selectedVehicleData)

  return (
    <Styled.Box>
      <Styled.InsideBox>
        <Styled.VehicleListBox>
          <Styled.DescriptionBox>
            <Styled.DescriptionTitle>
              Vorhandene Fahrzeuge
            </Styled.DescriptionTitle>
            <Styled.DescriptionButtonBox onClick={() => handleNewCar()}>
              <Styled.DescriptionButtonIcon>+</Styled.DescriptionButtonIcon>
              <Styled.DescriptionButton>Fahrzeug</Styled.DescriptionButton>
            </Styled.DescriptionButtonBox>
          </Styled.DescriptionBox>
          {dataSource.length > 0 && (
            <Styled.ListBoxStyle>
              <Styled.ListBoxTitle>
                <Styled.TableStyled
                  id="carTable"
                  loading={loading}
                  dataSource={dataSource}
                  columns={columns}
                  show
                  pagination={{
                    responsive: true,
                    total: dataSource.length
                  }}
                  onRow={(record) => ({
                    onClick: () => handleSetDataFromRecord(record.id)
                  })}
                />
              </Styled.ListBoxTitle>
            </Styled.ListBoxStyle>
          )}
        </Styled.VehicleListBox>
        <Styled.VehicleEdit>
          <Styled.DescriptionBox>
            <Styled.DescriptionTitle>Fahrzeugdaten</Styled.DescriptionTitle>
          </Styled.DescriptionBox>
          <Styled.VehicleEditSelectGroup>
            <Styled.VehicleEditGroup $isPaddingRight>
              <Styled.VehicleEditInputLabel>
                Bezeichnung / Marke
              </Styled.VehicleEditInputLabel>

              <Styled.VehicleEditInput
                id="plate"
                value={selectedVehicleData.plate}
                onChange={(e) => handleChangeData(e.target.value, "plate")}
                $isError={statePlateError}
              />
            </Styled.VehicleEditGroup>

            <Styled.VehicleEditGroup>
              <Styled.VehicleEditInputLabel>
                Kennzeichen
              </Styled.VehicleEditInputLabel>
              <Styled.VehicleEditInput
                id="name"
                value={selectedVehicleData.name}
                onChange={(e) => handleChangeData(e.target.value, "name")}
              />
            </Styled.VehicleEditGroup>
          </Styled.VehicleEditSelectGroup>
          {statePlateError && <Styled.Error>Must be unique</Styled.Error>}
          <Styled.VehicleEditSelectGroup>
            <Styled.VehicleSelectGroup $isPaddingRight>
              <Styled.VehicleSelectLabel>Antrieb</Styled.VehicleSelectLabel>
              <Styled.VehicleSelect
                id="type"
                defaultValue="Choose..."
                style={{width: 120}}
                value={selectedVehicleData.type}
                onChange={(id) => handleChangeData(id, "type")}
              >
                <Styled.VehicleOption key="Elektro" value="Elektro">
                  Elektro
                </Styled.VehicleOption>
                <Styled.VehicleOption key="Verbrenner" value="Verbrenner">
                  Verbrenner
                </Styled.VehicleOption>
              </Styled.VehicleSelect>
            </Styled.VehicleSelectGroup>
            <Styled.VehicleEditGroup>
              <Styled.VehicleEditInputLabel>
                Leistung
              </Styled.VehicleEditInputLabel>
              <Styled.VehicleEditInput
                id="max_speed"
                type="number"
                min={0}
                value={selectedVehicleData.max_speed}
                onChange={(e) => handleChangeData(e.target.value, "max_speed")}
              />
            </Styled.VehicleEditGroup>
          </Styled.VehicleEditSelectGroup>
          <Styled.VehicleEditSelectGroup>
            <Styled.VehicleEditGroup $isPaddingRight>
              <Styled.VehicleEditInputLabel>
                Anmerungen
              </Styled.VehicleEditInputLabel>
              <Styled.VehicleEditTextArea
                id="notes"
                autoSize={{minRows: 2}}
                value={selectedVehicleData.notes}
                onChange={(e) => handleChangeData(e.target.value, "notes")}
              />
            </Styled.VehicleEditGroup>
            <Styled.VehicleSelectGroup>
              <Styled.VehicleSelectLabel>
                Fahrzeughersteller
              </Styled.VehicleSelectLabel>
              <Styled.VehicleSelect
                id="vehicle_manufacturersValue"
                defaultValue="Choose..."
                style={{width: 120}}
                value={selectedVehicleData.vehicle_manufacturersValue}
                onChange={(id) =>
                  handleChangeData(id, "vehicle_manufacturersValue")
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{margin: "8px 0"}} />
                    <Space style={{padding: "0 8px 4px"}}>
                      <Input
                        id="inputManufacturers"
                        placeholder="Neuen Hersteller eingeben"
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                      />
                      <Button
                        id="buttonManufacturers"
                        type="text"
                        onClick={addItem}
                        disabled={!name}
                      >
                        Hersteller hinzufügen
                      </Button>
                    </Space>
                  </>
                )}
              >
                {selectedVehicleData?.vehicle_manufacturersAll?.map(
                  (itemManufactures) => (
                    <Styled.VehicleOption
                      key={itemManufactures.id}
                      value={itemManufactures.id}
                    >
                      {itemManufactures.name}
                    </Styled.VehicleOption>
                  )
                )}
              </Styled.VehicleSelect>
            </Styled.VehicleSelectGroup>
          </Styled.VehicleEditSelectGroup>
          <Styled.LicenseBox>
            <Styled.LicenseTitle>Benötigter Führerschein</Styled.LicenseTitle>
            <Styled.LicenseSelectlabel>
              Bitte einen oder mehrere Führerscheine auswählen
            </Styled.LicenseSelectlabel>
            <Styled.LicenseSelectBox>
              <Styled.LicenseSelect
                id="license"
                mode="multiple"
                style={{
                  width: "100%"
                }}
                placeholder="Bitte Führerschein auswählen"
                value={selectedVehicleData?.vehicles_driving_licensesValue}
                onSelect={handleSelect}
                onDeselect={handleDeselect}
              >
                {selectedVehicleData?.vehicles_driving_licenses?.map(
                  (itemOption) => (
                    <Option key={itemOption.name}>{itemOption.name}</Option>
                  )
                )}
              </Styled.LicenseSelect>

              <Styled.LicenseSelectButton>
                <Styled.LicenseSelectButtonIcon />
              </Styled.LicenseSelectButton>
            </Styled.LicenseSelectBox>
          </Styled.LicenseBox>

          <Styled.VehicleEditButtonsGroup>
            <Styled.PopconfirmStyle
              cancelButtonProps={{type: "primary"}}
              placement="leftTop"
              title="Möchten Sie dieses Szenario wirklich löschen?"
              disabled={selectedVehicleData.id === undefined}
              onConfirm={() => handleDataDelete()}
              okText="Yes"
              cancelText="No"
            >
              <Styled.VehicleEditButtonsDelete
                id="deleteButton"
                $isDisable={selectedVehicleData.id === undefined}
              >
                Löschen
              </Styled.VehicleEditButtonsDelete>
            </Styled.PopconfirmStyle>
            <Styled.VehicleEditButtonsAbort
              id="abortButton"
              onClick={() => {
                if (!isAbort) handleAbort()
              }}
              $isDisable={isAbort}
            >
              Abbrechen
            </Styled.VehicleEditButtonsAbort>
            <Styled.VehicleEditButtonsSave
              id="saveButton"
              onClick={() => {
                if (!isSaveAllow) handleDataSave()
              }}
              $isDisable={isSaveAllow}
            >
              Speichern
            </Styled.VehicleEditButtonsSave>
          </Styled.VehicleEditButtonsGroup>
        </Styled.VehicleEdit>
      </Styled.InsideBox>
    </Styled.Box>
  )
}

export default VehicleView
