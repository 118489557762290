import React from "react"
import {ModalStyle} from "../../pages/login/StyledComponents"
import {Styled} from "./modal-unsaved-user.styled"
import SusiPortraitImg from "../../images/susi_portrait.png"

const {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  OutterRowWrapper,
  TitleBox
} = Styled

const ModalUnsavedUser = ({
  closable,
  isVisibleFinish,
  setIsVisibleFinish,
  handleOnConfirmYes,
  handleSaveDataIfNoSaved,
  setRowId
}) => {
  const handleClose = () => {
    setIsVisibleFinish(false)
  }

  return (
    <>
      <ModalStyle
        visible={isVisibleFinish}
        footer={[]}
        centered
        closable={closable}
        afterClose={handleClose}
      >
        <OutterRowWrapper>
          <LeftSideFormContainer>
            <SusiPortraitWrapper
              justify="space-evenly"
              align="middle"
              wrap={false}
            >
              <TitleBox>
                <OrangeText>Hallo, ich bin SUSI.</OrangeText>
              </TitleBox>
              <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
            </SusiPortraitWrapper>
            <FormContainer>
              <Styled.Description>
                Möchten Sie die Daten speichern?
              </Styled.Description>

              <Styled.ButtonsGroup>
                <Styled.ButtonStyled
                  onClick={() => {
                    handleSaveDataIfNoSaved()
                      .then(() => {
                        handleOnConfirmYes()
                      })
                      .catch((err) => {
                        setRowId(null)
                      })
                      .finally(() => handleClose())
                  }}
                >
                  Ja, speichern
                </Styled.ButtonStyled>
                <Styled.ButtonStyled
                  onClick={() => {
                    handleOnConfirmYes()
                    handleClose()
                  }}
                >
                  Nein
                </Styled.ButtonStyled>
              </Styled.ButtonsGroup>
            </FormContainer>
          </LeftSideFormContainer>
        </OutterRowWrapper>
      </ModalStyle>
    </>
  )
}

export default ModalUnsavedUser
