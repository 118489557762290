import React from "react"
import {Styled} from "./button-name-style.styled"

const ButtonNameStyle = ({
  id,
  name,
  color,
  background,
  handleDataSet,
  status
}) => (
  <Styled.ButtonBox>
    <Styled.ButtonStyle
      id={`mainTable${id}`}
      $backgroundcolor={background}
      $color={color}
      onClick={() => handleDataSet()}
    >
      {name}
    </Styled.ButtonStyle>
  </Styled.ButtonBox>
)

export default ButtonNameStyle
