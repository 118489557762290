import React from "react"
import UsersView from "../../pages/admin/UsersView"
import ScenariosView from "../../pages/admin/scenariosView"
import ExportView from "../../pages/admin/exportView"
import VehicleView from "../../pages/admin/vehicleView"

const menuItems = [
  {
    title: "users",
    textTranslationHandle: "menu.users",
    path: "users",
    element: <UsersView />
  },
 /* FKARLI-345, in order to add the menu back, remove this comment.
  {
    title: "vehicle",
    textTranslationHandle: "menu.vehicle",
    path: "vehicle",
    element: <VehicleView />
  },
   */
  {
    title: "scenarios",
    textTranslationHandle: "menu.scenarios",
    path: "scenarios",
    element: <ScenariosView />
  },
  {
    title: "export",
    textTranslationHandle: "menu.exportData",
    path: "export",
    element: <ExportView />
  }
]

export default menuItems
