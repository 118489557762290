import styled from "styled-components"

export const AchtungBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  height: 73px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fde1e1;
  border: 1px solid #fcd2d2;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  color: #924040;

  transition: 0.4s;

  border: 1px solid red;
  margin: 30px 0;

  @media screen and (max-width: 650px) {
    margin: 0px;
  }

  ${({isShow}) =>
    isShow
      ? `
    height: 73px;
    background: #fde1e1;
    border: 1px solid #fcd2d2;
    `
      : `
    height: 0px;
    background: white;
    border: 1px solid white;
    margin: 0px;
  `}
`

export const VertAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 5px;
`

export const Bold = styled.div`
  display: flex;
  font-weight: 700;
  flex-direction: column;
  justify-content: center;
`

export const TempDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
`

export const Styled = {
  AchtungBox,
  VertAlign,
  Bold,
  TempDiv
}
