import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 111px;
  border-radius: 4px;
  min-width: 55px;
`

export const BoxDropDown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`

export const Styled = {
  MainBox,
  BoxDropDown
}
