import React from "react"
import {useLocation} from "react-router-dom"
import {ModalStyle} from "../../pages/login/StyledComponents"
import {Styled} from "./modal-scenario-list.styled"
import SusiPortraitImg from "../../images/susi_portrait.png"

const {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  OutterRowWrapper,
  TitleBox,
  DescriptionBox,
  TextBox
} = Styled

const ModalScenarioList = ({text, description}) => {
  const location = useLocation()

  return (
    <>
      <ModalStyle
        visible={location.pathname === "/drive"}
        footer={[]}
        centered
        closable={false}
      >
        <OutterRowWrapper>
          <LeftSideFormContainer>
            <SusiPortraitWrapper
              justify="space-evenly"
              align="middle"
              wrap={false}
            >
              <TitleBox>
                <OrangeText>Hallo, ich bin SUSI.</OrangeText>
              </TitleBox>
              <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
            </SusiPortraitWrapper>
            <FormContainer>
              <TextBox>{text}</TextBox>
              <DescriptionBox>{description}</DescriptionBox>
            </FormContainer>
          </LeftSideFormContainer>
        </OutterRowWrapper>
      </ModalStyle>
    </>
  )
}

export default ModalScenarioList
