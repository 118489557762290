import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  height: 59px;
  background: #f2f2f5;
  flex-direction: row;
  align-item: center;
  justify-content: space-between;
  background: #f8f8fb;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
`

export const BoxTab = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  cursor: pointer;
`

export const TabLight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 33.333%;
  cursor: pointer;
  background: rgba(250, 186, 21, 0.098);
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  transition: background-color 2s ease-out;
`

export const TabDark = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 33.333%;
  cursor: pointer;
  background: rgba(250, 186, 21, 0.098);
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);

  transition: 0.3s;
  ${({active}) =>
    active &&
    `
        background: rgba(250, 186, 21, 0.2);
    `}
`

export const SvgContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: auto;
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 20px;
`

export const Svg = styled.svg`
  position: relative;
  width: auto;
  height: 50px;
  max-height: 32px;
  z-index: 1;
  transition: 0.3s;
`

export const TitleSvg = styled.div`
  position: relative;
`

export const TitleSpan = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #faba15;

  @media screen and (max-width: 656px) {
    font-size: 10px;
    margin-top: 7px;
  }

  ${({active}) =>
    active &&
    `
        color: white;
    `}
`

export const Styled = {
  MainBox,
  BoxTab,
  TabLight,
  TabDark,
  SvgContainer,
  Svg,
  TitleSvg,
  TitleSpan
}
