import React, {useEffect} from "react"
import {Table} from "antd"
import {useBoolean, useInterval} from "react-use"
import {ModalStyle} from "../../pages/login/StyledComponents"
import {Styled} from "./modal-finish.styled"
import SusiPortraitImg from "../../images/susi_portrait.png"

const {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  OutterRowWrapper,
  TitleBox
} = Styled

const ModalFinish = ({
  closable,
  isVisibleFinish,
  setIsVisibleFinish,
  handleDataSet,
  dataSourseTable
}) => {
  const [count, setCount] = React.useState(5)
  const [delay, setDelay] = React.useState(1000)
  const [isRunning, toggleIsRunning] = useBoolean()

  useInterval(
    () => {
      setCount(count - 1)
      if (count === 1) toggleIsRunning(false)
    },
    isRunning ? delay : null
  )

  const handleClose = () => {
    setIsVisibleFinish(false)
  }

  useEffect(() => {
    toggleIsRunning(true)
    setCount(5)
  }, [isVisibleFinish])

  const columns = [
    {
      title: "Frage",

      dataIndex: "question",
      onCell: (item) => ({
        rowSpan: item.counts
      })
    },
    {
      title: "Frage text",
      dataIndex: "questionText",
      ellipsis: "true"
    }
  ]

  return (
    <>
      <ModalStyle
        visible={isVisibleFinish}
        footer={[]}
        centered
        closable={closable}
        afterClose={handleClose}
      >
        <OutterRowWrapper>
          <LeftSideFormContainer>
            <SusiPortraitWrapper
              justify="space-evenly"
              align="middle"
              wrap={false}
            >
              <TitleBox>
                <OrangeText>Hallo, ich bin SUSI.</OrangeText>
              </TitleBox>
              <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
            </SusiPortraitWrapper>
            <FormContainer>
              {dataSourseTable.length === 0 ? (
                <Styled.Description>
                  Alle Fragen beantwortet, Szenariolauf kann jetzt beendet
                  werden.
                </Styled.Description>
              ) : (
                <Styled.Description>
                  Es gibt unbeantwortete Fragen:
                </Styled.Description>
              )}

              {dataSourseTable.length !== 0 && (
                <Table
                  columns={columns}
                  dataSource={dataSourseTable}
                  bordered
                  scroll={{y: 200}}
                  pagination={false}
                />
              )}

              {dataSourseTable.length === 0 ? (
                <Styled.Description>
                  Möchten Sie das aktuelle Szenario beenden?
                </Styled.Description>
              ) : (
                <Styled.Description>
                  Sind Sie sicher, dass Sie die laufende Szenarioausführung
                  beenden möchten?
                </Styled.Description>
              )}
              <Styled.ButtonsGroup>
                <Styled.ButtonStyled
                  onClick={() => handleDataSet()}
                  disabled={count > 0}
                >
                  Yes {count > 0 ? `(${count})` : ""}
                </Styled.ButtonStyled>
                <Styled.ButtonStyled onClick={() => handleClose()}>
                  No
                </Styled.ButtonStyled>
              </Styled.ButtonsGroup>
            </FormContainer>
          </LeftSideFormContainer>
        </OutterRowWrapper>
      </ModalStyle>
    </>
  )
}

export default ModalFinish
