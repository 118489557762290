import React from "react"
import {Styled} from "./button-style.styled"

const ButtonStyle = ({
  name,
  color,
  borderColor,
  borderSize,
  backgroundColor,
  width,
  height,
  borderRadius,
  handleDataSet,
  paddingLeft
}) => (
  <Styled.ButtonBox>
    <Styled.ButtonStyle
      $bordercolor={borderColor}
      $borderSize={borderSize}
      $backgroundcolor={backgroundColor}
      $width={width}
      $height={height}
      $color={color}
      $borderradius={borderRadius}
      onClick={handleDataSet}
      $paddingleft={paddingLeft}
    >
      {name}
    </Styled.ButtonStyle>
  </Styled.ButtonBox>
)

export default ButtonStyle
