import React from "react"
import {useTranslation} from "react-i18next"
import {Dropdown, Menu} from "antd"
import {DownOutlined} from "@ant-design/icons"
import {Styled} from "./languageSelector.styled"

const languages = [
  {
    code: "de",
    label: "DE"
  },
  {
    code: "en",
    label: "EN"
  }
]

const LanguageSelector = () => {
  const {t, i18n} = useTranslation()

  const handleClick = ({key}) => {
    i18n.changeLanguage(key)
  }

  const menu = (
    <Menu onClick={handleClick} onKeyDown={handleClick}>
      {languages.map((lng) => (
        <Menu.Item key={lng.code}>{lng.label}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Styled.MainBox>
      <Styled.BoxDropDown>
        <Dropdown overlay={menu} trigger={["click"]}>
          <button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            type="button"
            style={{
              background: "white",
              color: "#555B6D",
              border: 0,
              cursor: "pointer"
            }}
          >
            {i18n.language.toUpperCase()}
            <DownOutlined style={{marginLeft: "10px"}} />
          </button>
        </Dropdown>
      </Styled.BoxDropDown>
    </Styled.MainBox>
  )
}

export default LanguageSelector
