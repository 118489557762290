import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 111px;
  border-radius: 4px;
`

export const BoxMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px;
  cursor: pointer;

  ${({$currentItem}) =>
    $currentItem &&
    `
    
    text-decoration: none;
    position: relative;
 
    :after {
      content: '';

      width: 100%;
      position: absolute;
      left: 0;
      bottom: 8px;

      border-width: 0 0 1px;
      border-style: solid;
    }

    `}

  @media screen and (max-width: 1200px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media screen and (max-width: 800px) {
    margin-left: 3px;
    margin-right: 3px;
  }
`

export const Styled = {
  MainBox,
  BoxMenuItem
}
