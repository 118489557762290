/* eslint-disable no-debugger */
/* eslint-disable no-console */
import {useState, useCallback, useRef, useEffect} from "react"
import MicrophoneStream from "microphone-stream"
import getUserMedia from "get-user-media-promise"
import { WAKEWORD_TOKEN, WAKEWORD_URL } from "../../const/const"

const SOCKET_STATE = {
  UNINSTANTIATED: -1,
  CONNECTING: 0,
  OPEN: 1,
  CLOSED: 2,
  ERROR: 3
}

/**
 * Convert Float32Array to Int16Array
 *
 * This function was taken from one of many StackOverflow
 * examples, e.g.:
 * https://stackoverflow.com/questions/35234551/javascript-converting-from-int16-to-float32
 */
function convertFloat32To16BitPCM(input) {
  const output = new Int16Array(input.length)

  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]))
    output[i] = s < 0 ? s * 0x8000 : s * 0x7fff
  }

  return output
}

function wait(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const useKeywordRecognizer = () => {
  const [keywordDetection, setKeywordDetection] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isListening, setIsListening] = useState(false)
  const [error, setError] = useState(null)
  const loaded = useRef(false)
  const mic = useRef(null)
  const stream = useRef(null)
  const socketState = useRef(SOCKET_STATE.UNINSTANTIATED)
  const ws = useRef(null)

  /**
   * Initialize KeywordRecognizer client, by establishing a connection to
   * recognition backend via Websockets, setup microphone audio stream and
   * imediately start keyword recognition.
   */
  const init = useCallback(async () => {
    try {
      console.debug("Started KeywordRecognizer initialization ...")

      ws.current = new WebSocket(`${WAKEWORD_URL}?token=${WAKEWORD_TOKEN}`)

      ws.current.onopen = (evt) => {
        socketState.current = SOCKET_STATE.OPEN
      }

      ws.current.onmessage = (evt) => {
        try {
          const msg = JSON.parse(evt.data)
          if (msg.recognized === true) {
            console.debug("Recognized keyword.")
            setKeywordDetection({label: "Hey Karli (German)"})
          }
        } catch (err) {
          console.error(
            "KeywordRecognizer received message without recognition!",
            err
          )
        }
      }

      ws.current.onclose = (evt) => {
        console.debug("KeywordRecognizer websocket connection closed.")
        socketState.current = SOCKET_STATE.CLOSED
      }

      ws.current.onerror = (evt) => {
        console.error("KeywordRecognizer websocket connection failed!")
        socketState.current = SOCKET_STATE.ERROR
      }

      while (
        socketState.current === SOCKET_STATE.UNINSTANTIATED ||
        socketState.current === SOCKET_STATE.CONNECTING
      ) {
        console.debug("Waiting for websocket connection ...")

        // eslint-disable-next-line no-await-in-loop
        await wait(2000)
      }

      if (socketState.current === SOCKET_STATE.OPEN) {
        console.debug("Initializing microphone input ...")

        const audioContext = new AudioContext({
          sampleRate: 16000
        })

        mic.current = new MicrophoneStream({
          bufferSize: 1024,
          objectMode: true,
          context: audioContext
        })

        stream.current = await getUserMedia({video: false, audio: true})
        mic.current.setStream(stream.current)

        setIsListening(true)
        setIsLoaded(true)
        loaded.current = true

        console.debug("Attaching datastream eventhandler ...")
        mic.current.on("data", async (data) => {
          // TODO: check AudioContext props before sending
          const float32Data = data.getChannelData(0)
          const pcm16Bit = convertFloat32To16BitPCM(float32Data)
          await wait(100)
          ws.current.send(pcm16Bit)
        })
      } else {
        console.error("Failed to connect")
      }
    } catch (err) {
      console.error("KeywordRecognizer could not initialize!", err)
      setError(err.toString())
    }
    console.debug("KeywordRecognizer successfully initialized internally.")
  }, [])

  /**
   * Start keyword recognition by sending audio input from recording sources.
   */
  const start = useCallback(async () => {
    try {
      console.debug("Starting listening for microphone input ...")

      while (!socketState.current === SOCKET_STATE.OPEN) {
        // eslint-disable-next-line no-await-in-loop
        await wait(100)
      }

      if (socketState.current === SOCKET_STATE.OPEN && mic.current) {
        mic.current.playRecording()
        setIsListening(true)
        setError(null)
      }
    } catch (err) {
      setError(err.toString())
      setIsListening(false)
    }
  }, [socketState])

  /**
   * Stop sending audio chunks to KeywordRecognizer backend.
   */
  const stop = useCallback(async () => {
    try {
      if (!socketState.current === SOCKET_STATE.OPEN) {
        setError(
          "Failed to stop KeywordRecognizer recognize stream - engine not initialized or released!"
        )
        setIsListening(false)
        return
      }

      console.debug("Stopping KeywordRecognition audio stream output ...")
      mic.current.pauseRecording()

      setIsListening(false)
      setError(null)
    } catch (err) {
      setError(err.toString())
      setIsListening(false)
    }
  }, [])

  /**
   * Destroy KeywordRecognizer by stopping audio stream and disconnect from backend.
   */
  const release = useCallback(async () => {
    console.warn("Releasing KeywordRecognizer ...")

    if (loaded.current) {
      console.debug("Destroying KeywordRecognizer resources ...")
      mic.current.audioInput.disconnect()
      mic.current.stop()
      mic.current.destroy()
      mic.current = null
      stream.current = null

      setIsLoaded(true)
      setIsListening(false)
    }
  }, [loaded])

  return {
    keywordDetection,
    isLoaded,
    isListening,
    error,
    init,
    start,
    stop,
    release
  }
}
