import React, {useEffect, useState} from "react"
import {useMutation, useQuery} from "@apollo/client"
import {LoadingOutlined, SearchOutlined} from "@ant-design/icons"
import {message, Spin} from "antd"
import {ModalStyle} from "../../pages/login/StyledComponents"
import {Styled} from "./modal-test-group.styled"
import SusiPortraitImg from "../../images/susi_portrait.png"

import {
  DELETE_GROUP,
  GET_TEST_GROUPS_USERS_AND_GROUPS,
  INSERT_NEW_GROUP,
  UPDATE_GROUP
} from "../../const/const"
import {
  ListBoxStyle,
  ListBoxTitle,
  TableStyled
} from "../test-groups/test-groups.styled"
import ButtonToogleNameStyle from "../../common/button-toogleName-style"
import {
  PopconfirmStyle,
  VehicleEditButtonsAbort,
  VehicleEditButtonsDelete,
  VehicleEditButtonsGroup,
  VehicleEditButtonsSave
} from "../../pages/admin/vehicleView/vehicle-view.styled"
import {
  isEquelTwoObjTestGroup,
  isFilledRequiredFieldsTestGroup,
  isValueObjChangeTestGroup
} from "../../middleware/math"
import {MainContentSpinnerWrapper} from "../../pages/main/main.styled"
import "./styled.css"

const {
  LeftSideFormContainer,
  FormContainer,
  SusiPortrait,
  SusiPortraitWrapper,
  OrangeText,
  OutterRowWrapper,
  TitleBox,
  Context,
  NameLabel,
  NameInput,
  SearchInput,
  DescriptionLabel,
  DescriptionInput
} = Styled

const columns = [
  {
    title: "Benutzername",
    dataIndex: "name",
    width: 30
  },

  {
    title: "Aktion",
    dataIndex: "aktion",
    align: "center",
    width: 30
  }
]

const ModalTestGroup = ({
  closable,
  isVisibleFinish,
  setIsVisibleFinish,
  test_group_id,
  handleSetActiveKeyIndex
}) => {
  const {data} = useQuery(GET_TEST_GROUPS_USERS_AND_GROUPS, {
    fetchPolicy: "no-cache",
    variables: {test_groupsId: test_group_id}
  })

  const [mutationInsertNewGroup] = useMutation(INSERT_NEW_GROUP)
  const [mutationUpdateGroup] = useMutation(UPDATE_GROUP)
  const [mutationDelete_Group] = useMutation(DELETE_GROUP)

  const [changeData, setChangeData] = useState({})
  const [prevData, setPrevData] = useState({})
  const [searchTextState, setSearchTextState] = useState("")
  const [isSaveData, setIsSaveData] = useState(false)
  const [rowId, setRowId] = useState()
  const [rowData, setRowData] = useState()

  // inside func changeData,prevData = undefined, (prev)!=undefined
  const addToArrSelectedUser = (userId, userType) => {
    setChangeData((prev) => {
      /* eslint-disable  */
      if (
        !prev.selectedUserArr?.some(
          (item) => item.userId === userId && item.userType === userType
        )
      )
        return {
          ...prev,
          selectedUserArr: [...prev.selectedUserArr, {userId, userType}]
        }
      else
        return {
          ...prev,
          selectedUserArr: prev.selectedUserArr?.filter(
            (item) => !(item.userId === userId && item.userType === userType)
          )
        }
    })
  }
  useEffect(() => {
    if (!data?.test_groups) return

    const get_all_filtered_users_in_one_array = [
      ...data?.driversAll.filter(
        ({user_name: username1}) =>
          !data?.drivers.some(
            ({user_name: username2}) => username1 === username2
          )
      ),
      ...data?.test_subjectsAll.filter(
        ({user_name: username1}) =>
          !data?.test_subjects.some(
            ({user_name: username2}) => username1 === username2
          )
      ),
      ...data?.usersAll.filter(
        ({user_name: username1}) =>
          !data?.users.some(({user_name: username2}) => username1 === username2)
      )
    ]

    const userType = {
      drivers: "Fahrer",
      test_subjects: "Proband",
      users: "TestLeiter"
    }

    const dataSource = get_all_filtered_users_in_one_array
      .map((item, index) => ({
        key: index,
        name: `${item.user_name}`,
        /* eslint-disable  */
        table: item.__typename,
        aktion: (
          <ButtonToogleNameStyle
            id={index}
            name="Hinzufügen"
            color="#F1B44C"
            background="transparent"
            handleDataSet={() => addToArrSelectedUser(item.id, item.__typename)}
            selectedUser={changeData.selectedUserArr}
            userId={item.id}
            userType={item.__typename}
          />
        )
      }))
      .filter(
        (item) =>
          item.name.toLowerCase().includes(searchTextState.toLowerCase()) ||
          searchTextState === ""
      )

    // if update  group
    if (test_group_id !== 0) {
      setChangeData({
        name:
          changeData.name === undefined
            ? data.test_groups[0].name
            : changeData.name,
        description:
          changeData.description === undefined
            ? data.test_groups[0].description
            : changeData.description,
        dataSource,
        selectedUserArr:
          changeData.selectedUserArr === undefined
            ? []
            : changeData.selectedUserArr
      })
      setPrevData({
        name: data.test_groups[0].name,
        description: data.test_groups[0].description,
        dataSource,
        selectedUserArr: []
      })
    } else {
      // if insert  group
      setChangeData({
        name: changeData.name === undefined ? "" : changeData.name,
        description:
          changeData.description === undefined ? "" : changeData.description,
        dataSource,
        selectedUserArr:
          changeData.selectedUserArr === undefined
            ? []
            : changeData.selectedUserArr
      })
      setPrevData({
        dataSource,
        selectedUserArr: []
      })
    }
  }, [data, changeData.selectedUserArr, searchTextState])

  const handleClose = () => {
    setChangeData({})
    setIsVisibleFinish(false)
  }
  const handleAbort = () => {
    setChangeData(prevData)
    setSearchTextState("")
  }
  const handleChangeData = (value, key) => {
    setChangeData((prev) => ({
      ...prev,
      [key]: value
    }))
  }
  const handleDataSave = async () => {
    setIsSaveData(true)
    if (test_group_id === 0) {
      try {
        const res = await mutationInsertNewGroup({
          variables: {
            name: changeData.name,
            description: changeData.description
          }
        })

        const objects = changeData.selectedUserArr.map((item) => ({
          test_group_id: res.data.insert_test_groups.returning[0].id,
          test_subject_id:
            item.userType === "test_subjects" ? item.userId : null,
          user_id: item.userType === "users" ? item.userId : null,
          driver_id: item.userType === "drivers" ? item.userId : null
        }))

        await mutationUpdateGroup({
          variables: {
            test_groupId: res.data.insert_test_groups.returning[0].id,
            name: changeData.name,
            description: changeData.description,
            objects
          }
        })
        handleSetActiveKeyIndex(res.data.insert_test_groups.returning[0].id)
      } catch (err) {
        setIsSaveData(false)
      }
    } else {
      const objects = changeData.selectedUserArr.map((item) => ({
        test_group_id: test_group_id,
        test_subject_id: item.userType === "test_subjects" ? item.userId : null,
        user_id: item.userType === "users" ? item.userId : null,
        driver_id: item.userType === "drivers" ? item.userId : null
      }))

      try {
        await mutationUpdateGroup({
          variables: {
            test_groupId: test_group_id,
            name: changeData.name,
            description: changeData.description,
            objects
          }
        })
      } catch (err) {
        setIsSaveData(false)
      }
    }
    setIsSaveData(false)
    handleClose()
  }
  const handleDataDelete = async () => {
    try {
      await mutationDelete_Group({
        variables: {
          test_groupId: test_group_id
        }
      })
      handleClose()
    } catch (err) {
      message.error("Sie müssen zuerst die Szenario-Tabelle löschen")
    }
  }

  const isSaveAllow =
    !isFilledRequiredFieldsTestGroup(prevData, changeData) ||
    !isEquelTwoObjTestGroup(prevData, changeData) ||
    !isValueObjChangeTestGroup(prevData, changeData) ||
    isSaveData

  const isAbort =
    (!isEquelTwoObjTestGroup(prevData, changeData) ||
      !isValueObjChangeTestGroup(prevData, changeData) ||
      isSaveData) &&
    searchTextState === ""

  const handleSearch = (searchText) => {
    setSearchTextState(searchText)
    if (searchText !== "") {
      setChangeData((prev) => ({
        ...prev,
        dataSource: prevData.dataSource.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase())
        )
      }))
    } else setChangeData(changeData)
  }
  return (
    <>
      <ModalStyle
        visible={isVisibleFinish}
        footer={[]}
        centered
        closable={closable}
        afterClose={handleClose}
      >
        <OutterRowWrapper>
          <LeftSideFormContainer>
            <SusiPortraitWrapper
              justify="space-evenly"
              align="middle"
              wrap={false}
            >
              <TitleBox>
                <OrangeText>Hallo, ich bin SUSI.</OrangeText>
              </TitleBox>
              <SusiPortrait src={SusiPortraitImg} alt="SusiPortrait" />
            </SusiPortraitWrapper>
            {changeData?.dataSource !== undefined ? (
              <FormContainer>
                <Context>
                  <NameLabel>Name</NameLabel>
                  <NameInput
                    id="nameModal"
                    placeholder="Name eingeben"
                    value={changeData.name}
                    onChange={(e) => handleChangeData(e.target.value, "name")}
                    disabled={isSaveData}
                  />

                  <DescriptionLabel>Description</DescriptionLabel>
                  <DescriptionInput
                    id="descriptionModal"
                    placeholder="Beschreibung eingeben"
                    value={changeData.description}
                    onChange={(e) =>
                      handleChangeData(e.target.value, "description")
                    }
                    disabled={isSaveData}
                  />
                </Context>

                <SearchInput
                  id="searchModal"
                  placeholder="Benutzer suchen"
                  prefix={<SearchOutlined />}
                  value={searchTextState}
                  onChange={(e) => handleSearch(e.target.value)}
                  disabled={isSaveData}
                />
                <Styled.TableBox $isDisabled={isSaveData}>
                  <ListBoxStyle>
                    <ListBoxTitle>
                      <TableStyled
                        id="usersTableModal"
                        dataSource={changeData.dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{y: 200}}
                        onRow={(record, index) => ({
                          onClick: () => {
                            setRowId(index)
                            setRowData(record)
                          }
                        })}
                        rowClassName={(record, index) =>
                          record?.name === rowData?.name &&
                          record?.table === rowData?.table &&
                          rowId !== null
                            ? "table-row-dark"
                            : "table-row-light"
                        }
                      />
                    </ListBoxTitle>
                  </ListBoxStyle>
                </Styled.TableBox>

                <VehicleEditButtonsGroup>
                  <PopconfirmStyle
                    cancelButtonProps={{type: "primary"}}
                    placement="leftTop"
                    title="Möchten Sie dieses Szenario wirklich löschen?"
                    disabled={!test_group_id || isSaveData}
                    onConfirm={() => handleDataDelete()}
                    okText="Ja"
                    cancelText="Nein"
                  >
                    <VehicleEditButtonsDelete
                      id="deleteButtonModal"
                      $isDisable={!test_group_id || isSaveData}
                    >
                      Löschen
                    </VehicleEditButtonsDelete>
                  </PopconfirmStyle>
                  <VehicleEditButtonsAbort
                    id="abortButtonModal"
                    onClick={() => {
                      if (!isAbort) handleAbort()
                    }}
                    $isDisable={isAbort}
                  >
                    Abbrechen
                  </VehicleEditButtonsAbort>
                  <Styled.ButtonBoxRelative>
                    <VehicleEditButtonsSave
                      id="saveButtonModal"
                      onClick={() => {
                        if (!isSaveAllow) {
                          handleDataSave()
                          setRowId(null)
                        }
                      }}
                      $isDisable={isSaveAllow}
                    >
                      Speichern
                    </VehicleEditButtonsSave>
                    {isSaveData && <Styled.SpinStyle size="middle" />}
                  </Styled.ButtonBoxRelative>
                  <VehicleEditButtonsAbort
                    id="closeButtonModal"
                    onClick={() => {
                      handleClose()
                      setRowId(null)
                    }}
                  >
                    Schließen
                  </VehicleEditButtonsAbort>
                </VehicleEditButtonsGroup>
              </FormContainer>
            ) : (
              <MainContentSpinnerWrapper>
                <Spin indicator={<LoadingOutlined spin />} />
              </MainContentSpinnerWrapper>
            )}
          </LeftSideFormContainer>
        </OutterRowWrapper>
      </ModalStyle>
    </>
  )
}

export default ModalTestGroup
