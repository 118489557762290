import React from "react"
import {useParams} from "react-router-dom"
import HealthScale from "../../../common/questions-components/health-scale"
import RadiobuttonGroup from "../../../common/questions-components/radiobutton-group"
import Rhombus from "../../../common/questions-components/rhombus"
import SetDate from "../../../common/questions-components/set-date"
import {Styled} from "./fragen-list.styled"
import GetHealth from "../../../common/questions-components/get-health/get-health.component"
import MinutesInput from "../../../common/questions-components/minutes-input/minutes-input.component"
import AchtungQuestion from "../../../common/achtung-question/achtung.component"
import Achtung from "../../../common/achtung"

const FragenList = ({dataQuestions, before}) => {
  const {tabId, stageId} = useParams()

  return (
    <>
      {dataQuestions.map(
        (item, indexCount) =>
          indexCount - 1 === Number(stageId) - 1 && (
            <Styled.MainBox key={item.questionaire.id}>
              {/* // show AchtungQuestion only Fragen B,C */}
              {tabId === "1" && (stageId === "2" || stageId === "3") && (
                <AchtungQuestion
                  isShow
                  title="Bitte nach der ersten Fahrt ausfüllen!"
                  marginBottom={20}
                />
              )}
              {tabId === "1" && Number(stageId) === before && (
                <Achtung
                  isShow
                  title="dancer.titleTemp"
                  description="dancer.descTemp"
                />
              )}
              {item.questionaire.subtitle !== null && (
                <Styled.SubTitle>{item.questionaire.subtitle}</Styled.SubTitle>
              )}
              {item.questionaire.description !== null && (
                <Styled.Description>
                  {item.questionaire.description}
                </Styled.Description>
              )}

              {item.questionaire.questionnaires_questions.map(
                (questionItem, index, questionArr) => (
                  <Styled.QuestionBox key={questionItem.question.id}>
                    {questionItem.question.questions_type.name ===
                      "stepper" && (
                      <Rhombus
                        title={questionItem.question.title}
                        question={questionItem.question.question}
                        questionId={questionItem.question.id}
                        answer={
                          questionItem.question.test_subjects_questions_answers
                        }
                        data={questionItem.question.description}
                        lastConnected
                        sequence_id={undefined}
                        isFirst={index === 0}
                        isLast={index === questionArr.length - 1}
                      />
                    )}

                    {questionItem.question.questions_type.name ===
                      "misc-stepper" && (
                      <HealthScale
                        title={questionItem.question.title}
                        question={questionItem.question.question}
                        questionId={questionItem.question.id}
                        answer={
                          questionItem.question.test_subjects_questions_answers
                        }
                        data={questionItem.question.description}
                        lastConnected
                        sequence_id={undefined}
                        isFirst={index === 0}
                        isLast={index === questionArr.length - 1}
                      />
                    )}

                    {questionItem.question.questions_type.name === "date" && (
                      <SetDate
                        title={questionItem.question.title}
                        question={questionItem.question.question}
                        questionId={questionItem.question.id}
                        answer={
                          questionItem.question.test_subjects_questions_answers
                        }
                        data={questionItem.question.description}
                      />
                    )}
                    {questionItem.question.questions_type.name ===
                      "rectangular-radio-button" && (
                      <GetHealth
                        title={questionItem.question.title}
                        question={questionItem.question.question}
                        questionId={questionItem.question.id}
                        description={questionItem.question.description}
                        answer={
                          questionItem.question.test_subjects_questions_answers
                        }
                      />
                    )}

                    {questionItem.question.questions_type.name ===
                      "circular-radio-button" && (
                      <RadiobuttonGroup
                        title={questionItem.question.title}
                        question={questionItem.question.question}
                        questionId={questionItem.question.id}
                        options={questionItem.question.description}
                        sequence_id={undefined}
                        answer={
                          questionItem.question.test_subjects_questions_answers
                        }
                        data={questionItem.question.description}
                      />
                    )}

                    {questionItem.question.questions_type.name ===
                      "time-minutes" && (
                      <MinutesInput
                        title={questionItem.question.title}
                        question={questionItem.question.question}
                        questionId={questionItem.question.id}
                        options={questionItem.question.description}
                        sequence_id={undefined}
                        answer={
                          questionItem.question.test_subjects_questions_answers
                        }
                        data={questionItem.question.description}
                      />
                    )}
                  </Styled.QuestionBox>
                )
              )}
            </Styled.MainBox>
          )
      )}
    </>
  )
}

export default FragenList
