import React, {Suspense} from "react"
import ReactDOM from "react-dom/client"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink
} from "@apollo/client"
import "./index.css"
import "antd/dist/antd.css"
import "./helpers/languages/i18n"
import App from "./App"
import {GRAPHQL_SERVER_URL, SWUI_BE_ADMIN_PW} from "./const/const"

const root = ReactDOM.createRoot(document.getElementById("root"))

const client = new ApolloClient({
  link: new HttpLink({
    uri: GRAPHQL_SERVER_URL,
    headers: {
      "x-hasura-admin-secret": SWUI_BE_ADMIN_PW
    },
    credentials: "include"
  }),
  cache: new InMemoryCache()
})

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Suspense fallback="...is loading">
        <App />
      </Suspense>
    </ApolloProvider>
  </React.StrictMode>
)
