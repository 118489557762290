import ModalBeforeDrive from "../components/modal-before-drive"

export const getFragenStep = (data, tab) => {
  if (tab === 2) return null

  if (data !== undefined) {
    const questionsData = data.stages.filter((item) => item.id === tab)

    return questionsData[0].test_scenarios_stages[0].test_scenarios_questionnaires.map(
      (item, index) => ({
        id: index,
        name: item.questionaire.name,
        questionnaires_type: item.questionaire.questionnaires_type.name,
        questionnaires_questions: item.questionaire.questionnaires_questions
      })
    )
  }

  return []
}

export const handlerModalBeforeDrive = (
  setIsVisibleFinish,
  isVisibleFinish,
  lastTabClick
) => (
  <ModalBeforeDrive
    closable={false}
    isVisibleFinish={isVisibleFinish}
    setIsVisibleFinish={setIsVisibleFinish}
    lastTabClick={lastTabClick}
  />
)

export const formMissedQuestions = (allQuestions) =>
  allQuestions
    .map((questionItem) => {
      const {name} = questionItem
      const question = questionItem.questionnaires_questions.filter(
        (item) =>
          item.question.test_subjects_questions_answers.length === 0 &&
          item.question?.questions_type.name !== "post-ride-events" // filtered last temp question
      )
      return question.map((item, index) => ({
        question: name,
        questionText: item.question.question,
        counts: index === 0 ? question.length : 0
      }))
    })
    .reduce((prev, curr) => [...prev, ...curr], [])
    .map((itemArr, index) => ({
      key: index,
      ...itemArr
    }))
