import {useMutation, useQuery} from "@apollo/client"
import {message} from "antd"
import React, {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {
  DELETE_SCENARIOS,
  GET_SCENARIOS_LIST_ADMIN,
  INSERT_QUESTIONNAIRES,
  INSERT_QUESTIONNAIRES_QUESTIONS,
  INSERT_QUESTIONS,
  INSERT_SCENARIOS,
  INSERT_TEST_SCENARIOS_QUESTIONNAIRES,
  INSERT_TEST_SCENARIOS_STAGES,
  SELECT_QUESTIONNAIRES_QUESTIONS,
  SELECT_TEST_SCENARIOS_QUESTIONNAIRES,
  UPDATE_SCENARIOS
} from "../../../const/const"
import {DataContext} from "../../../context/context"
import {isAuthDataWithoutParameter} from "../../../middleware/auth"
import {
  questionnairesData,
  questionnaires_questions,
  questionsData,
  test_scenarios_questionnaires,
  test_scenarios_stagesData
} from "./data"
import {Styled} from "./scenarios-view.styled"

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: 37
  },
  {
    title: "Beschreibung",
    dataIndex: "description",
    align: "center",
    width: 63,
    ellipsis: "true"
  }
]

const ScenariosView = () => {
  const navigate = useNavigate()

  // for table
  const [dataSource, setDataSource] = useState([])

  // for selected row
  const [selectedScenarioData, setSelectedScenarioData] = useState([])
  const {state, handlerSet} = useContext(DataContext)

  const {
    data,
    loading,
    refetch: refetchGetScenarioList
  } = useQuery(GET_SCENARIOS_LIST_ADMIN, {fetchPolicy: "no-cache"})

  const [mutationInsertScenario] = useMutation(INSERT_SCENARIOS)
  const [mutationTest_scenarios_stages] = useMutation(
    INSERT_TEST_SCENARIOS_STAGES
  )
  const [mutationInsert_questions] = useMutation(INSERT_QUESTIONS)
  const [mutationInsert_questionnaires] = useMutation(INSERT_QUESTIONNAIRES)
  const [Insert_test_scenarios_questionnaires] = useMutation(
    INSERT_TEST_SCENARIOS_QUESTIONNAIRES
  )

  const [mutationInsert_questionnaires_questions] = useMutation(
    INSERT_QUESTIONNAIRES_QUESTIONS
  )

  const [mutationUpdateScenario] = useMutation(UPDATE_SCENARIOS)

  const {refetch: refetchSelect_Test_scenarios_questionnaires} = useQuery(
    SELECT_TEST_SCENARIOS_QUESTIONNAIRES,
    {fetchPolicy: "no-cache"}
  )
  const {refetch: refetchSelect_questionnaires_questions} = useQuery(
    SELECT_QUESTIONNAIRES_QUESTIONS,
    {fetchPolicy: "no-cache"}
  )
  const [mutationDeleteScenario] = useMutation(DELETE_SCENARIOS)

  const handleDataSaveCatch = async () => {
    // if scenario id is undefined - insert new data
    if (selectedScenarioData.id === undefined) {
      try {
        const res_scenario = await mutationInsertScenario({
          variables: {
            name: selectedScenarioData.name.trim(),
            test_group_id: selectedScenarioData.test_group_id,
            description: selectedScenarioData.description?.trim()
          }
        })

        const resStages = await mutationTest_scenarios_stages({
          variables: {
            objects: test_scenarios_stagesData(
              res_scenario.data.insert_test_scenarios.returning[0].id
            )
          }
        })

        // add questions
        const resQuestions = await mutationInsert_questions({
          variables: {
            objects: questionsData
          }
        })

        const resQuestionnaires = await mutationInsert_questionnaires({
          variables: {
            objects: questionnairesData
          }
        })

        const resQuestionnairesId =
          resQuestionnaires.data.insert_questionnaires.returning[0].id
        const diff_questionnaire = resQuestionnairesId - 1

        const resTest_scenarios_questionnaires =
          await Insert_test_scenarios_questionnaires({
            variables: {
              objects: test_scenarios_questionnaires(
                res_scenario.data.insert_test_scenarios.returning[0].id,
                diff_questionnaire,
                resStages.data.insert_test_scenarios_stages.returning[0].id
              )
            }
          })

        const diff_question =
          resQuestions.data.insert_questions.returning[0].id - 1

        await mutationInsert_questionnaires_questions({
          variables: {
            objects: questionnaires_questions(diff_question, diff_questionnaire)
          }
        })
      } catch (err) {
        console.error(err)
      }
      setSelectedScenarioData({})
    } else
      await mutationUpdateScenario({
        variables: {
          scenarioId: selectedScenarioData.id,
          name: selectedScenarioData.name.trim(),
          description: selectedScenarioData.description?.trim(),
          test_group_id: selectedScenarioData.test_group_id,
          test_track_id: 1,
          vehicle_id: 1
        }
      })
  }
  // insert or update
  const handleDataSave = async () => {
    if (
      !selectedScenarioData.name ||
      selectedScenarioData.test_group_id === undefined
    )
      return

    await handleDataSaveCatch().catch((err) => {
      console.error(err)
      if (err.response.status === 403) {
        isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
          if (dataRes)
            handleDataSaveCatch().catch((_) => {
              console.error(_)
              navigate("/login")
            })
          else navigate("/login")
        })
      }
    })

    await refetchGetScenarioList()
  }

  // delete scenario
  const handleDataDelete = async () => {
    if (selectedScenarioData.id === undefined) return
    let needToExit = false

    try {
      // select questionnaires
      const res__questionnairesIdArray =
        await refetchSelect_Test_scenarios_questionnaires({
          scenarioId: selectedScenarioData.id
        })

      // array for delete  questionnaires by id
      const questionnairesIdArray =
        res__questionnairesIdArray.data.test_scenarios_questionnaires.reduce(
          (prev, curr) => [...prev, curr.questionnaire_id],
          []
        )

      // select questions
      const res__questionsIdArray =
        await refetchSelect_questionnaires_questions({
          questionnairesArrId: questionnairesIdArray
        })

      // array for delete  questions by id
      const questionsIdArray =
        res__questionsIdArray.data.questionnaires_questions.reduce(
          (prev, curr) => [...prev, curr.question_id],
          []
        )

      await mutationDeleteScenario({
        variables: {
          scenarioId: selectedScenarioData.id,
          questionnairesArrId: questionnairesIdArray,
          questionsIdArray
        }
      })
        .then(() => message.info("Szenario wurde gelöscht"))
        .catch((err) => {
          console.error(err)
          if (err.response.status === 403) {
            isAuthDataWithoutParameter(handlerSet).then((dataRes) => {
              if (dataRes)
                mutationDeleteScenario({
                  variables: {
                    scenarioId: selectedScenarioData.id,
                    questionnairesArrId: questionnairesIdArray
                  }
                }).catch(() => {
                  needToExit = true
                  message.error("Szenario kann nicht gelöscht werden")
                  navigate("/login")
                })
              else navigate("/login")
            })
          }
        })
    } catch (err) {
      console.error(err)
    }
    if (needToExit) return

    await refetchGetScenarioList()

    setSelectedScenarioData({})
  }

  // prev data
  const prevData = data?.test_scenarios.filter(
    (itemSelect) => itemSelect.id === selectedScenarioData.id
  )[0]

  // check is any change for name, group or description
  const isDataChanged = () =>
    !(
      prevData?.name === selectedScenarioData.name &&
      prevData?.test_group_id === selectedScenarioData.test_group_id &&
      prevData?.description === selectedScenarioData.description
    )

  const handleAbort = (groupId) => {
    // if not any change break
    if (!isDataChanged()) return

    if (selectedScenarioData.id === undefined) setSelectedScenarioData({})
    else {
      setSelectedScenarioData(prevData)
    }
  }

  // update group
  const handleChangeData = (value, key) => {
    // if press button -> navigate
    if (value === "Add" && key === "test_group_id") {
      navigate("/admin/users")
      return
    }
    setSelectedScenarioData((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  // change and set dataSource for table
  useEffect(() => {
    if (!data) return

    setDataSource(
      data.test_scenarios.map((item) => ({
        ...item,
        key: `${item.id}`
      }))
    )
  }, [data])

  return (
    <Styled.Box>
      <Styled.InsideBox>
        <Styled.ScenarioListBox>
          <Styled.DescriptionBox>
            <Styled.DescriptionTitle>
              Vorhandene Szenarios
            </Styled.DescriptionTitle>
            <Styled.DescriptionButtonBox
              id="addNewScenario"
              onClick={() => setSelectedScenarioData({})}
            >
              <Styled.DescriptionButtonIcon>+</Styled.DescriptionButtonIcon>
              <Styled.DescriptionButton>Szenario</Styled.DescriptionButton>
            </Styled.DescriptionButtonBox>
          </Styled.DescriptionBox>
          {dataSource.length > 0 && (
            <Styled.ListBoxStyle>
              <Styled.ListBoxTitle>
                <Styled.TableStyled
                  loading={loading}
                  dataSource={dataSource}
                  columns={columns}
                  show
                  pagination={{
                    responsive: true,
                    total: dataSource.length
                  }}
                  onRow={(record) => ({
                    onClick: () =>
                      setSelectedScenarioData({
                        name: record.name,
                        id: record.id,
                        description: record.description,
                        test_group_id: record.test_group_id
                      })
                  })}
                />
              </Styled.ListBoxTitle>
            </Styled.ListBoxStyle>
          )}
        </Styled.ScenarioListBox>
        <Styled.ScenarioEdit>
          <Styled.ScenarioEditSelectGroup>
            <Styled.ScenarioEditGroup>
              <Styled.ScenarioEditInputLabel>
                Name
              </Styled.ScenarioEditInputLabel>
              <Styled.ScenarioEditInput
                id="name"
                placeholder="Testszenarioname eingeben"
                value={selectedScenarioData.name}
                onChange={(e) => handleChangeData(e.target.value, "name")}
              />
            </Styled.ScenarioEditGroup>
            <Styled.ScenarioSelectGroup>
              <Styled.ScenarioSelectLabel>
                Zugewiesener Testgruppe
              </Styled.ScenarioSelectLabel>
              <Styled.ScenarioSelect
                id="test_group_id"
                defaultValue="Testgruppe auswählen"
                style={{width: 120}}
                value={selectedScenarioData.test_group_id}
                onChange={(id) => handleChangeData(id, "test_group_id")}
              >
                {data?.test_groups.map((itemGroup) => (
                  <Styled.ScenarioOption
                    key={itemGroup.id}
                    value={itemGroup.id}
                  >
                    {itemGroup.name}
                  </Styled.ScenarioOption>
                ))}
                <Styled.ScenarioOption
                  key={data?.test_groups.length + 1}
                  value="Add"
                >
                  <Styled.ScenarioAddGroup id="newGroup">
                    Neue Gruppe hinzufügen
                  </Styled.ScenarioAddGroup>
                </Styled.ScenarioOption>
              </Styled.ScenarioSelect>
            </Styled.ScenarioSelectGroup>
          </Styled.ScenarioEditSelectGroup>

          <Styled.ScenarioEditTextAreaLabel>
            Beschreibung
          </Styled.ScenarioEditTextAreaLabel>
          <Styled.ScenarioEditTextArea
            id="description"
            placeholder="Beschreibung eingeben"
            autoSize={{minRows: 4}}
            value={selectedScenarioData.description}
            onChange={(e) => handleChangeData(e.target.value, "description")}
          />
          <Styled.ScenarioEditButtonsGroup>
            <Styled.PopconfirmStyle
              cancelButtonProps={{type: "primary"}}
              placement="leftTop"
              title="Möchten Sie dieses Szenario wirklich löschen?"
              onConfirm={handleDataDelete}
              okText="Yes"
              cancelText="No"
              disabled={selectedScenarioData.id === undefined}
            >
              <Styled.ScenarioEditButtonsDelete
                id="deleteButton"
                $isDisable={selectedScenarioData.id === undefined}
              >
                Löschen
              </Styled.ScenarioEditButtonsDelete>
            </Styled.PopconfirmStyle>
            <Styled.ScenarioEditButtonsAbort
              id="abortButton"
              onClick={() => handleAbort()}
              $isDisable={!isDataChanged()}
            >
              Abbrechen
            </Styled.ScenarioEditButtonsAbort>
            <Styled.ScenarioEditButtonsSave
              id="saveButton"
              onClick={() => handleDataSave()}
              $isDisable={
                !selectedScenarioData.name ||
                selectedScenarioData.test_group_id === undefined ||
                !isDataChanged()
              }
            >
              Speichern
            </Styled.ScenarioEditButtonsSave>
          </Styled.ScenarioEditButtonsGroup>
        </Styled.ScenarioEdit>
      </Styled.InsideBox>
    </Styled.Box>
  )
}

export default ScenariosView
