import styled from "styled-components"

export const MainBox = styled.div`
  display: flex;
  height: 95px;
  background: #f8f8fb;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  flex-direction: row;
  align-item: center;
  width: 100%;
  justify-content: center;
`

export const BoxWrappers = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  width: 100%;
  max-width: 92%;
`

export const BoxCount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.333%;
  min-width: 175px;

  opacity: 1;
`

export const BoxCountTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #808080;
  margin-bottom: 20px;
`

export const BoxCountUnder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #808080;
`

export const BoxTest = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.333%;
  padding-left: 10px;
  min-width: 175px;
`

export const MainBoxTemperature = styled.div`
  display: flex;
  height: 95px;
  background: #f8f8fb;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  flex-direction: row;
  align-item: center;
  width: 100%;
  justify-content: center;
`

export const BoxWrappersTemperature = styled.div`
  display: flex;
  align-item: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 92%;
`

export const BoxTestTemperature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 33.333%;
  min-width: 175px;
`

export const BoxTestTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #808080;
  margin-bottom: 20px;
`

export const BoxTestTitleOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #808080;
  margin-top: 12px;
  margin-left: 10px;
`

export const BoxTestUnder = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #808080;
  padding-left: 9px;
  color: #808080;
`

export const UnderBoxDelorean = styled.div`
  display: flex;
`
export const UnderBoxSvg = styled.div`
  display: flex;
`
export const UnderSvgCar = styled.svg`
  margin: 1px;
`

export const UnderBoxText = styled.div`
  padding-left: 5px;
  display: flex;
`

export const UnderSvgRoad = styled.img`
  margin: 5px;
  margin-top: 4px;
  height: 8px;
`

export const UnderBoxTurley = styled.div`
  padding-left: 15px;
  display: flex;
`

export const BoxQuestion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.333%;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #808080;
  padding-left: 10px;

  min-width: 175px;
  opacity: 1;
`

export const BoxQuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  width: 60%;
`

export const BoxQuestionUnder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
`

export const Styled = {
  MainBox,
  BoxWrappers,
  BoxCount,
  BoxTest,
  MainBoxTemperature,
  BoxWrappersTemperature,
  BoxTestTemperature,
  BoxQuestion,
  BoxCountTitle,
  BoxCountUnder,
  BoxTestTitle,
  BoxTestTitleOne,
  BoxTestUnder,
  BoxQuestionTitle,
  BoxQuestionUnder,
  UnderBoxDelorean,
  UnderBoxSvg,
  UnderSvgCar,
  UnderBoxText,
  UnderSvgRoad,
  UnderBoxTurley
}
